import styled from "styled-components";
import { colors } from "../../../../configs/colors";

export const Container = styled.div`
  margin: 0px 0px 20px 0px;
  flex-direction: column;
`;

export const CbButton = styled.button`
  border: 1px solid
    ${(props) => (props.blueButton ? colors.DARKBLUE : colors.DARKRED)};
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  width: 110px;
  background-color: ${colors.WHITE};
  cursor: pointer;
  border-radius: 1rem;
  padding: 10px;
  margin: 10px 5px 0px 0px;
  &:hover {
    background-color: ${(props) =>
      props.blueButton ? colors.BLUE : colors.RED};
  }
`;

export const CBMessageContainer = styled.div`
  flex-direction: row;
  
`;

export const CbMessage = styled.p`
  margin: 0px;
`;

export const CbDownloadButton = styled.button`
  border: none;
  width: 110px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  height: 40px;
  background-color: ${colors.DARKBLUE};
  cursor: pointer;
  border-radius: 1rem;
  padding: 10px;
  margin: 10px 5px 0px 0px;
  &:hover {
    background-color: ${colors.BLUE};
  }
`;

export const CbButtonText = styled.p`
  text-align: center;
  color: ${(props) => (props.blueButton ? colors.DARKBLUE : colors.DARKRED)};
  margin: 0px !important;
  padding: 0px !important;
  &:hover {
    color: ${colors.WHITE};
  }
`;
