// Global configuration
const ThemeColor = {
  Light: 1,
  Dark: 2
}
const UserStatus = {
  Online: 1,
  Offline: 2,
  Away: 3,
  Busy: 4
}
const ChatFilterOptions = {
  AllChats: 1,
  Friends: 2,
  Groups: 3,
  Unread: 4,
  Archived: 5
}
const CallFilterOptions = {
  AllCalls: 1,
  Incoming: 2,
  Outgoing: 3,
  Missedcalls: 4
}
const ProfileType = {
  Image: 1,
  Svg: 2,
  Text: 3
}

const headerColors = [
  '#1abc9c',
  '#2ecc71',
  '#3498db',
  '#9b59b6',
  '#34495e',
  '#16a085',
  '#27ae60',
  '#2980b9',
  '#8e44ad',
  '#2c3e50',
  '#f1c40f',
  '#e67e22',
  '#e74c3c',
  '#95a5a6',
  '#f39c12',
  '#d35400',
  '#c0392b',
  '#7f8c8d',
  '#7f8c8d'
]
export {
  ThemeColor,
  UserStatus,
  ChatFilterOptions,
  CallFilterOptions,
  ProfileType,
  headerColors
}
