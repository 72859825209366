import styled from "styled-components";
import { colors } from "../../../../configs/colors";

export const Container = styled.div`
  margin: 20px 0px 20px 0px;
  flex-direction: column;
`;

export const DwButton = styled.button`
  border: none;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  width: fit-content;
  width: 60px;
  height: 60px;
  color: white;
  background-color: ${(props) =>
    props.color
      };
  cursor: pointer;
  border-radius: 50%;
  padding: 10px;
  margin: 10px 5px 0px 0px;
  &:hover {
    background-color: ${(props) =>
      props.hoverColor
        };
  }
`;

export const DwMessageContainer = styled.div`
  /* margin: 10px 0px 0px 0px; */
  padding: 1rem 2.25rem;
  background-color: ${colors.RED};
  overflow-wrap: anywhere;
  color: #fff;
  margin-left: ${(props) => (props.margin ? 1.25 : 0)}rem;
  margin-right: ${(props) => (props.margin ? 1.25 : 0)}rem;
  border-radius: 1.25rem;
  text-align: left;
  display: inline-block;
  max-width: 25rem;
`;

export const DwMessage = styled.p`
  margin: 0px;
`;
export const DwButtonText = styled.p`
  text-align: center;
  color: ${colors.WHITE};
  margin: 0px !important;
  padding: 0px !important;
`;
