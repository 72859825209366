import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { callNewRoom, getBuckets } from "../../../../services/userServices/actionButtons";
import { useHistory } from "react-router";

import { sortActionButtons } from "../../../../utils/roomsUtils";
import { refreshActionButtons, setActionButtonClicked } from "../../../../store/reducers/main";
import {
  ABCColumnContainer,
  ActionButton,
  ActionButtonsContainer,
  ActionButtonText,
  MainContainer,
  TopButtonsContainer,
  ContainerSelect
} from "./styles";
import CustomSelect from "../../../../components/Home/Select";
import api from "../../../../services/api";

const oldBucket = {
  color: "#8888aa",
  _id: "oldButton",
  position: 999,
};

const windowWidth = (() => {
  if (window.innerWidth < 400) return 60;
  else if (window.innerWidth < 700) return 80;
  else return 110;
})();

export const ActionList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [toolsList, setToolsList] = useState([]);
  const [topButtonsList, setTopButtonsList] = useState([]);
  const [sortedBuckets, setSortedBuckets] = useState([]);
  const [options, setOptions] = useState({});
  const [tag, setTag] = useState(null);

  const main = useSelector((state) => state.main);

  const sortBuckets = (a, b) => {
    if (a.position > b.position) return 1;
    return -1;
  };

  const handleBuckets = () => {
    return (
      <>
        {sortedBuckets.length > 0 &&
          sortedBuckets.map((bucket, index) => {
            if (bucket.buttons.length === 0 || bucket.position === "0") return;
            const actionButtonsNumber = bucket.buttons.length;
            const containerSize =
              Math.ceil(actionButtonsNumber / 2) * windowWidth;
            return (
              <ABCColumnContainer key={index} containerSize={containerSize}>
                {bucket.buttons.map((tool, index) => {
                  if (
                    main.profile &&
                    tool.profile &&
                    !tool.profile.find(
                      (profileId) => profileId === main.profile.role
                    )
                  ) {
                    return;
                  }
                  return (
                    <ToolButton
                      key={index}
                      label={tool.label}
                      onClick={() => {
                        handleToolButtonClick(tool);
                      }}
                      buttonType={tool.type[0]}
                      color={bucket.color}
                      numberOfBuckets={main.buckets.length}
                    />
                  );
                })}
              </ABCColumnContainer>
            );
          })}
      </>
    );
  };

  useEffect(() => {
    const bucketsArray = [...(main.buckets ? main.buckets : [])];
    const topBucket = bucketsArray
      ? bucketsArray.find((bucket) => bucket.position === "0")
      : { buttons: [] };
    if (topBucket) setTopButtonsList(topBucket.buttons);
    const sortedList = bucketsArray.sort(sortBuckets);
    setSortedBuckets(sortedList);
  }, [main.actionButtons, main.buckets]);

  const handleToolButtonClick = async (actionButton) => {
    if (actionButton) {
      dispatch(setActionButtonClicked(actionButton));
    }
    await callNewRoom(actionButton._id, main.profile._id, main.idToken);
    history.push("/Home/Chats");
  };

  // useEffect(() => {
  //   getButtonsTags(true)
  // }, [main?.profile])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     getButtonsTags();
  //   }, 40 * 1000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // });

  // async function getButtonsTags(init) {
  //   if(main?.profile?.company){
  //     const result = await api.get(`/tags/${main?.profile?.company}`);
  //     setOptions(
  //       result.data.map(i => ({label: i.name, value: i._id}))
  //     )
  //     if(init) {
  //       const tagLocal = window.localStorage.getItem('tag')
  //       if(tagLocal) {
  //         const tagInit = result.data.find(i => i._id === tagLocal)
  //         if(tagInit) {
  //           setTag({label: tagInit.name, value: tagInit._id})
  //         }
  //       }
  //     }
  //   }
  // }

  // async function changeTag(event) {
  //   if(event) {
  //     setTag(event)
  //     window.localStorage.setItem('tag', event.value)
  //   } else {
  //     setTag('')
  //     window.localStorage.removeItem('tag')
  //   }
  //   dispatch(refreshActionButtons());
  // }

  return (
    <MainContainer>
      {/* <ContainerSelect>
        <h3>
          {tag?.label}
        </h3>
        
        <CustomSelect
          isClearable
          isMulti={false}
          options={options}
          value={tag}
          onChange={e => changeTag(e)}
          placeholder='Select the tag to filter'
        />
      </ContainerSelect> */}
      <TopButtonsContainer>
        {topButtonsList.map((tool, index) => {
          return (
            <ToolButton
              key={index}
              label={tool.label}
              onClick={() => {
                handleToolButtonClick(tool);
              }}
              buttonType={tool.type[0]}
              color={"#10314c"}
              numberOfBuckets={toolsList.length}
            />
          );
        })}
      </TopButtonsContainer>
      <ActionButtonsContainer>
          {handleBuckets()}
      </ActionButtonsContainer>
    </MainContainer>
  );
};

const ToolButton = ({ label, onClick, buttonType, color, numberOfBuckets }) => {
  return (
    <ActionButton
      type="button"
      numberOfBuckets={numberOfBuckets}
      className={"eyeson-btn btn-primary eyeson-actionButton"}
      onClick={onClick}
      color={color}
    >
      <ActionButtonText>{label}</ActionButtonText>
    </ActionButton>
  );
};
