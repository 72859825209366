import React from "react";
import { ContentContainer } from "../styles";
import moment from "moment";
import {
  Comment,
  Title,
  TitleContainer,
  CommentsContainer,
  CommentHeaderContainer,
  CommentTitle,
  CommentDate,
  DotSeparator,
  CommentContent,
  HorizontalLine,
} from "./styles";
import { ReactComponent as ChatIcon } from "../../../../../../assets/media/heroicons/outline/chat-alt-2.svg";
import { useSelector } from "react-redux";
import { getContactName } from "../../../../../../utils/hooks";

export const CommentsComponent = () => {
  const { currentAlert } = useSelector((state) => state.alerts);
  const { contactList } = useSelector((state) => state.main);

  return (
    <ContentContainer>
      <TitleContainer>
        <ChatIcon />
        <Title>Comments</Title>
      </TitleContainer>
      <HorizontalLine />
      <CommentsContainer>
        {currentAlert.history.length > 0 &&
          currentAlert.history.map((value, index) => {
            const userName = getContactName(value.user, contactList, "_id");
            return <CommentComp data={value} key={index} name={userName} />;
          })}
      </CommentsContainer>
    </ContentContainer>
  );
};

const CommentComp = ({ data, name }) => {
  const formattedDate = moment(data.update_ate).format("MMM DD, yyyy HH:mm:ss");
  return (
    <Comment>
      <CommentHeaderContainer>
        <CommentTitle>{name}</CommentTitle>
        <DotSeparator />
        <CommentTitle>Status: {data.status}</CommentTitle>
        <DotSeparator />
        <CommentDate>{formattedDate}</CommentDate>
      </CommentHeaderContainer>
      <CommentContent>{data.comment}</CommentContent>
    </Comment>
  );
};
