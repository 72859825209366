import api from "../../api";
export const checkDuplicates = async ({
  company,
  person_first_name,
  person_code,
  license_plate,
  comments,
  person_type,
  person_category,
}) => {
  try {
    const response = await api.post("/classifications/check", {
      company,
      person_first_name,
      person_code,
      license_plate,
      comments,
      person_type,
      person_category,
    });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch (err) {
    console.log(err);
    window.alert("Failed to retrieve reports");
    return false;
  }
};
