import styled from 'styled-components';
import { colors } from '../../../../configs/colors';


export const NewTaskButton = styled.div`
  background-color: ${colors.DARKBLUE};
  width: 140px;
  border-radius: 10px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${colors.BLUE};
  }
`

export const NewTaskButtonText = styled.p`
  text-align: center;
  margin: 0px !important;
  color: ${colors.WHITE};
  padding: 0px !important;
  line-height: 0px !important;
`