import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  roomList: [],
  parsedRooms: [],
  onlineList: [],
  socket: null,
  currentChat: null,
  contactFilter: '',
  unreadCount: 0,
  currentProfile: null,
  uploadModalVisible: false,
  uploadLoading: false,
  uploadFile: null,
  unsentNotification: [],
  showDocument: {
    visible: false,
    src: ''
  },
  internetStatus: 'online'
}
export const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    setSocket: (state, action) => {
      state.socket = action.payload
    },
    setRoomList: (state, action) => {
      state.roomList = action.payload
    },
    setParsedRooms: (state, action) => {
      state.parsedRooms = action.payload
    },
    setShowDocument: (state, action) => {
      state.showDocument = action.payload
    },
    setUploadLoading: (state, action) => {
      state.uploadLoading = action.payload
    },
    setUploadModalVisible: (state, action) => {
      state.uploadModalVisible = action.payload
    },
    setUploadFile: (state, action) => {
      state.uploadFile = action.payload
    },
    updateParsedRoom: (state, action) => {
      const newParsedRooms = state.parsedRooms.filter(room => room._id !== action.payload._id)
      newParsedRooms.push(action.payload)
      state.parsedRooms = newParsedRooms
    },
    setOnlineList: (state, action) => {
      state.onlineList = action.payload
    },
    setCurrentChat: (state, action) => {
      state.currentChat = action.payload
    },
    setCurrentProfile: (state, action) => {
      state.currentProfile = action.payload
    },
    setContactFilter: (state, action) => {
      state.contactFilter = action.payload
    },
    setInternetStatus: (state, action) => {
      state.internetStatus = action.payload
    },
    setUnreadCount: (state, action) => {
      state.unreadCount = action.payload
    },
    resetChatRedux: (state, action) => {
      state = initialState
    },
    connectToSocket: (state, action) => {
      return state
    },
    receiveRoomList: (state, action) => {
      return state
    },
    receiveRoom: (state, action) => {
      return state
    },
    loadChat: (state, action) => {
      return state
    },
    sendMessage: (state, action) => {
      return state
    },
    sendFile: (state, action) => {
      return state
    },
    receiveMessage: (state, action) => {
      return state
    },
    newRoom: (state, action) => {
      return state
    },
    createNewRoom: (state, action) => {
      return state
    },
    updateUnreadMessages: (state, action) => {
      return state
    },
    changeChatButtonPos: (state, action) => {
      return state
    },
    addNotification: (state, action) => {
      state.unsentNotification = [...state.unsentNotification, action.payload]
    },
    removeNotification: (state, action) => {
      state.unsentNotification = state.unsentNotification.filter(not => not.id !== action.payload.id)
    },
    reconnectToSocket: (state, action) => {
      state.unsentNotification = state.unsentNotification.filter(not => not.id !== action.payload.id)
    }
  }
})

export const {
  setSocket,
  setRoomList,
  setOnlineList,
  setCurrentChat,
  setParsedRooms,
  connectToSocket,
  receiveRoomList,
  receiveRoom,
  setContactFilter,
  setUnreadCount,
  sendMessage,
  receiveMessage,
  setCurrentProfile,
  loadChat,
  resetChatRedux,
  newRoom,
  addNotification,
  removeNotification,
  updateUnreadMessages,
  updateParsedRoom,
  createNewRoom,
  setInternetStatus,
  reconnectToSocket,
  changeChatButtonPos,
  sendFile,
  setUploadLoading,
  setUploadModalVisible,
  setUploadFile,
  setShowDocument
} = chatsSlice.actions
export default chatsSlice.reducer
