import React from 'react'
import { Link } from 'react-router-dom'
import { getLastMessageFormat } from '../../../../utils/formatingUtils'
import { getAvatar } from '../../../../utils/roomsUtils'
import { useSelector } from 'react-redux'


export const ChatButton = (props) => {
  const { contactList } = useSelector(state => state.main)

  const lastMessage = getLastMessageFormat(props.lastMessage)

  const nameOwner = contactList.find(contact => contact._id === props.Owner)

  if (props.Group) {
    return (
      <li className={props.active ? 'contacts-item groups active' : 'contacts-item groups'} id={props._id}>
        <Link to={'/Home/Chats/' + props._id} href='#chats-content' className='contacts-link' onClick={() => { props.handleChatClick() }}>
          <div className='avatar bg-danger text-light'>
            <span>
              <svg className='hw-24' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z' />
              </svg>
            </span>
          </div>
          <div className='contacts-content'>
            <div className='contacts-info'>
              <h6 className='chat-name'>{props.roomName}</h6>
            </div>
            <div className='contacts-texts'>
              <div className="badge badge-secondary">{nameOwner?.firstname || 'User'} {nameOwner?.lastname || 'unavailable'}</div>
            </div>
            <div className='contacts-texts'>
              <p className='text-truncate'>{lastMessage}</p>
              <div className='badge badge-rounded badge-primary ml-1'>{props.messageNotRead}</div>
            </div>
            <div className='contacts-texts'>
              <p className='chat-time'>{props.formattedDate}</p>
            </div>

          </div>
        </Link>
      </li>
    )
  } else {
    const { styles, initials } = getAvatar(48, 48, props.roomName ? props.roomName : 'Name Unknown')
    const styleAvata = {...styles,  height: '4.2rem',  width: '4.2rem', borderRadius: '50%', padding: '10px 8px'}
    return (
      <li
        className={props.active ? 'contacts-item active' : 'contacts-item'}
        data-sort=''
        id={props._id}
      >
        <Link to={'/Home/Chats/' + props._id} className='contacts-link' onClick={() => { props.handleChatClick() }}>
          <div className='avatar'>
            <div className='avatar-initials' style={styleAvata}>{initials}</div>
          </div>
          <div className='contacts-content'>
            <div className='contacts-info'>
              <h6 className='chat-name'>{props.roomName}</h6>
            </div>
            <div className='contacts-texts'>
              <p className='text-truncate'>{lastMessage}</p>
              <div className='badge badge-rounded badge-primary ml-1'>{props.messageNotRead}</div>
            </div>
            <div className='contacts-texts'>
              <p className='chat-time'>{props.formattedDate}</p>
            </div>

          </div>
        </Link>
      </li>
    )
  }
}
