export function getLastMessageFormat(message) {
  try {
    if(message?.shouldPlay && !message?.type) return message.message;

    if (message?.type == "buttonImage") return "Image";

    if (message?.type == "buttonEWAVE") {
      try {
        return message.message || "Video Link";
      } catch (error) {
        return "Video Link";
      }
    }

    if (message?.type == "buttonVMS") {
      return "Pending";
    }
    
    if(message.message) return message.message || "New Message"

    return message || "New Message";
  } catch (error) {
    return "Unknown";
  }
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export const dataURLtoBlob = (dataurl) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const parseHashedMessage = (message) => {
  if (message?.type == "buttonImage") return "Image";

  if (message?.type == "buttonEWAVE") {
    try {
      return message.message || "Video Link";
    } catch (error) {
      return "Video Link";
    }
  }

  if (message?.type == "buttonVMS") {
    return "Pending";
  }

  if(message.message) return message.message || "New Message"

  return message || "New Message";
};
