import React from 'react'
import { Link } from 'react-router-dom'
import { HomeInput, MainContainer } from '../../../components/Landing'

export const SignUp = () => {
  return (
    <MainContainer>
      <h1 className='font-weight-bold'>Sign up</h1>
      <p className='text-dark mb-3'>
        We are Different, We Make You Different.
      </p>
      <form className='mb-3'>
        <HomeInput
          labeltext='Name'
          type='text'
          className='form-control form-control-md'
          id='name'
          placeholder='Enter your name'
        />
        <HomeInput
          labeltext='Email Address'
          type='email'
          className='form-control form-control-md'
          placeholder='Enter your email'
        />
        <HomeInput
          labeltext='Password'
          type='password'
          className='form-control form-control-md'
          placeholder='Enter your password'
        />
        <Link
          to='/Chats'
          className='btn btn-primary btn-lg btn-block text-uppercase font-weight-semibold'
        >
          Sign up
        </Link>
      </form>
      <p>
        Already have an account?{' '}
        <Link className='font-weight-semibold' to='/SignIn'>
          Sign in
        </Link>
        .
      </p>
    </MainContainer>
  )
}
