import styled from "styled-components";
import { colors } from "../../../configs/colors";

export const baseStyle = {
  width: "90%",
  height: "200px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

export const focusedStyle = {
  borderColor: "#2196f3",
};

export const acceptStyle = {
  borderColor: "#00e676",
};

export const rejectStyle = {
  borderColor: "#ff1744",
};

export const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

export const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: "fit-content",
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

export const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

export const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

export const UseCameraButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.DARKBLUE};
  border-radius: 12px;
  width: 100px;
  margin: 10px;
  padding: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: ${colors.BLUE};
  }
`;

export const UseCameraButtonText = styled.p`
  padding: 0px !important;
  margin: 0px !important;
  color: ${colors.WHITE};
`;

export const ContentContainer = styled.div`
  background-color: white;
  width: 90%;
  max-width: 500px;
  border-radius: 8px;
  height: 320px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px;
  justify-content: center;
  align-items: center;
`;

export const ResetButton = styled.div`
  display: flex;
  margin-left: 10px;
  border-radius: 12px;
  background-color: ${colors.DARKRED};
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${colors.WHITE};
  cursor: pointer;
  &:hover {
    background-color: ${colors.RED};
  }
`;
