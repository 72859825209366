import React from 'react'
import { getAvatar } from '../../../../utils/roomsUtils'

export const Friend = (props) => {
  const { styles, initials } = getAvatar(36, 36, props.firstname + ' ' + props.lastname)
  const styleAvata = {...styles,  height: '3.2rem',  width: '3.2rem', borderRadius: '50%', padding: '8px'}

  return (
    <li className='contacts-item' id={props._id} data-sort='99'>
      <a className='contacts-link' onClick={props.onFriendSelect}>
        <div className={props.online ? 'avatar usercontact avatar-online' : 'avatar usercontact'}>
          <div className='avatar-initials' style={styleAvata}>{initials}</div>
        </div>
        <div className='contacts-content'>
          <div className='contacts-info'>
            <h6 className='chat-name text-truncate'>{props.firstname + ' ' + props.lastname}</h6>
          </div>
          <div className='contacts-texts'>
            <svg className='text-muted hw-20' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z' />
            </svg>

            <p className='text-muted mb-0'>{props.email}</p>
          </div>
        </div>
      </a>
    </li>
  )
}
