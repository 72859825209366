import React, { useEffect } from "react";
import { NavBar } from "../../../components/Home";
import { InvestigationList } from "./InvestigationList";
import { Investigation } from "./Investigation";

import { useDispatch, useSelector } from "react-redux";
import {
  getInvestigations,
  getInvestigationsTransactions,
  setCurrentInvestigation,
  setInvestigationBarVisible,
  setInvestigationFormVisible,
  setInvestigationTransactionInfo,
} from "../../../store/reducers/investigations";
import { InvestigationForm } from "./InvestigationForm";

export const Investigations = () => {
  const { actionButtonClicked, profile } = useSelector((state) => state.main);
  const _persist = useSelector((state) => state._persist);

  const {
    currentInvestigation,
    investigationList,
    investigationBarVisible,
    investigationFormVisible,
  } = useSelector((state) => state.investigations);

  const dispatch = useDispatch();
  const handleInvestigationBackClick = () => {
    dispatch(setInvestigationBarVisible(false));
    dispatch(setCurrentInvestigation(null));
  };
  useEffect(() => {
    if (
      !currentInvestigation &&
      !actionButtonClicked &&
      window.innerWidth > 1000 &&
      investigationList.length > 0
    ) {
      dispatch(setCurrentInvestigation(investigationList[0]));
    }
    dispatch(setInvestigationFormVisible(false));
    dispatch(setInvestigationBarVisible(false));
  }, [investigationList]);

  useEffect(() => {
    dispatch(getInvestigations());
  }, [_persist]);

  useEffect(() => {
    dispatch(setCurrentInvestigation(investigationList[0]));
  }, [investigationList]);

  useEffect(() => {
    dispatch(getInvestigationsTransactions());
  }, [currentInvestigation]);

  return (
    <>
      {investigationFormVisible && <InvestigationForm />}
      <div className="calls-tab-open h-100">
        <div className="main-layout">
          <NavBar activeMenu="investigations" />
          <InvestigationList
            onInvestigationClick={() => {
              dispatch(setInvestigationBarVisible(true));
            }}
          />
          {currentInvestigation && (
            <main
              className={investigationBarVisible ? "main main-visible" : "main"}
            >
              <Investigation
                onBackClick={() => {
                  handleInvestigationBackClick();
                }}
              />
            </main>
          )}
          <div className="backdrop" />
        </div>
      </div>
    </>
  );
};
