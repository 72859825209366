import { combineReducers } from "redux";

import mainReducer from "./main";
import chatsReducer from "./chats";
import investigationsReducer from "./investigations";
import tasksReducer from "./tasks";
import alertsReducer from "./alerts";
import reportReducer from "./reports";

export default combineReducers({
  main: mainReducer,
  chats: chatsReducer,
  investigations: investigationsReducer,
  tasks: tasksReducer,
  alerts: alertsReducer,
  reports: reportReducer,
});
