import { put, take, select } from "redux-saga/effects";
import { getReports } from "../../services/userServices";
import { setReportList } from "../reducers/reports";
import { updateReport } from "../../services/userServices/reports";
import { loadReports } from "../../services/userServices/reports";

export function* getReportsSaga() {
  const { profile } = yield select((state) => state.main);
  if (!profile?.profile?.stores[0]?.store)
    return window.alert("User store not found");
  const reportsList = yield loadReports(profile.profile.stores[0].store);

  yield put(setReportList(reportsList));
}

// export function* updateReportSaga(action) {
//   const { reportId, comment, priority, status, user } = action.payload;
//   const result = yield updateReport(reportId, priority, status, comment, user);
//   if (!result) return window.report("Failed to update report");
//   yield put(getReports());
//   yield take(reportFinishedFetching);
//   const { reportList } = yield select((state) => state.reports);
//   yield put(setCurrentReport(reportList[0]));
//   window.report("Report updated successfully");
// }
