export const validateInvestigationForm = (form) => {
  const errorFields = [];
  if (form.dateTime === "" || form.isoDateTime === "")
    errorFields.push("Date and Time");
  if (!form.businessGroup.length > 0) errorFields.push("Business Group");
  if (!form.region.length > 0) errorFields.push("Region");
  if (!form.store.length > 0) errorFields.push("Store");
  if (!form.priority.length > 0) errorFields.push("Priority");
  if (!form.nvrdvr.length > 0) errorFields.push("NVR/DVR");
  if (!form.cam.length > 0) errorFields.push("#CAM");
  if (!form.investigationGroup.length > 0)
    errorFields.push("Investigation Group");
  if (!form.subject.length > 0) errorFields.push("Subject");
  if (!form.description.length > 0) errorFields.push("Description");
  return errorFields;
};
