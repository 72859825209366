import React from "react";
import { ContentContainer } from "../styles";
import moment from "moment";
import {
  Comment,
  Title,
  TitleContainer,
  CommentsContainer,
  CommentHeaderContainer,
  CommentTitle,
  CommentDate,
  DotSeparator,
  CommentContent,
  HorizontalLine,
} from "./styles";
import { ReactComponent as ChatIcon } from "../../../../../../assets/media/heroicons/outline/chat-alt-2.svg";
import { useSelector } from "react-redux";

export const CommentsComponent = () => {
  const { currentInvestigation } = useSelector((state) => state.investigations);
  return (
    <ContentContainer>
      <TitleContainer>
        <ChatIcon />
        <Title>Comments</Title>
      </TitleContainer>
      <HorizontalLine />
      <CommentsContainer>
        {currentInvestigation.comments.map((value, index) => {
          return <CommentComp data={value} key={index} />;
        })}
      </CommentsContainer>
    </ContentContainer>
  );
};

const CommentComp = ({ data }) => {
  const formattedDate = moment(data.created_at).format("MMM DD, yyyy HH:mm:ss");
  return (
    <Comment>
      <CommentHeaderContainer>
        <CommentTitle>{data.name}</CommentTitle>
        <DotSeparator />
        <CommentDate>{formattedDate}</CommentDate>
      </CommentHeaderContainer>
      <CommentContent>{data.comment}</CommentContent>
    </Comment>
  );
};
