import React, { useEffect, useState } from "react";
import { TaskButton } from "../Task";
import { useSelector, useDispatch } from "react-redux";
import {
  setCurrentTask,
  setTaskFormVisible,
} from "../../../../store/reducers/tasks";
import { NewTaskButton, NewTaskButtonText } from "./styles";

export const TaskList = ({ onTaskClick }) => {
  const tasks = useSelector((state) => state.tasks);
  const dispatch = useDispatch();

  const changeToTask = (task) => {
    dispatch(setCurrentTask(task));
    onTaskClick();
  };

  const openTask = () => {
    dispatch(setTaskFormVisible(true));
  };

  return (
    <TaskListContainer>
      <div className="sidebar-header sticky-top p-2">
        <div
          className="d-flex justify-content-between align-items-center marginVertical10"
          style={{ margin: 10 }}
        >
          <h5 className="font-weight-semibold mb-0">Tasks</h5>
          <NewTaskButton onClick={openTask}>
            <NewTaskButtonText>New Task</NewTaskButtonText>
          </NewTaskButton>
        </div>
      </div>
      <ul className="contacts-list" id="chatContactTab">
        {tasks.taskList &&
          tasks.taskList.map((task, index) => {
            return (
              <TaskButton
                key={"task" + index}
                {...task}
                active={
                  tasks.currentTask ? tasks.currentTask._id === task._id : false
                }
                handleTaskClick={() => {
                  changeToTask(task);
                }}
              />
            );
          })}
      </ul>
    </TaskListContainer>
  );
};

const TaskListContainer = (props) => {
  return (
    <aside className="sidebar">
      <div className="tab-content">
        <div className="tab-pane active" id="tasks-content">
          <div className="d-flex flex-column h-100">
            <div className="hide-scrollbar h-100" id="chatContactsList">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};
