import React, { useEffect, useState, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as TaskSvg } from "../../../../assets/media/icons/search.svg";
import { ButtonDiv, Container, ContentContainer, headerStyle } from "./styles";
import {
  CommentsComponent,
  TaskContent,
  NewCommentComponent,
  UploadFiles,
} from "./components";
import { EyesonButton } from "../../../../components/Home/Button";
import { updateTask } from "../../../../services/userServices/tasks";

import { getTasks } from "../../../../store/reducers/tasks";
import { UploadFile } from "../../../../services/chat";
import { newComment } from "../../../../services/userServices/tasks/newComment.tasks";
const initialTaskState = {
  newTaskMembers: [],
  comment: "",
  status: "unchanged",
  priority: "unchanged",
  files: [],
};

function TaskReducer(state, action) {
  if (action.id === "resetState") {
    return { ...state, ...initialTaskState };
  }
  return { ...state, [action.id]: action.value };
}

export const Task = ({ onBackClick }) => {
  const { currentTask } = useSelector((state) => state.tasks);
  const { profile } = useSelector((state) => state.main);
  const [isUpdating, setIsUpdating] = useState(false);

  const dispatch = useDispatch();
  const [taskState, taskDispatch] = useReducer(TaskReducer, initialTaskState);

  const handleTaskUpdate = async () => {
    setIsUpdating(true);
    const filesUrl = await Promise.all(
      taskState.files.map(async (value) => {
        const formData = new FormData();
        formData.append("upload", value);
        const response = await UploadFile(formData);
        if (response.error) {
          window.alert("Failed to upload file");
        }
        return response.data.Location;
      })
    );
    const result = await updateTask(
      currentTask._id,
      currentTask.company,
      currentTask.description,
      [...currentTask.images, ...filesUrl],
      currentTask.owner,
      taskState.priority === "unchanged"
        ? currentTask.priority
        : taskState.priority,
      taskState.status === "unchanged" ? currentTask.status : taskState.status,
      currentTask.title,
      currentTask.transactions,
      [...currentTask.users, ...taskState.newTaskMembers],
      currentTask.created
    );
    setIsUpdating(false);
    if (taskState.comment !== "") await handleNewComment(taskState.comment);

    if (!result) return window.alert("Failed to update task");
    window.alert("Task updated successfully");

    dispatch(getTasks());
  };

  const handleNewComment = async (comment) => {
    const fullName = `${profile.firstname} ${profile.lastname}`;
    const result = await newComment(
      currentTask._id,
      fullName,
      profile._id,
      comment
    );
    if (!result) return false;
    return true;
  };

  useEffect(() => {
    taskDispatch({ id: "resetState" });
  }, [currentTask]);

  return (
    <div className="chats maxWidth">
      <div className="chat-body">
        <div className="chat-header invisibleHeader">
          <button
            className="btn btn-secondary btn-icon btn-minimal btn-sm text-muted d-xl-none"
            type="button"
            onClick={onBackClick}
          >
            <svg
              className="hw-20"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </button>
          <ul className="nav flex-nowrap" />
        </div>
        <div className="chat-content p-2 displayFlex">
          <Container>
            <TaskContent taskDispatch={taskDispatch} />
            <CommentsComponent comments={currentTask.comments} />
            <NewCommentComponent
              taskDispatch={taskDispatch}
              commentValue={taskState.comment}
            />
            <UploadFiles taskDispatch={taskDispatch} isUpdating={isUpdating} />
            <ButtonDiv>
              <EyesonButton
                onClick={handleTaskUpdate}
                blue
                buttonText={"Update"}
              />
            </ButtonDiv>
          </Container>
        </div>
        <div className="chat-footer"></div>
      </div>
    </div>
  );
};
