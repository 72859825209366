import React, { useEffect, useState } from "react";
export const useMobile = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;
  return isMobile;
};

export const getContactName = (id, list, key, returnKey = "") => {
  const contact = list.find((value) => value[key] === id);
  if (!contact) return "Not Found";
  let name = "";
  if (returnKey === "") name = `${contact.firstname} ${contact.lastname}`;
  else name = contact[returnKey];
  return name;
};
