import api from "../../api";
export const loadTasks = async (company, userId) => {
  try {
    const response = await api.post("/tasks/list", {
      company: company,
      user: userId,
      detail: "FULL",
      status: "ALL",
      priority: "ALL",
      sort: "CREATION",
    });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch (err) {
    console.log(err);
    window.alert("Failed to retrieve tasks");
    return false;
  }
};
