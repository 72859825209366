import React from "react";
import { ContentContainer } from "../styles";
import { Title, TitleContainer, DropzoneContainer } from "./styles";
import { ReactComponent as ChatIcon } from "../../../../../../assets/media/heroicons/outline/chat-alt-2.svg";
import { DropzoneForm } from "../../../../../../components/Home";
import { toBase64 } from "../../../../../../utils/formatingUtils";

export const UploadFiles = ({ investigationDispatch, isUpdating }) => {
  const handleFilesChange = (data) => {
    investigationDispatch({ id: "files", value: data });
  };

  return (
    <ContentContainer>
      <TitleContainer>
        <ChatIcon />
        <Title>Upload Pictures</Title>
      </TitleContainer>
      <DropzoneContainer>
        {!isUpdating && <DropzoneForm onChange={handleFilesChange} />}
      </DropzoneContainer>
    </ContentContainer>
  );
};
