import React, { useEffect, useState } from "react";
import { ContentContainer } from "../styles";
import {
  InfoContent,
  InfoDiv,
  InfoOption,
  InfoSelect,
  InfoTitle,
  TextArea,
  Title,
  TitleContainer,
} from "./styles";
import { ReactComponent as CommentIcon } from "../../../../../../assets/media/heroicons/outline/pencil-alt.svg";
import { useSelector } from "react-redux";

export const NewCommentComponent = ({ alertDispatch }) => {
  const { currentAlert } = useSelector((state) => state.alerts);
  const [priority, setPriority] = useState(currentAlert.priority);
  const [status, setStatus] = useState(currentAlert.status);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (currentAlert && currentAlert.priority)
      setPriority(currentAlert.priority);
    if (currentAlert && currentAlert.status)
      setStatus(currentAlert.status);

    setInputValue("");
  }, [currentAlert]);
  const handleTextAreaChange = (event) => {
    const text = event.target.value;
    alertDispatch({ id: "comment", value: text });
    setInputValue(text);
  };

  const handlePriorityChange = (event) => {
    const value = event.target.value;
    alertDispatch({ id: "priority", value: value });
    setPriority(value);
  };

  const handleStatusChange = (event) => {
    const value = event.target.value;
    alertDispatch({ id: "status", value: value });
    setStatus(value);
  };

  return (
    <ContentContainer>
      <TitleContainer>
        <CommentIcon />
        <Title>Your comment</Title>
      </TitleContainer>
      <TextArea
        placeholder="Enter comment..."
        onChange={handleTextAreaChange}
        value={inputValue}
      />
      <InfoDiv>
        <InfoContent>
          <InfoTitle>Status:</InfoTitle>
          <InfoSelect onChange={handleStatusChange} value={status}>
            <InfoOption value="Open">Open</InfoOption>
            <InfoOption value="Positive">Positive</InfoOption>
            <InfoOption value="Negative">Negative</InfoOption>
            <InfoOption value="Closed">Closed</InfoOption>
          </InfoSelect>
        </InfoContent>
        <InfoContent>
          <InfoTitle>Priority:</InfoTitle>
          <InfoSelect onChange={handlePriorityChange} value={priority}>
            <InfoOption value="Highest">Highest</InfoOption>
            <InfoOption value="High">High</InfoOption>
            <InfoOption value="Normal">Normal</InfoOption>
            <InfoOption value="Low">Low</InfoOption>
            <InfoOption value="Lowest">Lowest</InfoOption>
          </InfoSelect>
        </InfoContent>
      </InfoDiv>
    </ContentContainer>
  );
};
