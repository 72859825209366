import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { setShowDocument } from "../../../store/reducers/chats";
import { ImageModalDiv, ModalImage } from "./styles";

export const ShowDocumentModal = () => {
  const dispatch = useDispatch();
  const { showDocument } = useSelector((state) => state.chats);

  const handleClose = () => {
    dispatch(setShowDocument({ visible: false, src: "" }));
  };

  const showModalImgStyle = {
    maxWidth: window.innerWidth * 0.9,
    maxHeight: window.innerHeight * 0.9,
  };

  return (
    <div
      className="fadeBackground"
      id="showModal"
      onClick={(e) => {
        if (e.target.id !== "showModal") return;
        handleClose();
      }}
    >
      <ImageModalDiv
        id="showModalInsideDiv"
        onClick={(e) => {
          if (e.target.id !== "showModalInsideDiv") return;
          handleClose();
        }}
      >
        <ModalImage src={showDocument.src} style={showModalImgStyle} />
      </ImageModalDiv>
    </div>
  );
};
