import React, { useEffect } from 'react'
import { SignIn, SignUp, ResetPassword } from './Landing'
import { Home } from './Home'
import { useSelector } from 'react-redux'
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import { LogoutModal, ShowDocumentModal } from '../components/Home/'
export default function MainRouter (props) {
  const main = useSelector(state => state.main)
  const {showDocument} = useSelector(state => state.chats)


  useEffect(() => {
    console.log(`eBuzz App v${process.env.REACT_APP_APPVERSION}`)
  }, [])
  return (
    <>
      <BrowserRouter>
        <Route
          exact
          path='/'
          render={() => {
            return (
              <Redirect to='/SignIn' />
            )
          }}
        />
        <Route path='/SignIn' component={SignIn} exact />
        <Route path='/SignUp' component={SignUp} exact />
        <Route path='/reset-password' component={ResetPassword} exact />
        <Route path='/Home' component={Home} />
      </BrowserRouter>
      {main.logoutModal ? main.logoutModal.isVisible && <LogoutModal /> : <></>}
      {showDocument ? showDocument.visible && <ShowDocumentModal /> : <></>}

    </>
  )
}
