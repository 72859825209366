import api from "../api";

export const sendChatActionButton = async (
  chatId,
  action,
  user,
  owner,
  text,
  area,
  token
) => {
  try {
    const date = new Date();
    const minutes = date.getTimezoneOffset();
    const hours = minutes / 60;
    const newDate = date.setTime(date.getTime() - hours * 60 * 60 * 1000);
    const result = await api.post(
      "/tracking",
      {
        chatId,
        action,
        user,
        owner,
        date: new Date(newDate),
        text,
        area,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return result;
  } catch (err) {
    return { error: err };
  }
};
