import React, { useEffect, useState, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as AlertSvg } from "../../../../assets/media/icons/search.svg";
import { ButtonDiv, Container, ContentContainer, headerStyle } from "./styles";
import {
  CommentsComponent,
  AlertContent,
  NewCommentComponent,
} from "./components";
import { EyesonButton } from "../../../../components/Home/Button";
import { updateAlert } from "../../../../store/reducers/alerts";

import { getAlerts } from "../../../../store/reducers/alerts";
const initialAlertState = {
  comment: "",
  status: "unchanged",
  priority: "unchanged",
};

function AlertReducer(state, action) {
  if (action.id === "resetState") {
    return { ...state, ...initialAlertState };
  }
  return { ...state, [action.id]: action.value };
}

export const Alert = ({ onBackClick }) => {
  const { currentAlert } = useSelector((state) => state.alerts);
  const { profile } = useSelector((state) => state.main);

  const dispatch = useDispatch();
  const [alertState, alertDispatch] = useReducer(
    AlertReducer,
    initialAlertState
  );

  const handleAlertUpdate = async () => {
    dispatch(updateAlert({
      alertId: currentAlert._id,
      priority: alertState.priority === "unchanged"
      ? currentAlert.priority
      : alertState.priority,
      status: alertState.status === "unchanged"
      ? currentAlert.status
      : alertState.status,
      comment: alertState.comment,
      user: profile._id
    }))
  };

  useEffect(() => {
    alertDispatch({ id: "resetState" });
  }, [currentAlert]);
  return (
    <div className="chats maxWidth">
      <div className="chat-body">
        <div className="chat-header invisibleHeader">
          <button
            className="btn btn-secondary btn-icon btn-minimal btn-sm text-muted d-xl-none"
            type="button"
            onClick={onBackClick}
          >
            <svg
              className="hw-20"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </button>
          <ul className="nav flex-nowrap" />
        </div>
        <div className="chat-content p-2 displayFlex">
          <Container>
            <AlertContent
              alertDispatch={alertDispatch}
            />
            <CommentsComponent comments={currentAlert.comments} />
            <NewCommentComponent
              alertDispatch={alertDispatch}
              commentValue={alertState.comment}
            />
            <ButtonDiv>
              <EyesonButton
                onClick={handleAlertUpdate}
                blue
                buttonText={"Update"}
              />
            </ButtonDiv>
          </Container>
        </div>
        <div className="chat-footer"></div>
      </div>
    </div>
  );
};
