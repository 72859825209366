import React, { useState, useEffect } from "react";
import { WithContext as ReactTags } from "react-tag-input";
import { TagInputContainer } from "./styles";
import { useMobile } from "../../../utils/hooks";

const KeyCodes = {
  comma: 188,
  enter: 13,
  _comma: ",".charCodeAt(0),
  _enter: "\n".charCodeAt(0),
};

const delimiters = [
  KeyCodes.comma,
  KeyCodes.enter,
  KeyCodes._comma,
  KeyCodes._enter,
];

export const TagInput = ({
  onChange,
  suggestions = [],
  classNames = {},
  initialTags = [],
  placeholder = false,
  shouldDeleteInitial = true,
  padding = 5,
  resetOnValueChange = false,
  value = "",
  allowOnlyFromSuggestion = false,
}) => {
  const [tags, setTags] = useState(initialTags);
  const [inputValue, setInputValue] = useState("");
  const handleDelete = (i) => {
    if (!shouldDeleteInitial) {
      const isInitTag = tags.find(
        (tag, index) =>
          index === i && initialTags.find((value) => tag.id === value.id)
      );
      if (isInitTag) return;
    }
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    if (allowOnlyFromSuggestion) {
      const isValid = suggestions.find((value) => value.text === tag.text);
      if (!isValid) {
        setInputValue("");
        return window.alert(
          "Invalid input, please insert one from the suggestions"
        );
      }
    }
    setTags([...tags, tag]);
    setInputValue("");
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    // console.log('The tag at index ' + index + ' was clicked');
    return;
  };
  const isMobile = useMobile();

  useEffect(() => {
    onChange(tags);
  }, [tags]);

  useEffect(() => {
    if (resetOnValueChange) setTags(initialTags);
  }, [value]);

  return (
    <TagInputContainer padding={padding}>
      <ReactTags
        tags={tags}
        placeholder={
          placeholder ||
          (isMobile
            ? "Press comma to add a new tag"
            : "Press enter to add a new tag")
        }
        suggestions={suggestions}
        delimiters={delimiters}
        classNames={classNames}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        handleTagClick={handleTagClick}
        autofocus={false}
        handleInputChange={(value) => {
          const newTags = [];
          const length = value.length;
          let l = 0;
          for (let r = 0; r < length; r++) {
            if (
              delimiters.some(
                (charCode) => value.charAt(r) === String.fromCharCode(charCode)
              )
            ) {
              newTags.push(value.substring(l, r));
              l = r + 1;
            }
          }
          newTags.forEach((newTag) => {
            if (newTag) {
              handleAddition({ id: newTag, text: newTag }, tags, setTags);
            }
          });
          setInputValue(value.substring(l, length));
        }}
        inputFieldPosition="inline"
        inputValue={inputValue}
        autocomplete
      />
    </TagInputContainer>
  );
};
