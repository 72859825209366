import React, { useEffect } from "react";
import { NavBar } from "../../../components/Home";
import { AlertList } from "./AlertList";
import { Alert } from "./Alert";

import { useDispatch, useSelector } from "react-redux";
import {
  getAlerts,
  setCurrentAlert,
  setAlertBarVisible,
  getAlertInfo,
} from "../../../store/reducers/alerts";

export const Alerts = () => {
  const { actionButtonClicked, profile } = useSelector((state) => state.main);
  const _persist = useSelector((state) => state._persist);

  const { currentAlert, alertList, alertBarVisible } = useSelector(
    (state) => state.alerts
  );

  const dispatch = useDispatch();
  const handleAlertBackClick = () => {
    dispatch(setAlertBarVisible(false));
    dispatch(setCurrentAlert(null));
  };
  useEffect(() => {
    if (
      !currentAlert &&
      !actionButtonClicked &&
      window.innerWidth > 1000 &&
      alertList.length > 0
    ) {
      dispatch(setCurrentAlert(alertList[0]));
    }
    dispatch(setAlertBarVisible(false));
    dispatch(setCurrentAlert(null));
  }, [alertList]);

  useEffect(() => {
    dispatch(getAlerts());
  }, [_persist]);

  useEffect(() => {
    if (currentAlert) dispatch(getAlertInfo());
  }, [currentAlert]);

  return (
    <>
      <div className="calls-tab-open h-100">
        <div className="main-layout">
          <NavBar activeMenu="alerts" />
          <AlertList
            onAlertClick={() => {
              dispatch(setAlertBarVisible(true));
            }}
          />
          {currentAlert && (
            <main className={alertBarVisible ? "main main-visible" : "main"}>
              <Alert
                onBackClick={() => {
                  handleAlertBackClick();
                }}
              />
            </main>
          )}
          <div className="backdrop" />
        </div>
      </div>
    </>
  );
};
