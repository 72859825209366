import api from "../../api";
export const newComment = async (taskId, name, userid, comment) => {
  try {
    const response = await api.put(`/tasks/comments/${taskId}`, {
      name,
      userid,
      comment,
    });
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
    return false;
  } catch (err) {
    console.log(err);
    window.alert("Failed to update task comment");
    return false;
  }
};
