import React, { useEffect, useState } from "react";
import { ContentContainer } from "../styles";
import {
  InfoContent,
  InfoDiv,
  InfoOption,
  InfoSelect,
  InfoTitle,
  TextArea,
  Title,
  TitleContainer,
} from "./styles";
import { ReactComponent as CommentIcon } from "../../../../../../assets/media/heroicons/outline/pencil-alt.svg";
import { useSelector } from "react-redux";

export const NewCommentComponent = ({ investigationDispatch }) => {
  const { currentInvestigation } = useSelector((state) => state.investigations);
  const [priority, setPriority] = useState(currentInvestigation.priority);
  const [status, setStatus] = useState(currentInvestigation.status);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (currentInvestigation && currentInvestigation.priority)
      setPriority(currentInvestigation.priority);
    if (currentInvestigation && currentInvestigation.status)
      setStatus(currentInvestigation.status);

    setInputValue("");
  }, [currentInvestigation]);
  const handleTextAreaChange = (event) => {
    const text = event.target.value;
    investigationDispatch({ id: "comment", value: text });
    setInputValue(text);
  };

  const handlePriorityChange = (event) => {
    const value = event.target.value;
    investigationDispatch({ id: "priority", value: value });
    setPriority(value);
  };

  const handleStatusChange = (event) => {
    const value = event.target.value;
    investigationDispatch({ id: "status", value: value });
    setStatus(value);
  };

  return (
    <ContentContainer>
      <TitleContainer>
        <CommentIcon />
        <Title>Your comment</Title>
      </TitleContainer>
      <TextArea
        placeholder="Enter comment..."
        onChange={handleTextAreaChange}
        value={inputValue}
      />
      <InfoDiv>
        <InfoContent>
          <InfoTitle>Status:</InfoTitle>
          <InfoSelect onChange={handleStatusChange} value={status}>
            <InfoOption value="Started">Started</InfoOption>
            <InfoOption value="Recurring Violation">
              Recurring Violation
            </InfoOption>
            <InfoOption value="Positive Action">Positive Action</InfoOption>
            <InfoOption value="Praise Recommended">
              Praise Recommended
            </InfoOption>
            <InfoOption value="Promotion Recommended">
              Promotion Recommended
            </InfoOption>
            <InfoOption value="Positive">Closed - Positive</InfoOption>
            <InfoOption value="Closed - Written Warning">
              Closed - Written Warning
            </InfoOption>
            <InfoOption value="Closed - Pending Suspension">
              Closed - Pending Suspension
            </InfoOption>
            <InfoOption value="Closed - Recurring Violation">
              Closed - Recurring Violation
            </InfoOption>
            <InfoOption value="Closed - Training Resource">
              Closed - Training Resource
            </InfoOption>
            <InfoOption value="Closed - Terminated">
              Closed - Terminated
            </InfoOption>
            <InfoOption value="Closed">Closed</InfoOption>
          </InfoSelect>
        </InfoContent>
        <InfoContent>
          <InfoTitle>Priority:</InfoTitle>
          <InfoSelect onChange={handlePriorityChange} value={priority}>
            <InfoOption value="Highest">Highest</InfoOption>
            <InfoOption value="High">High</InfoOption>
            <InfoOption value="Normal">Normal</InfoOption>
            <InfoOption value="Low">Low</InfoOption>
          </InfoSelect>
        </InfoContent>
      </InfoDiv>
    </ContentContainer>
  );
};
