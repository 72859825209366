import { put, take, select } from "redux-saga/effects";
import { getAlertInfo, loadAlerts,  } from "../../services/userServices";
import { alertFinishedFetching, setAlertList, setCurrentAlert, setCurrentAlertDetail, getAlerts } from "../reducers/alerts";
import { updateAlert } from "../../services/userServices/alerts";

export function* getAlertsSaga() {
  const { profile, idToken } = yield select((state) => state.main);

  const alertsList = yield loadAlerts(
    profile._id,
    idToken
  );

  yield put(setAlertList(alertsList));
  yield put(alertFinishedFetching())
}

export function* updateAlertSaga(action) {
  const {alertId, comment, priority, status, user} = action.payload;
  const result = yield updateAlert(alertId, priority, status, comment, user)
  if (!result) return window.alert("Failed to update alert");
  yield put(getAlerts());
  yield take(alertFinishedFetching)
  const {alertList} = yield select((state) => state.alerts)
  yield put(setCurrentAlert(alertList[0]))
  window.alert("Alert updated successfully");
}



export function* getAlertInfoSaga() {
  const { currentAlert } = yield select((state) => state.alerts);

  const alertInfo = yield getAlertInfo(
    currentAlert.store,
    currentAlert.trans_id
  );

  yield put(setCurrentAlertDetail(alertInfo));
}