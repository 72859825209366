import React, { useState, useMemo } from "react";
import { useDropzone } from "react-dropzone";

import { useDispatch, useSelector } from "react-redux";
import {
  sendFile,
  setUploadModalVisible,
  setUploadFile,
} from "../../../store/reducers/chats";
import Loading from "../../../assets/media/loading.gif";
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  img,
  rejectStyle,
  thumb,
  thumbInner,
  UseCameraButton,
  UseCameraButtonText,
  ContentContainer,
  PreviewContainer,
  ResetButton,
} from "./styles";
import { dataURLtoBlob } from "../../../utils/formatingUtils";
import { WebcamCapture } from "../WebcamInput";

export const DropzoneArea = () => {
  const [camera, setCamera] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);

  const dispatch = useDispatch();
  const { uploadModalVisible, uploadLoading, uploadFile } = useSelector(
    (state) => state.chats
  );
  const handleDrop = (acceptedFiles) => {
    const fileToAppend = acceptedFiles[0];
    const url = URL.createObjectURL(acceptedFiles[0]);
    setFileUrl(url);
    dispatch(setUploadFile(fileToAppend));
  };

  const handleCameraScreenshot = (imageSrc) => {
    var _URL = window.URL || window.webkitURL;
    const blob = dataURLtoBlob(imageSrc);
    const file = new File([blob], "image.png", { type: "image/png" });
    const url = _URL.createObjectURL(file);
    dispatch(setUploadFile(file));
    setFileUrl({ ...file, url });
    setCamera(false);
  };

  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    // Disable click and keydown behavior
    // noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop: handleDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const handleUpload = () => {
    if (uploadFile) dispatch(sendFile(uploadFile));
  };

  const handleClose = () => {
    dispatch(setUploadModalVisible(false));
    handleReset();
  };

  const handleReset = () => {
    dispatch(setUploadFile(null));
    setFileUrl(null);
  };

  return camera ? (
    <div
      className={`dropzoneContainer ${
        uploadModalVisible ? "displayFlex" : "displayNone"
      }`}
      id="uploadModalBackground"
      onClick={(e) => {
        if (e.target.id !== "uploadModalBackground") return;
        handleClose();
      }}
    >
      <ContentContainer>
        <UseCameraButton
          onClick={() => {
            setCamera(false);
          }}
        >
          <UseCameraButtonText>Use File</UseCameraButtonText>
        </UseCameraButton>
        <WebcamCapture handleCaptureScreenshot={handleCameraScreenshot} />
      </ContentContainer>
    </div>
  ) : (
    <div
      className={`dropzoneContainer ${
        uploadModalVisible ? "displayFlex" : "displayNone"
      }`}
      id="uploadModalBackground"
      onClick={(e) => {
        if (e.target.id !== "uploadModalBackground") return;
        handleClose();
      }}
    >
      <div className="dropzoneContent">
        <div className="flexAlignCenter">
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p className="dropzoneText">
              Drag and drop images or CLICK HERE to activate camera or browse
              folders.
            </p>
          </div>
        </div>
        <aside>
          {uploadFile && fileUrl && (
            <>
              <h4>File</h4>
              <p>{uploadFile ? uploadFile.path : ""}</p>
              <div style={thumb}>
                <div style={thumbInner}>
                  <PreviewContainer>
                    <img
                      src={fileUrl ? fileUrl.url : uploadFile.preview}
                      style={img}
                    />
                    <ResetButton onClick={handleReset}>X</ResetButton>
                  </PreviewContainer>
                </div>
              </div>
            </>
          )}
        </aside>
        <div className="uploadModalButtonsDiv">
          {!uploadFile && (
            <UseCameraButton
              onClick={() => {
                setCamera(true);
              }}
            >
              <UseCameraButtonText>Use Camera</UseCameraButtonText>
            </UseCameraButton>
          )}
          {uploadFile && (
            <div
              onClick={handleUpload}
              disabled={uploadFile ? false : true}
              className="blueButton"
              id="sendUploadModal"
            >
              {uploadLoading ? (
                <img className="loadingGif" src={Loading}></img>
              ) : (
                <p className="buttonText">Upload File</p>
              )}
            </div>
          )}
          <div
            onClick={handleClose}
            className="redButton"
            id="closeUploadModal"
          >
            <p className="buttonText">Close</p>
          </div>
        </div>
      </div>
    </div>
  );
};
