import styled from "styled-components";
import { colors } from "../../../../../configs/colors";
export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  border-radius: 4px;
  border: 1px solid ${colors.GREY};
  padding: 25px;
  flex-direction: column;
  max-width: 100%;
  height: fit-content;
  margin: 20px 0px 20px 0px;
`;
