import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  investigationList: [],
  currentInvestigation: null,
  investigationBarVisible: false,
  investigationFormVisible: false,
  currentTransactions: [],
};
export const investigationsSlice = createSlice({
  name: "investigations",
  initialState,
  reducers: {
    setInvestigationList: (state, action) => {
      state.investigationList = action.payload;
    },
    setInvestigationBarVisible: (state, action) => {
      state.investigationBarVisible = action.payload;
    },
    setCurrentInvestigation: (state, action) => {
      state.currentInvestigation = action.payload;
    },
    setInvestigationTransactionInfo: (state, action) => {
      state.currentTransactions = action.payload;
    },
    setInvestigationFormVisible: (state, action) => {
      state.investigationFormVisible = action.payload;
    },
    getInvestigations: (state, action) => {
      return state;
    },
    getInvestigationsTransactions: (state, action) => {
      return state;
    },
  },
});

export const {
  setInvestigationList,
  setCurrentInvestigation,
  setInvestigationBarVisible,
  setInvestigationFormVisible,
  getInvestigations,
  setInvestigationTransactionInfo,
  getInvestigationsTransactions,
} = investigationsSlice.actions;
export default investigationsSlice.reducer;
