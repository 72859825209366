import React from 'react'
import offlineIcon from '../../../assets/media/offline.png'

export const OfflineDiv = () => {
  return (
    <div className='offlineDiv'>
      <img className='offlineImage' src={offlineIcon} />
      <p className='offlineText'>System Off-line</p>
      <p className='offlineTextSmaller'>Reconnecting...</p>
    </div>
  )
}
