import React, { useEffect, useState } from 'react'
import { ChatButton } from '../Chat'
import { useSelector, useDispatch } from 'react-redux'
import { receiveRoom } from '../../../../store/reducers/chats'
import {
  ChatFilterOptions
} from '../../../../configs/config'

import { ChatFilter } from '../../../../components/Home/ChatFilter'
import { OfflineDiv } from '../../../../components/Home/OfflineDiv'

import { sortRoom } from '../../../../utils/roomsUtils'

export const ChatList = ({ onChatClick }) => {
  const chats = useSelector(state => state.chats)
  const dispatch = useDispatch()

  const [chatsConfig, setChatsConfig] = useState(
    {
      _id: '',
      search: '',
      filterOption: ChatFilterOptions.AllChats,
      FilterChatList: []
    }
  )

  const changeToChat = (chat) => {
    onChatClick()
    dispatch(receiveRoom(chat))
  }

  const filterChats = (chats, option) => {
    let chatList = chats
    if (option !== ChatFilterOptions.AllChats) { chatList = chatList.filter((x) => x.filter === option) }
    return chatList
  }

  const handleFilterChange = (option) => {
    const newChats = filterChats(chats.parsedRooms, option)
    setChatsConfig({
      ...chatsConfig,
      filterOption: option,
      FilterChatList: newChats,
      search: ''
    })
  }
  useEffect(() => {
    if (chats.parsedRooms) {
      const params = window.location.href.split('/')
      let sortedList = []
      const filteredChats = filterChats(chats.parsedRooms, chatsConfig.filterOption)
      sortedList = sortRoom([...filteredChats])
      setChatsConfig({
        ...chatsConfig,
        _id: params[params.length - 1],
        FilterChatList: sortedList
      })
    }
  }, [chats.parsedRooms, chats.currentChat])

  return (
    <ChatListContainer>
      <div className='sidebar-header sticky-top p-2'>
        <div className='d-flex justify-content-between align-items-center marginVertical10'>
          <h5 className='font-weight-semibold mb-0'>Chats</h5>
        </div>
        <ChatFilter
          selectedOption={chatsConfig.filterOption}
          filterOptionChanged={handleFilterChange}
          search={chatsConfig.search}
          handleSearch={() => {}}
        />
      </div>
      <ul
        className='contacts-list'
        id='chatContactTab'
      >
        {(chats.internetStatus === 'online' || chats.roomList.length > 0)
          ? chatsConfig.FilterChatList.map((chat, index) => {
              return (
                <ChatButton
                  key={'chat' + index}
                  {...chat}
                  active={chats.currentChat ? chats.currentChat._id === chat._id : false}
                  handleChatClick={() => { changeToChat(chat) }}
                />
              )
            })
          : <OfflineDiv />}
      </ul>
    </ChatListContainer>
  )
}

const ChatListContainer = (props) => {
  return (
    <aside className='sidebar'>
      <div className='tab-content'>
        <div className='tab-pane active' id='chats-content'>
          <div className='d-flex flex-column h-100'>
            <div className='hide-scrollbar h-100' id='chatContactsList'>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </aside>
  )
}
