import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { sendChatActionButton } from "../../../../services/chat/buttons";
import { sendMessage } from "../../../../store/reducers/chats";

import { CbButton, Container, CbButtonText, CBMessageContainer } from "./styles";

export const CbMessageComponent = ({ data, chatId }) => {
  const { profile, idToken } = useSelector((state) => state.main);
  const dispatch = useDispatch();

  const handleActionButtonClicked = ({ action, label, area, value }) => {
    sendChatActionButton(
      chatId,
      action,
      profile._id,
      data.owner,
      value,
      area,
      idToken
    );
    dispatch(sendMessage(label));
  };
  return (
    <CBMessageContainer>
      <Container>
        {data.buttons.map((button, index) => {
          return (
            <CbButton
              key={index}
              blueButton
              onClick={() => handleActionButtonClicked({ ...button })}
            >
              <CbButtonText blueButton>{button.label}</CbButtonText>
            </CbButton>
          );
        })}
      </Container>
      {/* <div className="message-content">
          <span>Pending</span>
        </div> */}
    </CBMessageContainer>
  );
};
