import { put, take, select } from "redux-saga/effects";
import {
  setIdToken,
  setActionButtons,
  setContactList,
  setProfile,
  clearData,
  cancelAllSagas,
  resetMainRedux,
  setBuckets,
  setToolsProfile,
  // setConfiguredPushNotification,
} from "../reducers/main";

import {
  getContactList,
  getProfile,
  loadActionButtons,
  getTools,
} from "../../services/userServices";
import { channel } from "@redux-saga/core";
import { getBuckets } from "../../services/userServices/actionButtons";

const mainEventChannel = channel();

export function* userLoggedInSaga(data) {
  yield put(
    setIdToken({
      idToken: data.payload.idToken,
      username: data.payload.username,
    })
  );

  const profile = yield getProfile(data.payload.username);
  yield put(setProfile(profile));

  const tools = yield getTools(profile.role, profile.company);
  yield put(setToolsProfile(tools[0]));

  const contactList = yield getContactList(profile.company);
  yield put(setContactList(contactList));

  // const actionButtons = yield loadActionButtons(profile.company);
  // yield put(setActionButtons(actionButtons));

  const buckets = yield getBuckets(profile.company);
  if (buckets) yield put(setBuckets(buckets));

  // if (window.ReactNativeWebView) {
  //   const data = {
  //     userId: profile._id || profile.id,
  //     type: "login",
  //   };
  //   window.ReactNativeWebView.postMessage(JSON.stringify(data));
  //   yield put(setConfiguredPushNotification(true));
  // }

  // yield put(connectToSocket())

  if (profile && contactList && buckets) {
    return true;
  }
  return false;
}

export function* userLoggetOutSaga(data) {
  const { socket } = yield select((state) => state.chats);
  socket.disconnect();
  yield put(resetMainRedux());
  yield put(cancelAllSagas());
  console.log("logout");
}

export function* startIdleTimerSaga(action) {
  let t;
  window.onload = resetTimer;
  window.onmousemove = resetTimer;
  window.onmousedown = resetTimer; // catches touchscreen presses as well
  window.ontouchstart = resetTimer; // catches touchscreen swipes as well
  window.onclick = resetTimer; // catches touchpad clicks as well
  window.onkeydown = resetTimer;
  window.addEventListener("scroll", resetTimer, true); // improved; see comments
  function goToButtons() {
    // mainEventChannel.put(setIsIdle(true))
    action.payload.push("/Home/ActionButtons");
  }

  function resetTimer() {
    // mainEventChannel.put(setIsIdle(false))
    clearTimeout(t);
    t = setTimeout(goToButtons, 60000); // time is in milliseconds
  }
}

export function* watchMainEventChannel() {
  while (true) {
    const action = yield take(mainEventChannel);
    yield put(action);
  }
}

export function* refreshActionButtonsSaga() {
  const { profile } = yield select((state) => state.main);

  // const actionButtons = yield loadActionButtons(profile.company);
  const buckets = yield getBuckets(profile.company);

  // yield put(setActionButtons(actionButtons));
  if (buckets) yield put(setBuckets(buckets));
}
