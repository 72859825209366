import React, { useEffect } from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  connectToSocket,
  removeNotification,
} from "../../store/reducers/chats";
import { Router, useHistory } from "react-router";
import {
  // setConfiguredPushNotification,
  startIdleTimer,
} from "../../store/reducers/main";

import { Chats } from "./Chats";
import { ActionButtons } from "./ActionButtons";
import { Friends } from "./Friends";
import { Investigations } from "./Investigations";
import { Tasks } from "./Tasks";
import { Alerts } from "./Alerts";
import { Reports } from "./Reports";
import { refreshActionButtons } from "../../store/reducers/main";
import { parseHashedMessage } from "../../utils/formatingUtils";

export const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const main = useSelector((state) => state.main);
  const { socket } = useSelector((state) => state.chats);

  const { unsentNotification } = useSelector((state) => state.chats);

  const _persist = useSelector((state) => state._persist);

  useEffect(() => {
    if (main.profile && main.idToken && !(socket && socket.connected)) {
      dispatch(startIdleTimer(history));
      dispatch(connectToSocket(history));
    }
  }, [main.profile, main.idToken]);

  useEffect(() => {
    if (_persist.rehydrated && !main.profile && !main.idToken) {
      history.push("/SignIn");
    }
  }, [_persist, main.profile, main.idToken]);

  useEffect(() => {
    unsentNotification.forEach((not) => {
      if (window.ReactNativeWebView) {
        try {
          const parsedMessage = parseHashedMessage(not.message);
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ ...not, message: parsedMessage })
          );
        } catch (err) {
          console.log(err);
          window.ReactNativeWebView.postMessage(JSON.stringify(not));
        }
      }
      dispatch(removeNotification(not));
    });
  }, [unsentNotification]);

  useEffect(() => {
    if (main.profile) {
      if (window.ReactNativeWebView) {
        const data = {
          userId: main.profile._id || main.profile.id,
          token: main.idToken,
          type: "login",
        };
        window.ReactNativeWebView.postMessage(JSON.stringify(data));
        // dispatch(setConfiguredPushNotification(true));
      }
    }
  }, [main.profile]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(refreshActionButtons());
    }, 40 * 1000);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <BrowserRouter>
      <Router history={history}>
        <Route
          exact
          path="/Home"
          render={() => {
            return <Redirect to="/Home/ActionButtons" />;
          }}
        />
        <Route path="/Home/Chats" component={Chats} />
        <Route path="/Home/ActionButtons" component={ActionButtons} />
        <Route path="/Home/Contacts" component={Friends} />
        <Route path="/Home/Investigations" component={Investigations} />
        <Route path="/Home/Tasks" component={Tasks} />
        <Route path="/Home/Alerts" component={Alerts} />
        <Route path="/Home/LPRStream" component={Reports} />
      </Router>
    </BrowserRouter>
  );
};
