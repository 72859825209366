import api from "../../api";
export const createInvestigation = async (
  company,
  description,
  images,
  owner,
  priority,
  status,
  title,
  transactions,
  users
) => {
  try {
    const created = Date.now();
    const ownerAlreadyInUsers = users.find((userId) => userId === owner);
    if (!ownerAlreadyInUsers) users.push(owner);

    const response = await api.post("/investigations", {
      company,
      created,
      description,
      images,
      owner,
      priority,
      status,
      title,
      transactions,
      users,
    });
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
    return false;
  } catch (err) {
    console.log(err);
    window.alert("Failed to create investigation");
    return false;
  }
};
