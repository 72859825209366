import styled from "styled-components";
import { colors } from "../../../configs/colors";

export const TagInputContainer = styled.div`
  border: 1px solid ${colors.GREY};
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  height: fit-content;
  padding: ${(props) => props.padding || 0}px;
`;
