import React, { useEffect } from "react";
import { NavBar } from "../../../components/Home";
import { Report } from "./Report";

import { useDispatch, useSelector } from "react-redux";
import { getReports, setCurrentReport } from "../../../store/reducers/reports";

export const Reports = () => {
  const _persist = useSelector((state) => state._persist);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReports());
  }, [_persist]);

  useEffect(() => {
    dispatch(setCurrentReport(null));
  }, []);

  return (
    <>
      <div className="chats-tab-open h-100">
        <div className="main-layout">
          <NavBar activeMenu="reports" />
          <Report />
          <div className="backdrop" />
        </div>
      </div>
    </>
  );
};
