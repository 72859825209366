import styled from "styled-components";

export const Title = styled.p`
  padding: 0px !important;
  margin: 0px 0px 0px 10px !important;
  font-size: 18px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 0px 10px 0px;
`;

export const DropzoneContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
`;
