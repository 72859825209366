import api from '../../api'
export const getContactList = async (company) => {
  try {
    const response = await api.get(`/users/chat/${company}`)
    if (response.status === 200) {
      return response.data
    }
    return false
  } catch (err) {
    console.log(err)
    window.alert('Failed to retrieve contact list')
    return false
  }
}
