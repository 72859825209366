import React, { useState, useMemo, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { dataURLtoBlob } from "../../../utils/formatingUtils";
import { WebcamCapture } from "../WebcamInput";

import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
  DropzoneText,
  SelectedFilesContainer,
  DropzoneContainer,
  SelectedFilesText,
  ResetButton,
  RBText,
  FilesContainer,
  SelectedFilesImg,
  UseCameraButton,
  UseCameraButtonText,
} from "./styles";

export const DropzoneArea = ({ onChange, sizeLimit = 4 }) => {
  const [files, setFiles] = useState([]);
  const [filesUrl, setFilesUrl] = useState([]);
  const [camera, setCamera] = useState(false);

  const handleDrop = (acceptedFiles) => {
    var _URL = window.URL || window.webkitURL;
    const parsedFiles = acceptedFiles.map((file) => {
      const url = _URL.createObjectURL(file);
      return { ...file, url };
    });
    setFiles(acceptedFiles);
    setFilesUrl(parsedFiles);
  };

  const handleDropRejected = (event) => {
    if (event.length > 0 && event[0].errors && event[0].errors.length > 0)
      return window.alert(`Error: ${event[0].errors[0].message}`);
    window.alert("Failed to insert file");
  };

  const handleCameraScreenshot = (imageSrc) => {
    var _URL = window.URL || window.webkitURL;
    const blob = dataURLtoBlob(imageSrc);
    const file = new File([blob], "image.png", { type: "image/png" });
    const url = _URL.createObjectURL(file);
    // console.log(url);
    setFiles([file]);
    setFilesUrl([{ ...file, url }]);
  };

  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    // Disable click and keydown behavior
    // noClick: true,
    noKeyboard: true,
    maxFiles: 5,
    maxSize: sizeLimit * 100 * 100 * 100,
    onDrop: handleDrop,
    onDropRejected: handleDropRejected,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const handleClearFiles = () => {
    setFiles([]);
  };

  useEffect(() => {
    onChange(files);
  }, [files]);

  if (files.length > 0) {
    return (
      <FilesContainer>
        <SelectedFilesContainer>
          {filesUrl.map((value, index) => {
            return (
              <SelectedFilesImg key={index} src={value.url}></SelectedFilesImg>
              // <SelectedFilesText key={index}>
              //   File: {value.path}
              // </SelectedFilesText>
            );
          })}
        </SelectedFilesContainer>
        <ResetButton onClick={handleClearFiles}>
          <RBText>Remove</RBText>
        </ResetButton>
      </FilesContainer>
    );
  }

  return camera ? (
    <DropzoneContainer>
      <UseCameraButton
        onClick={() => {
          setCamera(false);
        }}
      >
        <UseCameraButtonText>Use File</UseCameraButtonText>
      </UseCameraButton>
      <WebcamCapture handleCaptureScreenshot={handleCameraScreenshot} />
    </DropzoneContainer>
  ) : (
    <DropzoneContainer>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <DropzoneText>
          Drag and drop images or CLICK HERE to activate camera or browse
          folders.
        </DropzoneText>
      </div>
      <UseCameraButton
        onClick={() => {
          setCamera(true);
        }}
      >
        <UseCameraButtonText>Use Camera</UseCameraButtonText>
      </UseCameraButton>
    </DropzoneContainer>
  );
};
