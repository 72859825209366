import { put, take, select } from "redux-saga/effects";
import { loadTasks } from "../../services/userServices";
import { setTaskList, setTaskTransactionInfo } from "../reducers/tasks";

export function* getTasksSaga() {
  const { profile } = yield select((state) => state.main);
  const tasksList = yield loadTasks(profile.company, profile._id);
  yield put(setTaskList(tasksList));
}

export function* getTaskTransactionsSaga() {
  const { currentTask } = yield select((state) => state.tasks);
  if (!currentTask) return console.log("No current task");

  const transactionList = yield all(
    currentTask.transactions.map(async (transaction) => {
      const transactionInfo = await loadTaskTransaction(transaction);
      return transactionInfo[0];
    })
  );

  yield put(setTaskTransactionInfo(transactionList));
}
