import Select, { components, createFilter } from 'react-select'

import ArrowDownSelect from "../../../assets/media/icons/arrow_down_select.svg";

import { Label } from './styles'

function CustomSelect({
  className,
  placeholder,
  label,
  isMulti,
  options = [],
  ...rest
}) {
  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    stringify: (data) => data.label.toString()
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img
          className='icon-dropdown'
          src={ArrowDownSelect}
          alt='Ícone-select'
        />
      </components.DropdownIndicator>
    )
  }

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>Sem nenhuma opção</span>
      </components.NoOptionsMessage>
    )
  }

  return (
    <div className={className}>
      {label && (
        <Label>{label}</Label>
      )}

      <Select
        {...rest}
        isMulti={isMulti}
        components={{ DropdownIndicator, NoOptionsMessage }}
        placeholder={placeholder}
        filterOption={createFilter(filterConfig)}
        options={options}
        styles={{
          control: (baseStyles, state) => ({
            '& .icon-dropdown': {
              transition: 'all 0.3s',
              transform: state.menuIsOpen
                ? 'rotate(180deg)'
                : 'rotate(0deg)'
            },
            ...baseStyles,
            margin: '0.5rem 0 0 0',
            width: '100%',
            minHeight: '3.5rem',
            minWidth: '300px',
            appearance: 'none',
            boxShadow: 'none',
            padding: '0 0.82rem 0 0',
            borderRadius: '0.5rem',
            backgroundClip: 'padding-box',
            backgroundColor: '#fff',
            fontSize: '1rem',
            border: `1px solid '#222222'`,
            transition:
              'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
            color: '#222222'
          }),
          indicatorSeparator: (baseStyleIndSep) => ({
            ...baseStyleIndSep,
            backgroundColor: 'transparent'
          }),
          option: (baseStyleOption) => ({
            ...baseStyleOption,
            fontSize: '1rem'
          })
        }}
      />
    </div>
  )
}

export default CustomSelect
