export const colors = {
  BLUE: "#1a70d1",
  DARKBLUE: "#1c3663",
  RED: "#c71515",
  DARKRED: "#a01010",
  WHITE: "#fff",
  GREEN: "#27AE60",
  DARKGREEN: "#139930",
  GREY: "#aaaaaa",
  DARKGREY70: "#72869C",
  DARKGREY: "#50647B",
  BLACK: "#000000",
  FADEBLACK: "#00000088",
};
