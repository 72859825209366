import api from "../../api";
export const updateInvestigation = async (
  investigationId,
  company,
  description,
  images,
  owner,
  priority,
  status,
  title,
  transactions,
  users,
  created
) => {
  try {
    const response = await api.put(`/investigations/${investigationId}`, {
      company,
      created,
      description,
      images,
      owner,
      priority,
      status,
      title,
      transactions,
      users,
    });
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
    return false;
  } catch (err) {
    console.log(err);
    window.alert("Failed to create investigation");
    return false;
  }
};
