import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import reducer from './reducers'
import saga from './sagas'

import {
  persistStore, persistReducer
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'main',
  storage: storage,
  blacklist: ['chats']
  // whitelist: ['username', 'profile', 'idToken', 'contactList', 'actionButtons'],
}
const rootReducer = (state, action) => {
  if (action.type === 'main/clearData') {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem('persist:root')
    state = {}
  }
  return reducer(state, action)
}

const pReducer = persistReducer(persistConfig, rootReducer)

const devMode = process.env.NODE_ENV === 'development'
const sagaMiddleware = createSagaMiddleware()

const middleware = [...getDefaultMiddleware({
  thunk: false,
  serializableCheck: false
}), sagaMiddleware] // Add Saga middleware

const store = configureStore({
  reducer: pReducer,
  devTools: devMode,
  middleware
})
sagaMiddleware.run(saga)

const persistor = persistStore(store)

export { persistor, store }
