import React, { useState, useEffect, useRef } from "react";

import {
  DwButton,
  Container,
  DwButtonText,
  DwDownloadButton,
  DwMessage,
  DwMessageContainer,
} from "./styles";
import ReactHlsPlayer from "react-hls-player";

import { ReactComponent as VideoStreamSVG } from "../../../../assets/media/newicons/video-camera.svg";
import { ReactComponent as DownloadSVG } from "../../../../assets/media/newicons/download.svg";
import { ReactComponent as VMSCameraSVG } from "../../../../assets/media/newicons/vms-cam.svg";
import { ReactComponent as LayoutSVG } from "../../../../assets/media/newicons/layout.svg";
import { ReactComponent as VMSMultipleSVG } from "../../../../assets/media/newicons/vms-quad.svg";

import { useSelector } from "react-redux";

const isElectron = navigator.userAgent === "Electron";
const isIOS =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

export const DwVmsMessage = ({ data }) => {
  const [player, setPlayer] = useState(false);
  const [urlVideo, setUrlVideo] = useState(data.playerUrl);
  const playerRef = React.useRef();
  const { profile } = useSelector((state) => state.main);

  function openVms(vmsUrl) {
    if (isElectron) return console.log(JSON.stringify({ url: vmsUrl, type: 'link' }));
    if (window.ReactNativeWebView && isIOS) {
      const url = {
        url: vmsUrl,
        type: "dwVms",
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(url));
    } else {
      window.open(vmsUrl, "_blank");
    }
  }

  function downloadVideo() {
    if (data.downloadUrl) {
      if (isElectron)
        return console.log(JSON.stringify({ url: data.downloadUrl, type: 'link' }));
      if (window.ReactNativeWebView) {
        const url = {
          url: data.downloadUrl,
          type: "download",
        };
        window.ReactNativeWebView.postMessage(JSON.stringify(url));
      } else {
        window.open(data.downloadUrl, "_blank");
      }
    }
  }

  function togglePlayer(url) {
    // setUrlVideo(url)
    if (playerRef.current) {
      player ? playerRef.current.pause() : playerRef.current.play();
    }
    setPlayer(!player);
  }

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  function checkProfileVideoLink() {
    return typeof profile.videoLink !== "boolean" || profile.videoLink === true;
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <>
      {data.message && (
        <DwMessageContainer margin={!checkProfileVideoLink()}>
          <DwMessage>{data.message}</DwMessage>
        </DwMessageContainer>
      )}
      {checkProfileVideoLink() && (
        <Container>
          {/* {(isMobile || isElectron) && (
            <DwButton
              blueButton
              onClick={() => {
                openVms(data.vmsUrl);
              }}
            >
              <DwButtonText>Single Camera</DwButtonText>
            </DwButton>
          )} */}

          {data.liveView && (
            <DwButton
              color="#c71515"
              hoverColor="#108AD3"
              onClick={() => togglePlayer(data.liveView)}
              title="Live View"
            >
              <VideoStreamSVG />
            </DwButton>
          )}

          <DwButton
            color="#000B4F"
            hoverColor="#108AD3"
            onClick={() => togglePlayer(data.playerUrl)}
            title="Video Stream"
          >
            <VideoStreamSVG />
          </DwButton>

          {data.downloadUrl && (
            <DwButton
              color="#000B4F"
              hoverColor="#108AD3"
              onClick={downloadVideo}
              title="Download"
            >
              <DownloadSVG />
            </DwButton>
          )}

          <DwButton
            onClick={() => {
              openVms(data.vmsUrl);
            }}
            title="VMS Camera"
            color="#5BB7EC"
            hoverColor="#048ABF"
          >
            <VMSCameraSVG />
          </DwButton>

          {data.multipleCams && (
            <DwButton
              onClick={() => {
                openVms(data.multipleCams);
              }}
              title="VMS Quad"
              color="#5BB7EC"
              hoverColor="#048ABF"
            >
              <VMSMultipleSVG />
            </DwButton>
          )}
          {data.layouts && (
            <DwButton
              onClick={() => {
                openVms(data.layouts);
              }}
              title="VMS Layout"
              color="#5BB7EC"
              hoverColor="#048ABF"
            >
              <LayoutSVG />
            </DwButton>
          )}
        </Container>
      )}
      {player && (
        <ReactHlsPlayer
          playerRef={playerRef}
          src={urlVideo}
          autoPlay={false}
          controls={true}
          width={isMobile ? "100%" : "80%"}
          height="auto"
        />
      )}
    </>
  );
};
