import api from "../../api";

export const getBuckets = async (company) => {
  try {
    const tag = await window.localStorage.getItem('tag')
    const response = await api.get(tag ? `/buckets/company/${company}/tag/${tag}` :`/buckets/company/${company}`);
    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch (err) {
    console.log(err);
    // window.alert("Failed to retrieve buckets");
    return false;
  }
};
