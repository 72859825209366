import api from "../../api";
export const getProfile = async (username) => {
  try {
    const response = await api.get(`/users/detail/${username}`);
    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch (err) {
    console.log(err);
    window.alert("Failed to load user profile");
    return false;
  }
};

export const getTools = async (roleId, companyId) => {
  try {
    const response = await api.get(`/profiles/${companyId}/${roleId}`);
    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch (err) {
    console.log(err);
    window.alert("Failed to load user profile");
    return false;
  }
};
