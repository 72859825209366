export const validateTaskForm = (form) => {
  const errorFields = [];
  if (form.dateTime === "" || form.isoDateTime === "")
    errorFields.push("Start Date");
  if (!form.priority.length > 0) errorFields.push("Priority");
  if (!form.taskGroup.length > 0) errorFields.push("Task Group");
  if (!form.subject.length > 0) errorFields.push("Subject");
  if (!form.description.length > 0) errorFields.push("Description");
  return errorFields;
};
