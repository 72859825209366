import api from "../../api";
export const loadReports = async (store) => {
  try {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 30);
    const response = await api.post("/reports/KPI", {
      startDate: currentDate,
      endDate: futureDate,
      type: "LPR",
      store,
      level: "S",
    });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch (err) {
    console.log(err);
    window.alert("Failed to retrieve reports");
    return false;
  }
};
