import { headerColors } from "../../../../configs/config";
import styled from "styled-components";
import { colors } from "../../../../configs/colors";
const width = 48;
const height = 48;

export const headerStyle = {
  backgroundColor: colors.BLUE,
  width,
  height,
  font: width / 2 + "px Arial",
  color: "#FFF",
  textAlign: "center",
  lineHeight: height + "px",
  borderRadius: "50%",
};

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
  overflow-y: scroll;
`;

export const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  padding-right: 30px;
  margin: 10px 0px 30px 0px;
`;

export const ListIconDiv = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
