import React, { useEffect, useState, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as InvestigationSvg } from "../../../../assets/media/icons/search.svg";
import { ButtonDiv, Container, ContentContainer, headerStyle } from "./styles";
import {
  CommentsComponent,
  InvestigationContent,
  NewCommentComponent,
  UploadFiles,
} from "./components";
import { EyesonButton } from "../../../../components/Home/Button";
import { updateInvestigation } from "../../../../services/userServices/investigations";

import { getInvestigations } from "../../../../store/reducers/investigations";
import { UploadFile } from "../../../../services/chat";
import { newComment } from "../../../../services/userServices/investigations/newComment.investigations";
const initialInvestigationState = {
  newInvestigationMembers: [],
  comment: "",
  status: "unchanged",
  priority: "unchanged",
  files: [],
};

function InvestigationReducer(state, action) {
  if (action.id === "resetState") {
    return { ...state, ...initialInvestigationState };
  }
  return { ...state, [action.id]: action.value };
}

export const Investigation = ({ onBackClick }) => {
  const { currentInvestigation } = useSelector((state) => state.investigations);
  const { profile } = useSelector((state) => state.main);
  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useDispatch();
  const [investigationState, investigationDispatch] = useReducer(
    InvestigationReducer,
    initialInvestigationState
  );

  const handleInvestigationUpdate = async () => {
    setIsUpdating(true);
    const filesUrl = await Promise.all(
      investigationState.files.map(async (value) => {
        const formData = new FormData();
        formData.append("upload", value);
        const response = await UploadFile(formData);
        if (response.error) {
          window.alert("Failed to upload file");
        }
        return response.data.Location;
      })
    );
    const result = await updateInvestigation(
      currentInvestigation._id,
      currentInvestigation.company,
      currentInvestigation.description,
      [...currentInvestigation.images, ...filesUrl],
      currentInvestigation.owner,
      investigationState.priority === "unchanged"
        ? currentInvestigation.priority
        : investigationState.priority,
      investigationState.status === "unchanged"
        ? currentInvestigation.status
        : investigationState.status,
      currentInvestigation.title,
      currentInvestigation.transactions,
      [
        ...currentInvestigation.users,
        ...investigationState.newInvestigationMembers,
      ],
      currentInvestigation.created
    );
    if (investigationState.comment !== "")
      await handleNewComment(investigationState.comment);

    setIsUpdating(false);
    if (!result) return window.alert("Failed to update investigation");
    window.alert("Investigation updated successfully");
    investigationDispatch({ id: "resetState" });

    dispatch(getInvestigations());
  };

  const handleNewComment = async (comment) => {
    const fullName = `${profile.firstname} ${profile.lastname}`;
    const result = await newComment(
      currentInvestigation._id,
      fullName,
      profile._id,
      comment
    );
    if (!result) return false;
    return true;
  };

  useEffect(() => {
    investigationDispatch({ id: "resetState" });
  }, [currentInvestigation]);

  return (
    <div className="chats maxWidth">
      <div className="chat-body">
        <div className="chat-header invisibleHeader">
          <button
            className="btn btn-secondary btn-icon btn-minimal btn-sm text-muted d-xl-none"
            type="button"
            onClick={onBackClick}
          >
            <svg
              className="hw-20"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </button>
          <ul className="nav flex-nowrap" />
        </div>
        <div className="chat-content p-2 displayFlex">
          <Container>
            <InvestigationContent
              investigationDispatch={investigationDispatch}
            />
            <CommentsComponent comments={currentInvestigation.comments} />
            <NewCommentComponent
              investigationDispatch={investigationDispatch}
              commentValue={investigationState.comment}
            />
            <UploadFiles
              investigationDispatch={investigationDispatch}
              isUpdating={isUpdating}
            />
            <ButtonDiv>
              <EyesonButton
                onClick={handleInvestigationUpdate}
                blue
                buttonText={"Update"}
              />
            </ButtonDiv>
          </Container>
        </div>
        <div className="chat-footer"></div>
      </div>
    </div>
  );
};
