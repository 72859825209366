import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  typedUsername: "",
  typedPassword: "",
  rememberLogin: false,
  username: null,
  idToken: false,
  profile: null,
  contactList: [],
  actionButtons: [],
  buckets: [],
  toolsProfile: null,
  actionButtonClicked: null,
  logoutModal: {
    isVisible: false,
    title: "",
    twoButtons: false,
    firstShouldClose: true,
    buttonTitle: "",
    secondButtonTitle: "",
  },
  isIdle: false,
  chatBarVisible: false,
  // configuredPushNotification: false,
};
export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setIdToken: (state, action) => {
      state.idToken = action.payload.idToken;
      state.username = action.payload.username;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setRememberLogin: (state, action) => {
      state.rememberLogin = action.payload;
    },
    setContactList: (state, action) => {
      state.contactList = action.payload;
    },
    setActionButtons: (state, action) => {
      state.actionButtons = action.payload;
    },
    setBuckets: (state, action) => {
      state.buckets = action.payload;
    },
    setActionButtonClicked: (state, action) => {
      state.actionButtonClicked = action.payload;
    },
    setChatBarVisible: (state, action) => {
      state.chatBarVisible = action.payload;
    },
    setTypedCredentials: (state, action) => {
      state.typedUsername = action.payload.username;
      state.typedPassword = action.payload.password;
    },
    resetMainRedux: (state, action) => {
      return {
        ...initialState,
        typedPassword: state.rememberLogin ? state.typedPassword : "",
        typedUsername: state.rememberLogin ? state.typedUsername : "",
        rememberLogin: state.rememberLogin,
      };
    },
    setIsIdle: (state, action) => {
      state.isIdle = action.payload;
    },
    setLogoutModal: (state, action) => {
      state.logoutModal = action.payload;
    },
    setToolsProfile: (state, action) => {
      state.toolsProfile = action.payload;
    },
    // setConfiguredPushNotification: (state, action) => {
    //   state.configuredPushNotification = action.payload;
    // },
    userLoggedIn: (state, action) => {
      return state;
    },
    userLoggedOut: (state, action) => {
      return state;
    },
    startIdleTimer: (state, action) => {
      return state;
    },
    refreshActionButtons: (state, action) => {
      return state;
    },

    clearData() {}, // não remover, ação está sendo usada para remover data do local storage
    cancelAllSagas: (state, action) => {
      return state;
    },
  },
});

export const {
  setIdToken,
  setProfile,
  setContactList,
  userLoggedIn,
  setActionButtons,
  resetMainRedux,
  userLoggedOut,
  clearData,
  setActionButtonClicked,
  setChatBarVisible,
  setIsIdle,
  startIdleTimer,
  cancelAllSagas,
  setTypedCredentials,
  setLogoutModal,
  setRememberLogin,
  setBuckets,
  setToolsProfile,
  refreshActionButtons,
  // setConfiguredPushNotification,
} = mainSlice.actions;
export default mainSlice.reducer;
