import React, { useReducer, useEffect, useMemo } from "react";
import { TagInput, DropzoneForm } from "../../../../components/Home";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CloseSvg } from "../../../../assets/media/heroicons/solid/x.svg";

import {
  Container,
  Form,
  FadeBackground,
  EndContainer,
  MCInput,
  MCTitle,
  MContentContainer,
  MiddleContainer,
  TopContainer,
  MCSelect,
  MCOption,
  MCTextArea,
  HorizontalLine,
  EButton,
  CloseButtonContainer,
  CloseButton,
  CloseButtonImg,
} from "./styles";

import DatePicker from "react-datepicker";
import {
  setInvestigationFormVisible,
  getInvestigations,
} from "../../../../store/reducers/investigations";
import { createInvestigation } from "../../../../services/userServices/investigations";
import { validateInvestigationForm } from "./utils";
import { toBase64 } from "../../../../utils/formatingUtils";

const initialFormState = {
  dateTime: "",
  isoDateTime: "",
  businessGroup: "",
  region: "",
  store: "",
  priority: "Highest",
  nvrdvr: "0",
  cam: "",
  investigationGroup: [],
  subject: "",
  description: "",
  images: [],
};

function formReducer(state, action) {
  return { ...state, [action.id]: action.value };
}

export const InvestigationForm = () => {
  const dispatch = useDispatch();
  const { contactList, profile } = useSelector((state) => state.main);
  const formattedContactList = useMemo(() => {
    const result = contactList.map((value, index) => {
      const fullName = `${value.firstname} ${value.lastname}`;
      return { id: fullName, text: fullName, userId: value._id };
    });
    return result;
  }, [contactList]);

  const [formState, formDispatch] = useReducer(formReducer, initialFormState);

  const handleFilesChange = async (data) => {
    const files = data;
    let error = false;
    const base64Files = await Promise.all(
      files.map(async (file) => {
        try {
          return await toBase64(file);
        } catch (e) {
          return (error = true);
        }
      })
    );
    if (error) return window.alert("Failed to convert files to base64");
    formDispatch({ id: "images", value: base64Files });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateInvestigationForm(formState);
    if (errors.length > 0)
      return window.alert(`The fields ${errors.join(", ")} are required!`);
    const result = await createInvestigation(
      profile.company,
      formState.description,
      formState.images,
      profile._id,
      formState.priority,
      "Started",
      formState.subject,
      [],
      formState.investigationGroup
    );
    if (!result) window.alert("Failed to create investigation");
    dispatch(getInvestigations());
    dispatch(setInvestigationFormVisible(false));
  };

  const handleClose = () => {
    dispatch(setInvestigationFormVisible(false));
  };

  return (
    <FadeBackground
      id="investigationFormBackground"
      onClick={(e) => {
        if (e.target.id !== "investigationFormBackground") return;
        handleClose();
      }}
    >
      <Container>
        <CloseButtonContainer onClick={handleClose}>
          <CloseButton>
            <CloseSvg />
          </CloseButton>
        </CloseButtonContainer>
        <Form onSubmit={handleSubmit}>
          <TopContainer>
            <DropzoneForm
              onChange={(files) => {
                handleFilesChange(files);
              }}
            />
          </TopContainer>
          <MiddleContainer>
            <MContentContainer>
              <MCTitle>Select Date and Time</MCTitle>
              <DatePicker
                dateFormat={"MM/dd/yyyy HH:mm:ss"}
                selected={formState.dateTime}
                placeholderText={"mm/dd/yyyy 00:00:00"}
                className="DatePicker"
                showTimeSelect
                onChange={(value) => {
                  formDispatch({ id: "dateTime", value: value });
                  formDispatch({
                    id: "isoDateTime",
                    value: value.toISOString(),
                  });
                }}
              />
            </MContentContainer>
            <MContentContainer>
              <MCTitle>Business Group</MCTitle>
              <MCSelect
                onChange={(event) =>
                  formDispatch({
                    id: "businessGroup",
                    value: event.target.value,
                  })
                }
              >
                <MCOption value="">Select a business group</MCOption>
                {profile.profile.businesses.map((value, index) => {
                  return (
                    <MCOption key={index} value={value.id}>
                      {value.name}
                    </MCOption>
                  );
                })}
              </MCSelect>
            </MContentContainer>
            <MContentContainer>
              <MCTitle>Region</MCTitle>
              <MCSelect
                onChange={(event) =>
                  formDispatch({
                    id: "region",
                    value: event.target.value,
                  })
                }
              >
                <MCOption value="">Select a region</MCOption>
                {profile.profile.regions.map((value, index) => {
                  return (
                    <MCOption key={index} value={value.id}>
                      {value.name}
                    </MCOption>
                  );
                })}
              </MCSelect>
            </MContentContainer>
            <MContentContainer>
              <MCTitle>Store</MCTitle>
              <MCSelect
                onChange={(event) =>
                  formDispatch({
                    id: "store",
                    value: event.target.value,
                  })
                }
              >
                <MCOption value="">Select a store</MCOption>
                {profile.profile.stores.map((value, index) => {
                  return (
                    <MCOption key={index} value={value.id}>
                      {value.name}
                    </MCOption>
                  );
                })}
              </MCSelect>
            </MContentContainer>
            <MContentContainer>
              <MCTitle>Priority</MCTitle>
              <MCSelect
                onChange={(event) =>
                  formDispatch({ id: "priority", value: event.target.value })
                }
              >
                <MCOption value="Highest">Highest priority</MCOption>
                <MCOption value="High">High priority</MCOption>
                <MCOption value="Normal">Normal priority</MCOption>
                <MCOption value="Low">Low priority</MCOption>
              </MCSelect>
            </MContentContainer>
            <MContentContainer>
              <MCTitle>NVR/DVR</MCTitle>
              <MCSelect
                onChange={(event) =>
                  formDispatch({ id: "nvrdvr", value: event.target.value })
                }
              >
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value, index) => {
                  return (
                    <MCOption value={value} key={index}>
                      {value}
                    </MCOption>
                  );
                })}
              </MCSelect>
            </MContentContainer>
            <MContentContainer>
              <MCTitle>#CAM</MCTitle>
              <MCInput
                type="string"
                onChange={(event) =>
                  formDispatch({ id: "cam", value: event.target.value })
                }
                placeholder="CAM"
              />
            </MContentContainer>
            <MContentContainer>
              <MCTitle>Investigation Group:</MCTitle>
              <TagInput
                onChange={(values) =>
                  formDispatch({
                    id: "investigationGroup",
                    value: values.map((value) => value.userId),
                  })
                }
                suggestions={formattedContactList}
                allowOnlyFromSuggestion
              />
            </MContentContainer>
            <MContentContainer>
              <MCTitle>Subject</MCTitle>
              <MCInput
                type="string"
                onChange={(event) =>
                  formDispatch({ id: "subject", value: event.target.value })
                }
                placeholder="Subject"
              />
            </MContentContainer>
            <MContentContainer>
              <MCTitle>Description</MCTitle>
              <MCTextArea
                type="string"
                onChange={(event) =>
                  formDispatch({ id: "description", value: event.target.value })
                }
                placeholder="Description"
              />
            </MContentContainer>
          </MiddleContainer>
          <EndContainer>
            <HorizontalLine />
            <EButton type="submit" value="Create Investigation" />
          </EndContainer>
        </Form>
      </Container>
    </FadeBackground>
  );
};
