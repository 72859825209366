import styled from "styled-components";
import { colors } from "../../../configs/colors";
export const EyesonBtn = styled.div`
  background-color: ${(props) =>
    props.blue ? colors.DARKBLUE : colors.DARKRED};
  width: 140px;
  border-radius: 10px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  &:hover {
    background-color: ${(props) => (props.blue ? colors.BLUE : colors.RED)};
  }
`;

export const EyesonBtnText = styled.p`
  text-align: center;
  margin: 0px !important;
  color: ${colors.WHITE};
  padding: 0px !important;
  line-height: 0px !important;
`;
