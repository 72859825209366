import React from 'react'

export const HomeInput = (props) => {
  return (
    <div
      className='form-group'
    >
      <label className='sr-only'>{props.labeltext}</label>
      <input
        className='form-control form-control-md'
        {...props}
      />
    </div>
  )
}
