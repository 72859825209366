import {
  userLoggedInSaga,
  userLoggetOutSaga,
  startIdleTimerSaga,
  watchMainEventChannel,
  refreshActionButtonsSaga,
} from "./main";
import {
  connectToSocketSaga,
  watchSocketEventChannel,
  receiveRoomListSaga,
  loadChatSaga,
  receiveRoomSaga,
  sendMessageSaga,
  receiveMessageSaga,
  newRoomSaga,
  createNewRoomSaga,
  updateUnreadMessagesSaga,
  reconnectToSocketSaga,
  changeChatButtonPosSaga,
  sendFileSaga,
} from "./chats";

import { all, takeEvery, fork } from "redux-saga/effects";
import {
  refreshActionButtons,
  startIdleTimer,
  userLoggedIn,
  userLoggedOut,
} from "../reducers/main";
import { getTasks, getTasksTransactions } from "../reducers/tasks";

import {
  connectToSocket,
  receiveRoomList,
  loadChat,
  receiveRoom,
  sendMessage,
  receiveMessage,
  newRoom,
  createNewRoom,
  updateUnreadMessages,
  reconnectToSocket,
  changeChatButtonPos,
  sendFile,
} from "../reducers/chats";
import { getTasksSaga, getTaskTransactionsSaga } from "./tasks";
import {
  getInvestigations,
  getInvestigationsTransactions,
} from "../reducers/investigations";
import {
  getInvestigationsSaga,
  getInvestigationTransactionsSaga,
} from "./investigations";
import { getAlertInfo, getAlerts, updateAlert } from "../reducers/alerts";
import { getAlertInfoSaga, getAlertsSaga, updateAlertSaga } from "./alerts";
import { getReports } from "../reducers/reports";
import { getReportsSaga } from "./reports";
// import { channel } from 'redux-saga'

export default function* rootSaga() {
  yield all([
    takeEvery(userLoggedIn, userLoggedInSaga),
    takeEvery(connectToSocket, connectToSocketSaga),
    takeEvery(receiveRoomList, receiveRoomListSaga),
    takeEvery(loadChat, loadChatSaga),
    takeEvery(receiveRoom, receiveRoomSaga),
    takeEvery(sendMessage, sendMessageSaga),
    takeEvery(receiveMessage, receiveMessageSaga),
    takeEvery(newRoom, newRoomSaga),
    takeEvery(userLoggedOut, userLoggetOutSaga),
    takeEvery(createNewRoom, createNewRoomSaga),
    takeEvery(updateUnreadMessages, updateUnreadMessagesSaga),
    takeEvery(startIdleTimer, startIdleTimerSaga),
    takeEvery(reconnectToSocket, reconnectToSocketSaga),
    takeEvery(changeChatButtonPos, changeChatButtonPosSaga),
    takeEvery(sendFile, sendFileSaga),
    takeEvery(getTasks, getTasksSaga),
    takeEvery(getInvestigations, getInvestigationsSaga),
    takeEvery(getAlerts, getAlertsSaga),
    takeEvery(getAlertInfo, getAlertInfoSaga),
    takeEvery(updateAlert, updateAlertSaga),
    takeEvery(getReports, getReportsSaga),
    takeEvery(refreshActionButtons, refreshActionButtonsSaga),
    takeEvery(getInvestigationsTransactions, getInvestigationTransactionsSaga),
    takeEvery(getTasksTransactions, getTaskTransactionsSaga),
    fork(watchSocketEventChannel),
    fork(watchMainEventChannel),
  ]);
}
