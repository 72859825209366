import React from 'react'

export const MainContainer = (props) => {
  return (
    <div className='main-layout card-bg-1'>
      <div className='container d-flex flex-column'>
        <div className='row no-gutters text-center align-items-center justify-content-center min-vh-100'>
          <div className='col-12 col-md-6 col-lg-5 col-xl-4'>
            {props.children}
          </div>
        </div>
      </div>
      <AppVersionText />
    </div>
  )
}

const AppVersionText = () => {
  return (
    <div className='appVersionDiv'>
      <p className='appVersionText'>
        {process.env.REACT_APP_APPVERSION}
      </p>
    </div>
  )
}
