import styled from "styled-components";
import { colors } from "../../../configs/colors";

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex: 1;
`;

export const SelectedFilesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 70%;
  overflow-y: auto;
  justify-content: center;
  flex-direction: column;
  margin-left: 5px;
`;

export const UseCameraButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.DARKBLUE};
  border-radius: 6px;
  width: 100px;
  margin: 10px;
  padding: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: ${colors.BLUE};
  }
`;

export const UseCameraButtonText = styled.p`
  padding: 0px !important;
  margin: 0px !important;
  color: ${colors.WHITE};
`;

export const SelectedFilesImg = styled.img`
  height: 100px;
  max-width: 100%;
  margin: 10px;
`;
export const SelectedFilesText = styled.p`
  font-size: 14px;
  padding: 0px !important;
  margin: 0px !important;
  line-height: 1.4;
`;

export const ResetButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.DARKBLUE};
  border-radius: 4px;
  width: 90px;
  height: 35px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: ${colors.BLUE};
  }
`;
export const RBText = styled.p`
  padding: 0px !important;
  margin: 0px !important;
  color: ${colors.WHITE};
`;

export const FileContainer = styled.div``;

export const DropzoneContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px;
`;

export const DropzoneText = styled.p`
  padding: 0px !important;
  margin: 0px !important;
  line-height: 1.4;
  text-align: center;
`;

export const baseStyle = {
  display: "flex",
  flex: 1,
  minWidth: "90%",
  minHeight: "90%",
  alignItems: "center",
  justifyContent: "center",
  padding: "30px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

export const focusedStyle = {
  borderColor: "#2196f3",
};

export const acceptStyle = {
  borderColor: "#00e676",
};

export const rejectStyle = {
  borderColor: "#ff1744",
};

export const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

export const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: "fit-content",
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

export const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

export const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
