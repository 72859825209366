import styled from "styled-components";
import { colors } from "../../../../../../configs/colors";

export const Title = styled.p`
  padding: 0px !important;
  margin: 0px 0px 0px 10px !important;
  font-size: 18px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CommentsContainer = styled.div`
  overflow-y: auto;
  min-height: 100px;
  height: fit-content;
  max-height: 300px;
`;

export const HorizontalLine = styled.div`
  height: 1px;
  width: 100%;
  margin: 20px 0px 0px 0px;
  background-color: ${colors.GREY};
`;

export const Comment = styled.div`
  margin: 20px 0px 20px 0px;
`;
export const CommentHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
export const CommentTitle = styled.p`
  padding: 0px !important;
  margin: 0px !important;
  font-weight: 500;
  margin-right: 5px;
`;
export const DotSeparator = styled.div`
  width: 3px;
  height: 3px;
  justify-self: center;
  align-self: center;
  background-color: ${colors.GREY};
  margin: 0px 10px 0px 10px;
  border-radius: 50%;
`;
export const CommentDate = styled.p`
  padding: 0px !important;
  margin: 0px !important;
  color: ${colors.GREY};
`;

export const CommentContent = styled.p`
  padding: 0px !important;
  margin: 5px 0px 5px 0px !important;
  font-size: 14px;
`;
