import React, { useMemo } from "react";
import { ContentContainer } from "../styles";
import { ReactComponent as TableArrow } from "../../../../../../assets/media/heroicons/solid/selector.svg";
import { ReactComponent as CamIcon } from "../../../../../../assets/media/icons/development.svg";
import { getContactName } from "../../../../../../utils/hooks";
import moment from "moment";
import {
  Title,
  StatusContainer,
  StatusText,
  Status,
  UsersContainer,
  UCCContainer,
  UCTitle,
  UCContentDiv,
  UContainer,
  UCCCText,
  DescriptionContainer,
  DCTitle,
  DCText,
  Divisor,
  VTRow,
  VTHeader,
  VTCell,
  TableContainer,
  Table,
  StatusSpamContainer,
  tagInputClassNames,
} from "./styles";
import { useSelector } from "react-redux";
import { TagInput } from "../../../../../../components/Home";
import "./styles.css";

export const AlertContent = ({ alertDispatch }) => {
  const { contactList } = useSelector((state) => state.main);
  const { title, status, priority, owner, users, description, company } =
    useSelector((state) => state.alerts.currentAlert);
  const transactions = useSelector((state) => state.alerts.currentAlertDetail);
  const spacing = 30;
  const usersNames = users.map((user) => {
    return getContactName(user, contactList, "_id");
  });
  const ownerName = getContactName(owner, contactList, "_id");

  const handleTagInputChange = (data) => {
    alertDispatch({
      id: "newAlertMembers",
      value: data.map((value) => value.userId),
    });
  };

  return (
    <ContentContainer>
      <StatusComponent priority={priority} status={status} title={title} />
      <Divisor amount={spacing} />
      <UsersComponent
        owner={ownerName}
        users={usersNames}
        onChange={handleTagInputChange}
      />
      <Divisor amount={spacing} />
      <DescriptionComponent description={description} />
      <Divisor amount={spacing} />
      <TableComponent transactions={transactions} contactList={contactList} />
    </ContentContainer>
  );
};

const StatusComponent = ({ status, priority, title }) => {
  return (
    <StatusContainer>
      <Title>{title}</Title>
      <StatusSpamContainer>
        <Status>
          <StatusText>{status}</StatusText>
        </Status>
        <Status>
          <StatusText>{priority}</StatusText>
        </Status>
      </StatusSpamContainer>
    </StatusContainer>
  );
};

const UsersComponent = ({ owner, users, onChange }) => {
  const { contactList } = useSelector((state) => state.main);
  const { currentAlert } = useSelector((state) => state.alerts);

  const formattedContactList = useMemo(() => {
    const result = contactList.map((value, index) => {
      const fullName = `${value.firstname} ${value.lastname}`;
      return { id: fullName, text: fullName, userId: value._id };
    });
    return result;
  }, [contactList]);
  const formattedUserList = useMemo(() => {
    const result = users.map((value, index) => {
      return { id: value, text: value };
    });
    return result;
  }, [users]);

  const handleChange = (data) => {
    const changedTags = data.filter(
      (value) => !formattedUserList.find((user) => user.id === value.id)
    );
    onChange(changedTags);
  };
  return (
    <UsersContainer>
      <UContainer>
        <UCTitle>Alert Created by:</UCTitle>
        <UCContentDiv>
          <UCCContainer>
            <UCCCText>{owner}</UCCCText>
          </UCCContainer>
        </UCContentDiv>
      </UContainer>
      <UContainer>
        <UCTitle>Alert Team:</UCTitle>
        <TagInput
          onChange={handleChange}
          resetOnValueChange
          value={currentAlert}
          allowOnlyFromSuggestion
          classNames={tagInputClassNames}
          initialTags={formattedUserList}
          suggestions={formattedContactList}
          placeholder=" "
          shouldDeleteInitial={false}
          padding={0}
        />
      </UContainer>
    </UsersContainer>
  );
};

const DescriptionComponent = ({ description }) => {
  return (
    <DescriptionContainer>
      <DCTitle>Description</DCTitle>
      <DCText>{description}</DCText>
    </DescriptionContainer>
  );
};

const TableComponent = ({ transactions, contactList }) => {
  return (
    <TableContainer>
      <Table>
        <thead>
          <VTRow>
            <TableHeaderComponent>Creation Date</TableHeaderComponent>
            <TableHeaderComponent>Transaction Date</TableHeaderComponent>
            <TableHeaderComponent>Store</TableHeaderComponent>
            <TableHeaderComponent>Description</TableHeaderComponent>
            <TableHeaderComponent>Employee</TableHeaderComponent>
            <TableHeaderComponent>Quantity</TableHeaderComponent>
          </VTRow>
        </thead>
        <tbody>
          {transactions &&
            transactions.map((t, index) => {
              const formattedCreationDate = moment(t.creation_date).format(
                "MM/DD/YYYY HH:mm:ss"
              );
              const formattedTransactionDate = moment(
                t.transaction_date
              ).format("MM/DD/YYYY HH:mm:ss");
              const storeName = getContactName(t.store, contactList, "company");
              return (
                <VTRow key={index}>
                  <VTCell to="Creation Date">{formattedCreationDate}</VTCell>
                  <VTCell to="Transaction Date">
                    {formattedTransactionDate}
                  </VTCell>
                  <VTCell to="Store">{storeName}</VTCell>
                  <VTCell to="Description">{t.description}</VTCell>
                  <VTCell to="Employee">{t.employee_name}</VTCell>
                  <VTCell to="Quantity">{t.quantity}</VTCell>
                </VTRow>
              );
            })}
        </tbody>
      </Table>
    </TableContainer>
  );
};

const TableHeaderComponent = (props) => {
  return (
    <VTHeader>
      {props.children}
      <TableArrow />
    </VTHeader>
  );
};
