import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
  margin: 20px;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
  overflow-y: scroll;
`;
