import React, { useEffect } from "react";
import { NavBar } from "../../../components/Home";
import { ActionList } from "./ActionList";
import { setCurrentChat } from "../../../store/reducers/chats";
import { useDispatch } from "react-redux";

export const ActionButtons = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentChat(null));
  }, []);
  return (
    <div className="chats-tab-open h-100">
      <div className="main-layout h-100">
        <NavBar activeMenu="actionButtons" />
        <ActionList />
        <div className="backdrop" />
      </div>
    </div>
  );
};
