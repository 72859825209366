import api from "../../api";
export const updateAlert = async (
  alertId,
  priority,
  status,
  comment,
  user
) => {
  try {
    const response = await api.put(`/emesages/${alertId}`, {
      status,
      priority,
      comment,
      user
    });
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
    return false;
  } catch (err) {
    console.log(err);
    window.alert("Failed to create alert");
    return false;
  }
};
