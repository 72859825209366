import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ChatsSvg } from "../../../assets/media/icons/chats.svg";
import { ReactComponent as EButtonsSvg } from "../../../assets/media/newicons/view-grid.svg";
import { ReactComponent as FriendsSvg } from "../../../assets/media/icons/friends.svg";
import { ReactComponent as QuitSvg } from "../../../assets/media/newicons/logout.svg";
import { ReactComponent as InvestigationSvg } from "../../../assets/media/newicons/search-circle.svg";
import { ReactComponent as TasksSvg } from "../../../assets/media/newicons/clipboard-list.svg";
import { ReactComponent as AlertsSvg } from "../../../assets/media/newicons/bell.svg";
import { ReactComponent as ReportsSvg } from "../../../assets/media/newicons/reportIcon.svg";
import { ReactComponent as SlidersSvg } from "../../../assets/media/icons/sliders.svg";

import { useSelector, useDispatch } from "react-redux";
import { userLoggedOut, setLogoutModal, refreshActionButtons } from "../../../store/reducers/main";
import CustomSelect from "../Select";
import api from "../../../services/api";

const isElectron = navigator.userAgent === "Electron";

// import { ReactComponent as CallsSvg } from '../../assets/media/icons/calls.svg'
// import { ReactComponent as FriendsSvg } from '../../assets/media/icons/friends.svg'
// import { ReactComponent as AccountSvg } from '../../assets/media/icons/accounts.svg'

export const NavBar = (props) => {
  const [tagModal, setTagModal] = useState(false)
  const [options, setOptions] = useState([]);
  const [tag, setTag] = useState(null);

  const dispatch = useDispatch();
  const { unreadCount } = useSelector((state) => state.chats);
  const { toolsProfile } = useSelector((state) => state.main);
  const main = useSelector((state) => state.main);

  const handleLogout = () => {
    dispatch(
      setLogoutModal({
        isVisible: true,
        title: "Quit eBuzz?",
        twoButtons: true,
        firstShouldClose: false,
        buttonTitle: "Yes",
        secondButtonTitle: "No",
      })
    );
  };

  useEffect(() => {
    getButtonsTags(true)
  }, [main?.profile])

  useEffect(() => {
    const interval = setInterval(() => {
      getButtonsTags();
    }, 40 * 1000);
    return () => {
      clearInterval(interval);
    };
  });

  async function getButtonsTags(init) {
    if(main?.profile?.company){
      const result = await api.get(`/tags/${main?.profile?.company}`);
      setOptions(
        result.data.map(i => ({label: i.name, value: i._id}))
      )
      if(init) {
        const tagLocal = window.localStorage.getItem('tag')
        if(tagLocal) {
          const tagInit = result.data.find(i => i._id === tagLocal)
          if(tagInit) {
            setTag({label: tagInit.name, value: tagInit._id})
          }
        }
      }
    }
  }

  async function changeTag(event) {
    if(event) {
      setTag(event)
      window.localStorage.setItem('tag', event.value)
    } else {
      setTag('')
      window.localStorage.removeItem('tag')
    }
    dispatch(refreshActionButtons());
    setTagModal(false)
  }


  // useEffect(() => {
  //   if (!idToken) history.push('/SignIn')
  // }, [idToken])
  return (
    <div className="navigation navbar navbar-light bg-primary">
      <ul
        className="nav nav-minimal flex-row flex-grow-1 justify-content-between flex-xl-column justify-content-xl-center"
        id="mainNavTab"
        role="tablist"
      >
        {toolsProfile && toolsProfile.tools.find((tool) => tool === "LPR") && (
          <li className="nav-item">
            <Link
              className={
                "nav-link p-0 py-xl-3 " +
                (props.activeMenu === "reports" ? "active" : "")
              }
              id="reports-tab"
              to="/Home/LPRStream"
              title="LPR Stream"
            >
              <ReportsSvg />
            </Link>
          </li>
        )}
        {toolsProfile && toolsProfile.tools.find((tool) => tool === "ALERTS") && (
          <li className="nav-item">
            <Link
              className={
                "nav-link p-0 py-xl-3 " +
                (props.activeMenu === "alerts" ? "active" : "")
              }
              id="alerts-tab"
              to="/Home/Alerts"
              title="Alerts"
            >
              <AlertsSvg />
            </Link>
          </li>
        )}

        {toolsProfile && toolsProfile.tools.find((tool) => tool === "TASKS") && (
          <li className="nav-item">
            <Link
              className={
                "nav-link p-0 py-xl-3 " +
                (props.activeMenu === "tasks" ? "active" : "")
              }
              id="tasks-tab"
              to="/Home/Tasks"
              title="Tasks"
            >
              <TasksSvg />
            </Link>
          </li>
        )}
        {toolsProfile &&
          toolsProfile.tools.find((tool) => tool === "INVESTIGATIONS") && (
            <li className="nav-item">
              <Link
                className={
                  "nav-link p-0 py-xl-3 " +
                  (props.activeMenu === "investigations" ? "active" : "")
                }
                id="investigations-tab"
                to="/Home/Investigations"
                title="Investigations"
              >
                <InvestigationSvg />
              </Link>
            </li>
          )}

        <li className="nav-item">
          <Link
            className={
              "nav-link p-0 py-xl-3 " +
              (props.activeMenu === "actionButtons" ? "active" : "")
            }
            id="actions-tab"
            to="/Home/ActionButtons"
            title="eButtons"
          >
            <EButtonsSvg />
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              "nav-link p-0 py-xl-3 " +
              (props.activeMenu === "chat" ? "active" : "")
            }
            id="chats-tab"
            to="/Home/Chats"
            title="Chats"
          >
            <ChatsSvg />
            <div
              id="navChat"
              className="badge badge-rounded badge-primary ml-1"
            >
              {unreadCount !== 0 ? unreadCount : ""}
            </div>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              "nav-link p-0 py-xl-3 " +
              (props.activeMenu === "friends" ? "active" : "")
            }
            id="friends-tab"
            to="/Home/Contacts"
            title="Contacts"
          >
            <FriendsSvg />
          </Link>
        </li>
        <li className="nav-item">
          <a
            className={"nav-link p-0 py-xl-3 " + (props.activeMenu === "")}
            id="tag"
            onClick={() => setTagModal(true)}
            title="Select tag"
          >
            <SlidersSvg fill="#aea9fe" height={24} width={24} className="clickable" />
          </a>
        </li>
        <li className="nav-item">
          <a
            className={"nav-link p-0 py-xl-3 " + (props.activeMenu === "")}
            id="logout"
            onClick={() => {
              handleLogout();
            }}
            title="Quit eBuzz"
          >
            <QuitSvg />
          </a>
        </li>
      </ul>

      {tagModal && (
        <div className='fadeBackground'>
          <div className='tagModalContainer'>
            <div className='modalTitleContainer'>
              <p className='logoutModalTitle'>Select the tag to filter</p>
            </div>
            <div>
              {tag && (
                <h3>
                  Current tag: {tag?.label}
                </h3>
              )}
              
              <CustomSelect
                isClearable
                isMulti={false}
                options={options}
                value={tag}
                onChange={e => changeTag(e)}
                placeholder='Select the tag to filter'
              />
            </div>

            <div className='modalButtonsContainer'>
              <div className='logoutModalButton backgroundRed' onClick={() => setTagModal(false)}>
                <p className='logoutModalButtonText'>Close</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
