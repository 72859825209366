import React, { useEffect, useMemo, useRef } from "react";
import { ContentContainer } from "../styles";
import { ReactComponent as TableArrow } from "../../../../../../assets/media/heroicons/solid/selector.svg";
import { getContactName } from "../../../../../../utils/hooks";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { DataTable } from "./tableData";
import "./styles.css";
import { setCurrentReport } from "../../../../../../store/reducers/reports";

export const ReportContent = () => {
  const { reportList } = useSelector((state) => state.reports);
  const dispatch = useDispatch();
  const parsedData = ParseReportList(reportList);

  const handleRowClick = (trans_id, plate) => {
    const selectedReport = reportList.find(
      (rprt) => rprt.trans_id == trans_id && rprt.description === plate
    );
    dispatch(setCurrentReport(selectedReport));
  };

  return (
    <ContentContainer>
      {reportList.length > 0 && (
        <DataTable data={parsedData} handleRowClick={handleRowClick} />
      )}
    </ContentContainer>
  );
};

const ParseReportList = (reportList) => {
  return reportList.map((report) => {
    const date = new Date(report.utc_datetime);
    const parsedTime = moment(date).format("hh:mm:ss A");
    return [
      report.evms,
      parsedTime,
      `<img className='plateImage' src='${report.field6}' />`,
      report.description,
      report.field7,
      report.person_type,
      report.person_category,
      report.comments,
      report.trans_id,
    ];
  });
};
