import React, { useEffect, useState } from "react";
import { InvestigationButton } from "../Investigation";
import { useSelector, useDispatch } from "react-redux";
import {
  setCurrentInvestigation,
  setInvestigationFormVisible,
} from "../../../../store/reducers/investigations";
import { NewInvestigationButton, NewInvestigationButtonText } from "./styles";

export const InvestigationList = ({ onInvestigationClick }) => {
  const investigations = useSelector((state) => state.investigations);
  const dispatch = useDispatch();

  const changeToInvestigation = (investigation) => {
    dispatch(setCurrentInvestigation(investigation));
    onInvestigationClick();
  };

  const openInvestigation = () => {
    dispatch(setInvestigationFormVisible(true));
  };

  return (
    <InvestigationListContainer>
      <div className="sidebar-header sticky-top p-2">
        <div
          className="d-flex justify-content-between align-items-center marginVertical10"
          style={{ margin: 10 }}
        >
          <h5 className="font-weight-semibold mb-0">Investigations</h5>
          <NewInvestigationButton onClick={openInvestigation}>
            <NewInvestigationButtonText>
              New Investigation
            </NewInvestigationButtonText>
          </NewInvestigationButton>
        </div>
      </div>
      <ul className="contacts-list" id="chatContactTab">
        {investigations.investigationList &&
          investigations.investigationList.map((investigation, index) => {
            return (
              <InvestigationButton
                key={"investigation" + index}
                {...investigation}
                active={
                  investigations.currentInvestigation
                    ? investigations.currentInvestigation._id ===
                      investigation._id
                    : false
                }
                handleInvestigationClick={() => {
                  changeToInvestigation(investigation);
                }}
              />
            );
          })}
      </ul>
    </InvestigationListContainer>
  );
};

const InvestigationListContainer = (props) => {
  return (
    <aside className="sidebar">
      <div className="tab-content">
        <div className="tab-pane active" id="investigations-content">
          <div className="d-flex flex-column h-100">
            <div className="hide-scrollbar h-100" id="chatContactsList">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};
