import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { contactLookup, getAvatar } from "../../../../utils/roomsUtils";
import {
  sendMessage,
  setShowDocument,
  setUploadModalVisible,
} from "../../../../store/reducers/chats";
import moment from "moment";
import { DropzoneArea } from "../../../../components/Home/Dropzone";
import parse from "html-react-parser";
import { DwVmsMessage } from "../../../../components/Home/MessageTypes/DwVms";
import { CbMessageComponent } from "../../../../components/Home/MessageTypes/ChatButtons";
import CheckIcon from "../../../../assets/media/icons/check.svg";
import CheckOkIcon from "../../../../assets/media/icons/check-ok.svg";

export const Chat = ({ onBackClick }) => {
  const { currentChat } = useSelector((state) => state.chats);
  const { profile } = useSelector((state) => state.main);
  const { contactList } = useSelector(state => state.main)
  const messagesEndRef = useRef(null)

  const dispatch = useDispatch();

  const [inputMessage, setInputMessage] = useState("");
  let messagesEnd;
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView()
  };

  const handleSendMessage = () => {
    if (inputMessage !== "") {
      dispatch(sendMessage(inputMessage));
      setInputMessage("");
    }
  };

  const handleInput = (event) => {
    setInputMessage(event.target.value);
  };

  const handleUploadClick = () => {
    dispatch(setUploadModalVisible(true));
  };
  
  useEffect(() => {
    scrollToBottom();
    setTimeout(scrollToBottom, 100);
  }, [currentChat, currentChat.messages]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleSendMessage();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [inputMessage]);

  return (
    <div className="chats maxWidth">
      <DropzoneArea />
      <div className="chat-body">
        <div className="chat-header">
          <button
            className="btn btn-secondary btn-icon btn-minimal btn-sm text-muted d-xl-none"
            type="button"
            onClick={onBackClick}
          >
            <svg
              className="hw-20"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </button>

          <div className="row chat-name align-items-center text-truncate w-100">
            <div
              id="avatarChatBox"
              className="avatar d-none d-sm-inline-block col-4 col-sm-1"
            >
              {currentChat && currentChat.Group ? (
                <GroupHeader />
              ) : (
                <SingleHeader
                  name={currentChat ? currentChat.roomName : "Name Unknown"}
                />
              )}
            </div>

            <div className="col-4 col-sm-2">
              <h6 id="chatName" className="text-truncate mb-0">
                {currentChat && currentChat.roomName}
              </h6>
              <small id="userStatus" className="text-muted" />
            </div>
            {currentChat && currentChat.Group && (
              <div className="col-9 col-sm-7 col-lg-8 d-flex">
                <h6 className="mt-2 ">
                  Members
                </h6>
                <div className="row border col-12 p-1 justify-content-around m-1">
                  {currentChat.Users.map((item, index) => {
                    const user = contactList.find(contact => contact._id === item)
                    if(user){
                      return (
                        <div className="badge badge-secondary mt-1 mr-2" key={index}>{user?.firstname} {user?.lastname}</div>
                      )
                    }
                    return <></>
                  })}
                </div>
              </div>
            )}
          </div>

          <ul className="nav flex-nowrap" />
        </div>
        <div className="chat-content p-2">
          <div id="chatBoxMessages" className="container" style={{ paddingBottom: 20 }}>
            {currentChat &&
              profile &&
              currentChat.messages.map((message, index) => {
                return message.UserId === profile._id ? (
                  <OwnMessage
                    key={`${index}-${message.UserId}`}
                    message={message}
                    dispatch={dispatch}
                    chatId={currentChat._id}
                  />
                ) : (
                  <OtherMessage
                    key={`${index}-${message.UserId}`}
                    message={message}
                    dispatch={dispatch}
                    chatId={currentChat._id}
                  />
                );
              })}
            {/* <OwnMessage
              message={{
                message: `155b9f3b{  "playerUrl":"https://b60a8d2a-65fb-4c09-96eb-f2a9b762e039.relay.vmsproxy.com/hls/744758c3-86f5-4bda-368d-82dd7c32a31b.m3u8?pos=2022-09-22T09:58:28:000&lo&auth=c3VwcG9ydEBleWVzb24uYml6OmZrdlltQjRJbFJ4Um5KVDhTNm1OUnkyTHl4L3hRVT1oemZlY2s6ZWFlMWZkY2FmMzVlYTRhYmE3ODBjNmVlNGNmMzEwYWE="
                ,"message":"Teste de video DW"
                ,"vmsUrl":"dw-vms://dwvms.com/client/b60a8d2a-65fb-4c09-96eb-f2a9b762e039/view?auth=c3VwcG9ydEBleWVzb24uYml6OmZrdlltQjRJbFJ4Um5KVDhTNm1OUnkyTHl4L3hRVT1oemZlY2s6ZWFlMWZkY2FmMzVlYTRhYmE3ODBjNmVlNGNmMzEwYWE=&resources=ed93120e-0f50-3cdf-39c8-dd52a640688c:04762367-751f-2727-25ca-9ae0dc6727de"
                ,"downloadUrl":"teste"
                ,"multipleCams":"dw-vms://dwvms.com/client/b60a8d2a-65fb-4c09-96eb-f2a9b762e039/view?auth=c3VwcG9ydEBleWVzb24uYml6OmZrdlltQjRJbFJ4Um5KVDhTNm1OUnkyTHl4L3hRVT1oemZlY2s6ZWFlMWZkY2FmMzVlYTRhYmE3ODBjNmVlNGNmMzEwYWE=&resources=ed93120e-0f50-3cdf-39c8-dd52a640688c:04762367-751f-2727-25ca-9ae0dc6727de"
                ,"layouts": "dw-vms://dwvms.com/client/b60a8d2a-65fb-4c09-96eb-f2a9b762e039/view?auth=c3VwcG9ydEBleWVzb24uYml6OmZrdlltQjRJbFJ4Um5KVDhTNm1OUnkyTHl4L3hRVT1oemZlY2s6ZWFlMWZkY2FmMzVlYTRhYmE3ODBjNmVlNGNmMzEwYWE=&resources=ed93120e-0f50-3cdf-39c8-dd52a640688c:04762367-751f-2727-25ca-9ae0dc6727de"
                }155b9f3b`,
              }}
              dispatch={dispatch}
              chatId={currentChat._id}
            /> */}
          </div>

          <div
            className="chat-finished"
            ref={messagesEndRef}
          />
        </div>
        <div className="chat-footer">
          <div className="attachment">
            <div className="dropdown">
              <button
                type="button"
                onClick={handleUploadClick}
                aria-haspopup="true"
                aria-expanded="false"
                className="btn btn-secondary btn-icon btn-minimal btn-sm uploadChatButtonContainer"
              >
                <svg
                  className="hw-20 uploadChatButton"
                  fill="none"
                  color="#1a70d1"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
            </div>
          </div>

          <textarea
            className="form-control emojionearea-form-control"
            id="messageInput"
            rows="1"
            placeholder="Type your message here..."
            onChange={handleInput}
            value={inputMessage}
          />
          <div
            id="sendmyMessage"
            className="btn btn-primary btn-icon send-icon rounded-circle text-light mb-1"
            onClick={() => {
              handleSendMessage();
            }}
          >
            <svg
              className="hw-24"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

const SingleHeader = ({ name }) => {
  const { styles, initials } = getAvatar(48, 48, name);
  const styleAvata = {...styles,  height: '3.2rem',  width: '3.2rem', borderRadius: '50%', padding: '3px'}

  return (
    <div className="avatar-initials" style={styleAvata}>
      {initials}
    </div>
  );
};

const GroupHeader = () => {
  return (
    <span>
      <svg
        className="hw-24"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
        />
      </svg>
    </span>
  );
};

const OwnMessage = ({ message, dispatch, chatId }) => {
  const { contactList } = useSelector((state) => state.main);
  const { currentChat } = useSelector((state) => state.chats);

  const fullName = contactLookup(message.UserId, contactList);

  const handleImgClick = (src) => {
    dispatch(setShowDocument({ visible: true, src }));
  };

  const formattedDate = moment(message.send).format("lll");
  const { styles, initials } = getAvatar(36, 36, fullName);

  function renderCheck() {
    let icon = CheckIcon
    
    if(currentChat.Group && message.ReadyBy.length === currentChat.Users.length) {
      icon = CheckOkIcon
    }
    if(!currentChat.Group && message.ReadyBy.length > 1) {
      icon = CheckOkIcon
    }
    return (
        <img src={icon} width={20} />
    )
  }

  return (
    <div className="message self">
      <div className="message-wrapper">
        {parseMessage(message, false, { document: handleImgClick }, chatId)}
      </div>
      <div className="message-options">
        <div style={styles}>
            {fullName}
        </div>

        <span className="message-date">{formattedDate}</span>
        {renderCheck()}
      </div>
    </div>
  );
};

const OtherMessage = ({ message, dispatch, chatId }) => {
  const { contactList } = useSelector((state) => state.main);
  const fullName = contactLookup(message.UserId, contactList);
  const formattedDate = moment(message.send).format("lll");
  const { styles, initials } = getAvatar(36, 36, fullName);

  const handleImgClick = (src) => {
    dispatch(setShowDocument({ visible: true, src }));
  };

  if (message.UserId === "60913498a923d230ac31b616") {
    return (
      <div className="message">
        <div className="message-wrapper">
         <div className="message-content">
            <span>
              {parseMessageText(message)}
            </span>
          </div>
        </div>
        <div className="message-options">
          <div style={styles}>
            Eyeson Bot
          </div>
          <span className="message-date">{formattedDate}</span>
        </div>
        <div className="message-wrapper">
          {parseMessage(message, true, { document: handleImgClick }, chatId)}
        </div>
      </div>
    );
  } else {
    return (
      <div className="message">
        <div className="message-wrapper">
          {parseMessage(message, false, { document: handleImgClick }, chatId)}
        </div>
        <div className="message-options">
          <div style={styles}>
            {fullName ? fullName : 'User unavailable'}
          </div>

          <span className="message-date">{formattedDate}</span>
        </div>
      </div>
    );
  }
};


function parseMessageText(message) {
  try {
    if (message?.message.type == "buttonVMS") {
      return message.message || '';
    }
    
    if (message?.message.type == "buttonEWAVE") {
      return message?.message || '';
    }

    if(message?.message) return message.message

    return message
  } catch (error) {
    return ''
  }
}


function parseMessage(message, special, callbacks, chatId) {
  try {
    if(message?.message?.shouldPlay && !message?.message?.type) {
      return ( 
        <div className="message-content">
          <span>{message?.message?.message}</span>
        </div>
      )
    }
    if (!message.message)
      return (
        <div className="message-content">
          <span></span>
        </div>
      );
    if (message.message.type == "buttonImage") {
      return (
        <img
          src={message.message.Message}
          className="documentInChat"
          onClick={() => {
            callbacks.document(message.message.Message);
          }}
        />
      );
    }

    if (message.message.type == "buttonVMS") {
      return <DwVmsMessage data={message.message} />;
    }

    if (message.message.type == "buttonEWAVE") {
      return <CbMessageComponent data={message.message} chatId={chatId} />;
    }

    if (special)
      return (
        <div className="message-content bg-danger">
          <span style={{ color: "#FFFF" }}>{message?.message ? message.message : message}</span>
        </div>
      );
    return (
      <div className="message-content">
        <span>{message?.message?.message ? message?.message?.message : message?.message}</span>
      </div>
    );
  } catch (error) {
    console.log(error)
  }
}
