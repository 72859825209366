import { put, take, select, all } from "redux-saga/effects";
import { loadInvestigations } from "../../services/userServices";
import { loadInvestigationTransaction } from "../../services/userServices/investigations/load.transaction";
import {
  setInvestigationList,
  setInvestigationTransactionInfo,
} from "../reducers/investigations";

export function* getInvestigationsSaga() {
  const { profile } = yield select((state) => state.main);

  const investigationsList = yield loadInvestigations(
    profile.company,
    profile._id
  );

  yield put(setInvestigationList(investigationsList));
}

export function* getInvestigationTransactionsSaga() {
  const { currentInvestigation } = yield select(
    (state) => state.investigations
  );
  if (!currentInvestigation || !currentInvestigation.transactions)
    return console.log("No current investigation");
  const transactionList = yield all(
    currentInvestigation.transactions.map(async (transaction) => {
      const transactionInfo = await loadInvestigationTransaction(transaction);
      return transactionInfo[0];
    })
  );

  yield put(setInvestigationTransactionInfo(transactionList));
}
