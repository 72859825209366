import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  width: 100%;
  justify-content: center;

  @media (max-width: 1000px) {
    overflow-y: initial;
    flex-direction: column;
  }
`;

export const ABCColumnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  align-content: baseline;
  max-width: 310px;

  @media (max-width: 1000px) {
    flex-direction: row;
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
  }
`;

export const TopButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  margin: 10px 0px 20px 0px;
  
  @media (min-width: 1000px) {
    min-height: 100px;
  }

  @media (max-width: 1000px) {
    overflow-x: initial;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const ActionButton = styled.button`
  background-color: ${(props) => props.color};
  height: 100px;
  align-self: center;
  border-radius: 8px;

  width: 7rem;
  max-width: 150px;
  @media (max-width: 900px) {
    padding: 0px !important;
  }

  @media (max-width: 750px) {
    height: 70px;
    width: 6rem;
  }

  @media (max-width: 400px) {
    height: 50px;
  }
`;

export const ActionButtonText = styled.p`
  margin-bottom: 0px !important;
  @media (max-width: 750px) {
    font-size: 11px;
  }
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 32px 0;

  h3 {
    margin: 0;
  }
`;