import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as AlertSvg } from "../../../../assets/media/newicons/bell.svg";
import { headerStyle, ListIconDiv } from "./styles";

export const AlertButton = (props) => {
  return (
    <li
      className={props.active ? "contacts-item active" : "contacts-item"}
      data-sort=""
      id={props._id}
    >
      <Link
        to={"/Home/Alerts/" + props._id}
        className="contacts-link"
        onClick={() => {
          props.handleAlertClick();
        }}
      >
        <div className="avatar">
          <div className="avatar-initials" style={headerStyle}>
            <ListIconDiv>
              <AlertSvg />
            </ListIconDiv>
          </div>
        </div>
        <div className="contacts-content">
          <div className="contacts-info">
            <h6 className="chat-name">{props.title}</h6>
          </div>
          <div className="contacts-texts">
            <p className="text-truncate">{props.status}</p>
          </div>
          <div className="contacts-texts">
            <p className="chat-time">{props.description}</p>
          </div>
        </div>
      </Link>
    </li>
  );
};
