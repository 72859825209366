import { put, select, take } from "redux-saga/effects";
import { channel } from "redux-saga";
import {
  setSocket,
  setOnlineList,
  setRoomList,
  setCurrentChat,
  receiveRoomList,
  receiveRoom,
  setParsedRooms,
  loadChat,
  setUnreadCount,
  receiveMessage,
  newRoom,
  addNotification,
  updateUnreadMessages,
  updateParsedRoom,
  setInternetStatus,
  reconnectToSocket,
  changeChatButtonPos,
  setUploadLoading,
  setUploadModalVisible,
  setUploadFile,
} from "../reducers/chats";
import { roomGroup, roomSingle, sortRoom } from "../../utils/roomsUtils";
import io from "socket.io-client";
import { audios } from "../../utils/audioMapping";
import {
  setActionButtonClicked,
  setChatBarVisible,
  setLogoutModal,
} from "../reducers/main";
import moment from "moment";
import { UploadFile } from "../../services/chat";
import { getLastMessageFormat } from "../../utils/formatingUtils";

// const isElectron = navigator.userAgent === 'Electron'

const socketEventChannel = channel();

const handleElectronMessageEvent = (message) => {
  if (!message) return;
  const parsedMessage = parseSpecialMessage(message);
};


const parseSpecialMessage = (message) => {
  return message.message || "New message";
};

export function* connectToSocketSaga(action) {
  const { profile, idToken } = yield select((state) => state.main);
  const { currentChat } = yield select((state) => state.chats);
  const socket = io.connect(process.env.REACT_APP_SOCKETURL, {
    query: `UserId=${profile._id}&CompanyId=${profile.company}&token=${idToken}`,
  });
  // socket.on("error", (data) => console.log("data", data));
  socket.on("connect", () => {
    socketEventChannel.put(setInternetStatus("online"));
    socket.emit("join", { UserId: profile._id, CompanyId: profile.company });
    // console.log("se conectou no servidor");
  });

  socket.on("online_list", (data) => {
    // console.log("recebendo lista online");
    socketEventChannel.put(setOnlineList(data));
    socket.emit("list_rooms", {
      UserId: profile._id,
      CompanyId: profile.company,
    });
  });

  socket.on("receive_rooms", (data) => {
    socketEventChannel.put(receiveRoomList(data));
  });

  socket.on("receive_room", (data) => {
    // console.log("RECEIVE ROOM SOCKET EVENT", data);
    // Refatorar, criar função a parte para nao repetir codigo
    if(data[0] && data[0].messages && data[0]?.Owner != profile._id){
      const countMessage = (data[0].messages?.length > 0 ? data[0].messages?.length : 1) - 1
      const latestMessage = data[0].messages[countMessage]?.message;

      if (latestMessage?.owner) {
        const audioSelected = audios[latestMessage?.shouldPlay];
        if (audioSelected) {
          const audio = new Audio(audioSelected).play();
          if (audio !== undefined) {
            audio.then(function() {
            }).catch(function(error) {
            });
          }
        }
      }
    }

    socketEventChannel.put(receiveRoom(data[0]));
  });

  socket.on(profile._id, (data) => {
    if (currentChat !== data.ChatId) {
      console.log(JSON.stringify(data));
    } else {
      // loadChat(data.ChatId)
    }

    // console.log("mensagem recebida");
    // console.log(data);
  });

  socket.on("new_rom", (data) => {
    
    // console.log("NEW ROOM SOCKET EVENT", data, profile);
    // Refatorar, criar função a parte para nao repetir codigo
    const latestMessage = data.currentMessage;
    if (latestMessage && data.Owner != profile._id) {
      const audioSelected = audios[latestMessage.shouldPlay];
      if (audioSelected) {
        const audio = new Audio(audioSelected).play();
        if (audio !== undefined) {
          audio.then(function() {
          }).catch(function(error) {
          });
        }
      }
    }
    socketEventChannel.put(newRoom({ data, history: action.payload }));
  });

  socket.on("new_message", (data) => {
    console.log(JSON.stringify(data, null, 4));
    if(data?.message){
      socketEventChannel.put(receiveMessage(data));
    }
  });
  socket.on("connect_error", () => {
    socketEventChannel.put(setInternetStatus("offline"));
    setTimeout(() => {
      // console.log("connection_error");
      socketEventChannel.put(reconnectToSocket());
    }, 1000);
  });

  socket.on("disconnect", () => {
    socketEventChannel.put(setInternetStatus("offline"));
    setTimeout(() => {
      // console.log("disconnected");
      socketEventChannel.put(reconnectToSocket());
    }, 1000);
  });
  yield put(setSocket(socket));
}

export function* reconnectToSocketSaga(action) {
  const { profile } = yield select((state) => state.main);
  const { socket } = yield select((state) => state.chats);
  if (!socket) {
    return;
  }
  if (!socket.connected && profile) {
    socket.connect();
  }
}

export function* receiveRoomListSaga(data) {
  const { profile, contactList } = yield select((state) => state.main);
  const { currentChat } = yield select((state) => state.chats);
  if (!profile) return // console.log("no profile");

  const rooms = data.payload;
  const parsedRooms = [];
  let unreadCount = 0;
  const currentChatId = currentChat ? currentChat._id : "";
  rooms.forEach((room) => {
    const newRoom = room.Group
      ? roomGroup(room, profile._id)
      : roomSingle(room, profile._id, contactList);
    if (newRoom.messageNotRead !== "" && room._id !== currentChatId) {
      unreadCount += newRoom.messageNotRead;
    }
    parsedRooms.push(newRoom);
  });
  yield put(setUnreadCount(unreadCount));
  const sortedList = sortRoom(parsedRooms);
  // if (!currentChat && roomList.length === 0) {
  //   yield put(loadChat(sortedList[0]))
  // }
  yield put(setRoomList(rooms));
  yield put(setParsedRooms(sortedList));
}

export function* receiveRoomSaga(data) {
  const { profile, contactList, actionButtonClicked } = yield select(
    (state) => state.main
  );
  if (!profile) return;
  const { currentProfile } = yield select((state) => state.chats);
  const room = data.payload;
  if (actionButtonClicked && room.ButtonId === actionButtonClicked._id) {
    yield put(setChatBarVisible(true));
    yield put(setActionButtonClicked(false));
  }
  const parsedRoom = room.Group
    ? roomGroup(room, profile._id)
    : roomSingle(room, profile._id, contactList);
  const Name = parsedRoom.Name
    ? parsedRoom.Name
    : currentProfile
    ? `${currentProfile.firstname} ${currentProfile.lastname}`
    : "Name Unknown";
  const messages = parsedRoom.messages ? parsedRoom.messages : [];
  yield put(
    setCurrentChat({
      ...parsedRoom,
      Name,
      messages,
      roomName: parsedRoom.roomName || Name,
    })
  );
  yield put(updateUnreadMessages());
}

export function* loadChatSaga(data) {
  const { socket } = yield select((state) => state.chats);
  const { profile } = yield select((state) => state.main);
  if (!profile) return;

  const room = data.payload;

  socket.emit("list_room", {
    UserId: profile._id,
    CompanyId: profile.company,
    ChatId: room._id,
  });
}

export function* updateUnreadMessagesSaga() {
  const { parsedRooms, currentChat, socket } = yield select(
    (state) => state.chats
  );
  const { profile } = yield select((state) => state.main);
  if (!profile) return;

  const thisRoom = parsedRooms.find((room) => room._id === currentChat._id);
  yield put(updateParsedRoom({ ...thisRoom, messageNotRead: "" }));

  socket.emit("read_message", {
    UserId: profile._id,
    ChatId: thisRoom._id,
  });

  socket.emit("list_rooms", {
    UserId: profile._id,
    CompanyId: profile.company,
  });
}

export function* sendMessageSaga(data) {
  const message = data.payload;
  const { socket, currentChat } = yield select((state) => state.chats);
  const { profile } = yield select((state) => state.main);
  if (!profile) return;

  socket.emit("new_message", {
    CompanyId: profile.company,
    UserId: profile._id,
    message: message,
    ChatId: currentChat._id,
  });
  // yield put(loadChat(currentChat))
}

export function* receiveMessageSaga(data) {
  const { currentChat, unreadCount, socket } = yield select(
    (state) => state.chats
  );
  const { profile, contactList } = yield select((state) => state.main);
  if (!profile) return;
  const message = data.payload;
  const formattedDate = moment(message.send).format("lll");
  yield put(
    changeChatButtonPos({
      chatId: message.ChatId,
      lastMessage: getLastMessageFormat(message),
      formattedDate,
    })
  );
  if (message.SenderId !== profile._id) {
    // console.log("recebi uma mensagem");
    const userRecibe = contactList.find(
      (contact) => contact._id === message.SenderId
    );
    console.log(userRecibe)
    console.log(JSON.stringify({...data, notification: true, name: userRecibe.firstname}));
    handleElectronMessageEvent(message.message);
  }
  let isCurrentChat = false;
  if (!currentChat || currentChat._id !== message.ChatId) {
    yield put(setUnreadCount(unreadCount + 1));
    socket.emit("list_rooms", {
      UserId: profile._id,
      CompanyId: profile.company,
    });
  } else {
    isCurrentChat = true;
    yield put(loadChat(currentChat));
  }
  const msgContact = contactList.find(
    (contact) => contact._id === message.SenderId
  );
  const msgTitle = msgContact
    ? `${msgContact.firstname} ${msgContact.lastname}`
    : "Nova mensagem!";
  const msgId = new Date().toString();
  yield put(
    addNotification({
      id: msgId,
      title: msgTitle || "Name Unknown",
      message: message.message,
      currentChat: isCurrentChat,
      type: "pushNotification",
    })
  );
}

export function* changeChatButtonPosSaga(data) {
  const { parsedRooms } = yield select((state) => state.chats);
  const { chatId, lastMessage, formattedDate } = data.payload;
  const newParsedRooms = parsedRooms.filter((room) => room._id !== chatId);
  let oldRoom = parsedRooms.find((room) => room._id === chatId);
  oldRoom = { ...oldRoom, sortNum: Date.now(), lastMessage, formattedDate };
  yield put(setParsedRooms([...newParsedRooms, oldRoom]));
}

export function* createNewRoomSaga(data) {
  const id = data.payload;
  const { profile } = yield select((state) => state.main);
  if (!profile) return;

  const { socket } = yield select((state) => state.chats);
  yield put(setChatBarVisible(true));
  socket.emit("create_room", {
    CompanyId: profile.company,
    Options: false,
    Users: [profile._id, id],
  });
}

export function* newRoomSaga(action) {
  const { roomList, currentProfile, parsedRooms } = yield select(
    (state) => state.chats
  );
  const { profile, contactList } = yield select((state) => state.main);
  if (!profile) return;

  const newRoom = action.payload.data;
  newRoom.Name = newRoom.Name
    ? newRoom.Name
    : currentProfile
    ? `${currentProfile.firstname} ${currentProfile.lastname}`
    : "Name Unknown";
  newRoom.messages = newRoom.messages ? newRoom.messages : [];
  const sameRoom = roomList.find((room) => room._id === newRoom._id);
  // const newRoomList = [...roomList]
  let newParsedRoom;
  if (!sameRoom) {
    if (newRoom.Group === true) {
      newParsedRoom = roomGroup(newRoom, profile._id);
    } else {
      newParsedRoom = roomSingle(newRoom, profile._id, contactList);
    }
    // newRoomList.push(newParsedRoom)
    // yield put(receiveRoomList(newRoomList))
    yield put(setParsedRooms([...parsedRooms, newParsedRoom]));
    yield put(loadChat(newParsedRoom));
  } else {
    yield put(loadChat(sameRoom));
  }
  yield put(setChatBarVisible(true));

  action.payload.history.push("/Home/Chats");
}

export function* watchSocketEventChannel() {
  while (true) {
    const action = yield take(socketEventChannel);
    yield put(action);
  }
}

export function* sendFileSaga({ payload }) {
  const { socket, currentChat } = yield select((state) => state.chats);
  const { profile } = yield select((state) => state.main);
  //try upload
  try {
    yield put(setUploadLoading(true));
    const formData = new FormData();
    console.log(payload)
    formData.append("upload", payload);
    const response = yield UploadFile(formData);
    if (response.error) {
      throw new Error("Erro ao realizar upload");
    }

    // console.log(
    //   "Documento enviado para baixar clique aqui: ",
    //   response.data.Location
    // );
    const message = getHtmlToUpload(response.data);
    //enviar no chat
    socket.emit("new_message", {
      CompanyId: profile.company,
      UserId: profile._id,
      message: message,
      ChatId: currentChat._id,
    });

    // fechar modal
    yield put(setUploadLoading(false));
    yield put(setUploadFile(null));
    yield put(setUploadModalVisible(false));
  } catch (error) {
    yield put(setUploadLoading(false));
    yield put(
      setLogoutModal({
        isVisible: true,
        title: "Failed to upload file, please try again!",
        twoButtons: false,
        firstShouldClose: true,
        buttonTitle: "Okay",
        secondButtonTitle: "",
      })
    );
    // console.log(error);
  }
}

function getHtmlToUpload(img) {
  return {
    type: 'buttonImage',
    Message: img.Location
  };
}
