import api from "../../api";
export const getAlertInfo = async (store, transId) => {
  try {
    const response = await api.post("/emessages/detail/", {
        trans_id: transId,
        store
    });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch (err) {
    console.log(err);
    window.alert("Failed to retrieve alerts");
    return false;
  }
};
