import api from '../apiSocket'

export const UploadFile = async (file) => {
  try {
    const result = await api.post('/upload', file)
    return result;
  } catch(err) {
    // window.alert('Failed to upload file, please try again!')
    return {error: err}
  }
}