import React, { useReducer, useEffect, useMemo } from "react";
import { TagInput, DropzoneForm } from "../../../../components/Home";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CloseSvg } from "../../../../assets/media/heroicons/solid/x.svg";

import {
  Container,
  Form,
  FadeBackground,
  EndContainer,
  MCInput,
  MCTitle,
  MContentContainer,
  MiddleContainer,
  TopContainer,
  MCSelect,
  MCOption,
  MCTextArea,
  HorizontalLine,
  EButton,
  CloseButtonContainer,
  CloseButton,
  CloseButtonImg,
} from "./styles";

import DatePicker from "react-datepicker";
import { setTaskFormVisible, getTasks } from "../../../../store/reducers/tasks";
import { createTask } from "../../../../services/userServices/tasks";
import { validateTaskForm } from "./utils";
import { toBase64 } from "../../../../utils/formatingUtils";

const initialFormState = {
  dateTime: "",
  isoDateTime: "",
  priority: "Highest",
  taskGroup: [],
  subject: "",
  description: "",
  images: [],
};

function formReducer(state, action) {
  return { ...state, [action.id]: action.value };
}

export const TaskForm = () => {
  const dispatch = useDispatch();
  const { contactList, profile } = useSelector((state) => state.main);
  const formattedContactList = useMemo(() => {
    const result = contactList.map((value, index) => {
      const fullName = `${value.firstname} ${value.lastname}`;
      return { id: fullName, text: fullName, userId: value._id };
    });
    return result;
  }, [contactList]);

  const [formState, formDispatch] = useReducer(formReducer, initialFormState);

  const handleFilesChange = async (data) => {
    const files = data;
    let error = false;
    const base64Files = await Promise.all(
      files.map(async (file) => {
        try {
          return await toBase64(file);
        } catch (e) {
          return (error = true);
        }
      })
    );
    if (error) return window.alert("Failed to convert files to base64");
    formDispatch({ id: "images", value: base64Files });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateTaskForm(formState);
    if (errors.length > 0)
      return window.alert(`The fields ${errors.join(", ")} are required!`);
    const result = await createTask(
      profile.company,
      formState.description,
      formState.images,
      profile._id,
      formState.priority,
      "Started",
      formState.subject,
      formState.taskGroup
    );
    if (!result) window.alert("Failed to create task");
    dispatch(getTasks());
    dispatch(setTaskFormVisible(false));
  };

  const handleClose = () => {
    dispatch(setTaskFormVisible(false));
  };

  return (
    <FadeBackground
      id="taskFormBackground"
      onClick={(e) => {
        if (e.target.id !== "taskFormBackground") return;
        handleClose();
      }}
    >
      <Container>
        <CloseButtonContainer onClick={handleClose}>
          <CloseButton>
            <CloseSvg />
          </CloseButton>
        </CloseButtonContainer>
        <Form onSubmit={handleSubmit}>
          <TopContainer>
            <DropzoneForm
              onChange={(files) => {
                handleFilesChange(files);
              }}
            />
          </TopContainer>
          <MiddleContainer>
            <MContentContainer>
              <MCTitle>Start Date</MCTitle>
              <DatePicker
                dateFormat={"MM/dd/yyyy HH:mm:ss"}
                selected={formState.dateTime}
                placeholderText={"mm/dd/yyyy 00:00:00"}
                className="DatePicker"
                showTimeSelect
                onChange={(value) => {
                  formDispatch({ id: "dateTime", value: value });
                  formDispatch({
                    id: "isoDateTime",
                    value: value.toISOString(),
                  });
                }}
              />
            </MContentContainer>
            <MContentContainer>
              <MCTitle>Priority</MCTitle>
              <MCSelect
                onChange={(event) =>
                  formDispatch({ id: "priority", value: event.target.value })
                }
              >
                <MCOption value="Highest">Highest priority</MCOption>
                <MCOption value="High">High priority</MCOption>
                <MCOption value="Normal">Normal priority</MCOption>
                <MCOption value="Low">Low priority</MCOption>
              </MCSelect>
            </MContentContainer>
            <MContentContainer>
              <MCTitle>Task Group:</MCTitle>
              <TagInput
                onChange={(values) =>
                  formDispatch({
                    id: "taskGroup",
                    value: values.map((value) => value.userId),
                  })
                }
                suggestions={formattedContactList}
                allowOnlyFromSuggestion
              />
            </MContentContainer>
            <MContentContainer>
              <MCTitle>Title</MCTitle>
              <MCInput
                type="string"
                onChange={(event) =>
                  formDispatch({ id: "subject", value: event.target.value })
                }
                placeholder="Subject"
              />
            </MContentContainer>
            <MContentContainer maxWidth>
              <MCTitle>Notes</MCTitle>
              <MCTextArea
                type="string"
                onChange={(event) =>
                  formDispatch({ id: "description", value: event.target.value })
                }
                placeholder="Description"
              />
            </MContentContainer>
          </MiddleContainer>
          <EndContainer>
            <HorizontalLine />
            <EButton type="submit" value="Create Task" />
          </EndContainer>
        </Form>
      </Container>
    </FadeBackground>
  );
};
