import React, { useEffect, useState } from "react";
import { AlertButton } from "../Alert";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentAlert } from "../../../../store/reducers/alerts";

export const AlertList = ({ onAlertClick }) => {
  const alerts = useSelector((state) => state.alerts);
  const dispatch = useDispatch();
  const changeToAlert = (alert) => {
    dispatch(setCurrentAlert(alert));
    onAlertClick();
  };

  return (
    <AlertListContainer>
      <div className="sidebar-header sticky-top p-2">
        <div
          className="d-flex justify-content-between align-items-center marginVertical10"
          style={{ margin: 10 }}
        >
          <h5 className="font-weight-semibold mb-0">Alerts</h5>
        </div>
      </div>
      <ul className="contacts-list" id="chatContactTab">
        {alerts.alertList &&
          alerts.alertList.map((alert, index) => {
            return (
              <AlertButton
                key={"alert" + index}
                {...alert}
                active={
                  alerts.currentAlert
                    ? alerts.currentAlert._id === alert._id
                    : false
                }
                handleAlertClick={() => {
                  changeToAlert(alert);
                }}
              />
            );
          })}
      </ul>
    </AlertListContainer>
  );
};

const AlertListContainer = (props) => {
  return (
    <aside className="sidebar">
      <div className="tab-content">
        <div className="tab-pane active" id="alerts-content">
          <div className="d-flex flex-column h-100">
            <div className="hide-scrollbar h-100" id="chatContactsList">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};
