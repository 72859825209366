import React, { useReducer } from "react";

import {
  Container,
  FadeBackground,
  CloseButton,
  CloseButtonContainer,
  MCInput,
  MCTitle,
  MContentContainer,
  MiddleContainer,
  Title,
  TopContainer,
  Form,
  EndContainer,
  HorizontalLine,
  EButton,
  MCOption,
  MCSelect,
  MCTextArea,
} from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentReport } from "../../../../../../store/reducers/reports";
import { ReactComponent as CloseSvg } from "../../../../../../assets/media/heroicons/solid/x.svg";
import { current, isAllOf } from "@reduxjs/toolkit";
import {
  checkDuplicates,
  updateReport,
} from "../../../../../../services/userServices/reports";

const initialFormState = {
  customerFirstName: "current",
  customerCode: "current",
  plate: "current",
  type: "current",
  category: "current",
  comments: "current",
};

function formReducer(state, action) {
  return { ...state, [action.id]: action.value };
}

export const ReportModal = () => {
  const { currentReport } = useSelector((state) => state.reports);
  const [formState, formDispatch] = useReducer(formReducer, initialFormState);

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setCurrentReport(null));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await updateReport({
      trans_id: currentReport.trans_id,
      company: currentReport.company,
      comments:
        formState.comments === "current"
          ? currentReport.comments
          : formState.comments,
      customer_first_name:
        formState.customerFirstName === "current"
          ? ""
          : formState.customerFirstName,
      license_plate:
        formState.plate === "current"
          ? currentReport.description
          : formState.plate,
      person_category:
        formState.category === "current"
          ? currentReport.person_category
          : formState.category,
      customer_number:
        formState.customerCode === "current" ? "" : formState.customerCode,
      person_type:
        formState.type === "current"
          ? currentReport.person_type
          : formState.type,
    });
    if (!result) return window.alert("Failed to update report");
    window.alert("Report updated sucessfully");
  };

  const handleCheckForDuplicates = async () => {
    const result = await checkDuplicates({
      company: currentReport.company,
      comments:
        formState.comments === "current"
          ? currentReport.comments
          : formState.comments,
      person_first_name:
        formState.customerFirstName === "current"
          ? ""
          : formState.customerFirstName,
      license_plate:
        formState.plate === "current"
          ? currentReport.description
          : formState.plate,
      person_category:
        formState.category === "current"
          ? currentReport.person_category
          : formState.category,
      person_code:
        formState.customerCode === "current" ? "" : formState.customerCode,
      person_type:
        formState.type === "current"
          ? currentReport.person_type
          : formState.type,
    });
    if (result.length > 0) return window.alert("Is duplicated");
    window.alert("It's not duplicated");
  };
  return (
    <FadeBackground
      id="reportModalBackground"
      onClick={(e) => {
        if (e.target.id !== "reportModalBackground") return;
        handleClose();
      }}
    >
      <Container>
        <CloseButtonContainer onClick={handleClose}>
          <CloseButton>
            <CloseSvg />
          </CloseButton>
        </CloseButtonContainer>
        <Form onSubmit={handleSubmit}>
          <TopContainer>
            <Title>Update Informations</Title>
          </TopContainer>
          <MiddleContainer>
            <MContentContainer>
              <MCTitle>Customer First Name:</MCTitle>
              <MCInput
                type="string"
                onChange={(event) =>
                  formDispatch({
                    id: "customerFirstName",
                    value: event.target.value,
                  })
                }
              />
            </MContentContainer>
            <MContentContainer>
              <MCTitle>Customer #:</MCTitle>
              <MCInput
                type="string"
                onChange={(event) =>
                  formDispatch({
                    id: "customerCode",
                    value: event.target.value,
                  })
                }
              />
            </MContentContainer>
            <MContentContainer>
              <MCTitle>Plate</MCTitle>
              <MCInput
                type="string"
                onChange={(event) =>
                  formDispatch({ id: "plate", value: event.target.value })
                }
                defaultValue={currentReport.description}
              />
            </MContentContainer>
            <MContentContainer>
              <MCTitle>Type</MCTitle>
              <MCSelect
                onChange={(event) =>
                  formDispatch({ id: "type", value: event.target.value })
                }
              >
                <MCOption value="current">{currentReport.person_type}</MCOption>
                {currentReport.person_type !== "CUSTOMER" && (
                  <MCOption value="customer">CUSTOMER</MCOption>
                )}
              </MCSelect>
            </MContentContainer>
            <MContentContainer>
              <MCTitle>Category</MCTitle>
              <MCSelect
                onChange={(event) =>
                  formDispatch({ id: "category", value: event.target.value })
                }
              >
                <MCOption value="current">
                  {currentReport.person_category}
                </MCOption>
                {currentReport.person_category !== "NEW" && (
                  <MCOption value="new">NEW</MCOption>
                )}
              </MCSelect>
            </MContentContainer>
            <MContentContainer>
              <MCTitle>Comments</MCTitle>
              <MCTextArea
                type="string"
                onChange={(event) =>
                  formDispatch({ id: "comments", value: event.target.value })
                }
              />
            </MContentContainer>
          </MiddleContainer>
          <HorizontalLine />
          <EndContainer>
            <EButton type="submit" value="Update Info" />
            <EButton
              value="Check for duplicates"
              onClick={handleCheckForDuplicates}
            />
          </EndContainer>
        </Form>
      </Container>
    </FadeBackground>
  );
};
