import api from "../../api";
export const updateReport = async ({
  trans_id,
  customer_first_name,
  customer_number,
  comments,
  person_type,
  person_category,
  company,
  license_plate,
}) => {
  try {
    const updateResponse = await api.put(`/transactions/${trans_id}`, {
      customer_first_name,
      customer_number,
      description,
      comments,
      person_type,
      person_category,
    });
    if (response.status !== 200) {
      console.log(response.data);
      window.alert("Failed to update report");
      return false;
    }
    const response = await api.post("/classifications", {
      company,
      person_first_name: customer_first_name,
      person_code: customer_number,
      license_plate,
      comments,
      person_type,
      person_category,
    });
    if (response.status == 200) {
      return response.data;
    }
    return false;
  } catch (err) {
    console.log(err);
    window.alert("Failed to update report");
    return false;
  }
};
