import MainRouter from './pages'
import './assets/css/app.min.css'
import './assets/css/eyeson.css'
import "react-datepicker/dist/react-datepicker.css";


function App (props) {
  return (
    <MainRouter />
  )
}

export default App
