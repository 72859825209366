import moment from 'moment'
import {
  headerColors,
  ChatFilterOptions,
  ProfileType,
  UserStatus
} from '../configs/config'

export function roomSingle (room, profileId, contactList) {
  const roomName = contactLookup(
    room.Users.filter(contactFilter(profileId)),
    contactList
  )
  let sortNum = 0
  let formattedDate = ' '
  let lastMessage = ' '
  if (typeof room.messages !== 'undefined' && room.messages.length > 0) {
    sortNum = new Date(room.messages[room.messages.length - 1].send).getTime()
    formattedDate = moment(room.messages[room.messages.length - 1].send).format(
      'lll'
    )
    lastMessage = room.messages[room.messages.length - 1].message
  }
  const messageNotRead = getMessageNotRead(room, profileId)
  return {
    ...room,
    roomName,
    lastMessage,
    formattedDate,
    messageNotRead,
    sortNum,
    filter: ChatFilterOptions.Friends,
    to: '/Home/Chats/' + room._id,
    profile: '',
    profileType: ProfileType.Image,
    status: UserStatus.Online
  }
}

export function roomGroup (room, profileId) {
  let sortNum = 0
  let formattedDate = ' '
  let lastMessage = ' '
  if (typeof room.messages !== 'undefined' && room.messages.length > 0) {
    formattedDate = moment(room.messages[room.messages.length - 1].send).format(
      'lll'
    )
    lastMessage = room.messages[room.messages.length - 1].message
    sortNum = new Date(room.messages[room.messages.length - 1].send).getTime()
  }
  const messageNotRead = getMessageNotRead(room, profileId)
  return {
    ...room,
    roomName: room.Name,
    lastMessage,
    formattedDate,
    messageNotRead,
    sortNum,
    filter: ChatFilterOptions.Groups,
    to: '/Home/Chats/' + room._id,
    profile: '',
    profileType: ProfileType.Image,
    status: UserStatus.Online
  }
}

export function contactLookup (ids, contactList) {
  let roomName = false
  if (Array.isArray(ids)) {
    contactList.forEach(element => {
      ids.forEach(id => {
        if (element._id === id && roomName === false) {
          roomName = `${element.firstname} ${element.lastname}`
        }
      })
    })
  } else {
    contactList.forEach(element => {
      if (element._id === ids) {
        roomName = `${element.firstname} ${element.lastname}`
      }
    })
  }
  return roomName
}

export function getMessageNotRead (room, profileId) {
  let messageNotRead = 0
  if (typeof room.messages !== 'undefined' && room.messages.length > 0) {
    for (let i = 0; i < room.messages.length; i++) {
      const el = room.messages[i]
      const readCheck = el.read_by.includes(profileId)

      if (!readCheck) messageNotRead = messageNotRead + 1
    }

    if (messageNotRead === 0) {
      messageNotRead = ''
    }
  } else {
    messageNotRead = ''
  }
  return messageNotRead
}

export function contactFilter (profileId) {
  return function (id) {
    if (id !== profileId) {
      return id
    }
  }
}

export function sortRoom (list) {
  return list.sort(sortFunction)
}

function sortFunction (a, b) {
  const aEmpty = typeof a.messages === 'undefined' || a.messages.length === 0
  const bEmpty = typeof b.messages === 'undefined' || b.messages.length === 0
  // const aTime = !aEmpty ? moment(a.formattedDate).utc().format('YYYY-MM-DD HH:mm:ss') : 0
  // const bTime = !bEmpty ? moment(b.formattedDate).utc().format('YYYY-MM-DD HH:mm:ss') : 0
  if (a.sortNum > b.sortNum || (bEmpty && !aEmpty)) {
    return -1
  }
  return 0
}

export function sortContacts (contacts) {
  return contacts.sort(sortContactsFunction)
}

function sortContactsFunction (a, b) {
  if (a.online && !b.online) {
    return -1
  }
}

export function sortActionButtons (actionButtons) {
  return actionButtons.sort(sortActionButtonsFunction)
}

function sortActionButtonsFunction (a, b) {
  if(parseInt(a.bucketPosition) < parseInt(b.bucketPosition)) return -1;
  // if (a.type === 'EYESON360' && b.type !== 'EYESON360') {
  //   return -1
  // }
}

export const getAvatar = (width, height, name) => {
  const initials = name
    ? name
        .split(' ')[0]
        .charAt(0)
        .toUpperCase() +
      name
        .split(' ')[1]
        .charAt(0)
        .toUpperCase()
    : 'Name Unknown'
  const charIndex = initials.charCodeAt(0) - 65
  let colorIndex = charIndex % 19
  colorIndex = colorIndex > 0 ? colorIndex : headerColors.length - 1
  const styles = {
    backgroundColor: headerColors[colorIndex],
    height,
    font: '0.875rem Arial',
    color: '#FFF',
    textAlign: 'center',
    lineHeight: height + 'px',
    borderRadius: '1rem',
    padding: '0 8px'
  }
  return { initials, styles }
}

export const filterChats = (chats, option) => {
  let chatList = chats
  if (option !== ChatFilterOptions.AllChats) {
    chatList = chatList.filter(x => x.filter === option)
  }
  return chatList
}
