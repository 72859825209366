import api from "../../api";

export const callNewRoom = async (buttonId, profileId, token) => {
  try {
    const response = await api.post("buttons/send/" + buttonId, {
      user: profileId,
      token: token,
    });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch (err) {
    console.log(err);
    // window.alert("Failed to create new room");
    return false;
  }
};
