import React, { useEffect } from "react";
import { NavBar } from "../../../components/Home";
import { TaskList } from "./TaskList";
import { Task } from "./Task";

import { useDispatch, useSelector } from "react-redux";
import {
  getTasks,
  getTasksTransactions,
  setCurrentTask,
  setTaskBarVisible,
  setTaskFormVisible,
} from "../../../store/reducers/tasks";
import { TaskForm } from "./TaskForm";

export const Tasks = () => {
  const { actionButtonClicked } = useSelector((state) => state.main);
  const { currentTask, taskList, taskBarVisible, taskFormVisible } =
    useSelector((state) => state.tasks);
  const _persist = useSelector((state) => state._persist);

  const dispatch = useDispatch();
  const handleTaskBackClick = () => {
    dispatch(setTaskBarVisible(false));
    dispatch(setCurrentTask(null));
  };
  useEffect(() => {
    if (
      !currentTask &&
      !actionButtonClicked &&
      window.innerWidth > 1000 &&
      taskList.length > 0
    ) {
      dispatch(setCurrentTask(taskList[0]));
    }
    dispatch(setTaskFormVisible(false));
    dispatch(setTaskBarVisible(false));
  }, []);

  useEffect(() => {
    dispatch(getTasks());
  }, [_persist]);

  useEffect(() => {
    dispatch(setCurrentTask(taskList[0]));
  }, [taskList]);

  // useEffect(() => {
  //   if (currentTask) {
  //     dispatch(getTasksTransactions());
  //   }
  // }, [currentTask]);

  return (
    <>
      {taskFormVisible && <TaskForm />}
      <div className="calls-tab-open h-100">
        <div className="main-layout">
          <NavBar activeMenu="tasks" />
          <TaskList
            onTaskClick={() => {
              dispatch(setTaskBarVisible(true));
            }}
          />
          {currentTask && (
            <main className={taskBarVisible ? "main main-visible" : "main"}>
              <Task
                onBackClick={() => {
                  handleTaskBackClick();
                }}
              />
            </main>
          )}
          <div className="backdrop" />
        </div>
      </div>
    </>
  );
};
