import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  alertList: [],
  currentAlert: null,
  alertBarVisible: false,
  currentAlertDetail: null
};
export const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    setAlertList: (state, action) => {
      state.alertList = action.payload;
    },
    setAlertBarVisible: (state, action) => {
      state.alertBarVisible = action.payload;
    },
    setCurrentAlert: (state, action) => {
      state.currentAlert = action.payload;
    },
    setCurrentAlertDetail: (state, action) => {
      state.currentAlertDetail = action.payload
    },
    getAlerts: (state, action) => {
      return state;
    },
    getAlertInfo: (state, action) => {
      return state;
    },
    updateAlert: (state, action) => {
      return state;
    },
    alertFinishedFetching: (state, action) => {
      return state;
    }
  },
});

export const {
  setAlertList,
  setCurrentAlert,
  setAlertBarVisible,
  getAlerts,
  setCurrentAlertDetail,
  getAlertInfo,
  updateAlert,
  alertFinishedFetching
} = alertsSlice.actions;
export default alertsSlice.reducer;
