import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAvatar } from '../../../../utils/roomsUtils'
import {} from '../../../../store/reducers'
import { ReactComponent as ChatIcon } from '../../../../assets/media/icons/chatIcon.svg'
import { createNewRoom } from '../../../../store/reducers/chats'
import { useHistory } from 'react-router'

export const FriendProfile = ({ onBackClick }) => {
  const { currentProfile } = useSelector(state => state.chats)
  const dispatch = useDispatch()
  const history = useHistory()
  const [profileConfig, setProfileConfig] = useState({
    fullName: '',
    phone: '',
    email: '',
    avatarStyles: {},
    avatarInitials: ''
  })

  useEffect(() => {
    if (currentProfile) {
      const fullName = `${currentProfile.firstname} ${currentProfile.lastname}`
      const { styles, initials } = getAvatar(60, 60, fullName)
      const styleAvata = {...styles,  height: '4.2rem',  width: '4.2rem', borderRadius: '50%', padding: '5px'}

      setProfileConfig({
        fullName,
        avatarStyles: styleAvata,
        avatarInitials: initials,
        phone: currentProfile.phone,
        email: currentProfile.email
      })
    }
  }, [currentProfile])

  const handleNewRoomClick = () => {
    dispatch(createNewRoom(currentProfile._id))
    history.push('/Home/Chats')
  }
  return (
    <div className='friends px-0 py-2 p-xl-3'>
      <div className='container-xl'>
        <div className='row'>
          <div className='col'>
            <div className='card card-body card-bg-1 mb-3'>
              <div className='d-flex flex-column align-items-center'>
                <div className='avatar avatar-lg mb-3'>
                  <div
                    className='avatar-initials'
                    style={profileConfig.avatarStyles}
                  >{profileConfig.avatarInitials}
                  </div>
                </div>

                <div className='d-flex flex-column align-items-center'>
                  <h5 id='contactName' className='mb-1'>
                    {profileConfig.fullName}
                  </h5>
                  <div className='d-flex mt-2' id='newChat'>
                    <a href='#chats-content' onClick={handleNewRoomClick}>
                      <div className='btn btn-primary btn-icon rounded-circle text-light mx-2' href='#chats-content'>
                        <ChatIcon />
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className='chat-closer d-xl-none'>
                <button className='btn btn-secondary btn-icon btn-minimal btn-sm text-muted' type='button' onClick={onBackClick}>
                  <svg className='hw-20' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M10 19l-7-7m0 0l7-7m-7 7h18' />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='row friends-info'>
          <div className='col'>
            <div className='card'>
              <ul className='list-group list-group-flush'>

                <li className='list-group-item'>
                  <div className='media align-items-center'>
                    <div className='media-body'>
                      <p className='small text-muted mb-0'>Phone</p>
                      <p id='contactPhone' className='mb-0'>{profileConfig.phone}</p>
                    </div>
                    <svg className='text-muted hw-20' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z' />
                    </svg>
                  </div>
                </li>
                <li className='list-group-item'>
                  <div className='media align-items-center'>
                    <div className='media-body'>
                      <p className='small text-muted mb-0'>Email</p>
                      <p id='contactEmail' className='mb-0'>{profileConfig.email}</p>
                    </div>
                    <svg className='text-muted hw-20' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z' />
                    </svg>
                  </div>
                </li>
              </ul>
            </div>

          </div>
        </div>

      </div>
    </div>
  )
}
