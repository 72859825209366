import React, { useState, useEffect } from 'react'
import { NavBar } from '../../../components/Home'
import { FriendProfile } from './FriendProfile'
import { FriendsList } from './FriendsList'
import { useDispatch } from 'react-redux'
import { setCurrentChat } from '../../../store/reducers/chats'
export const Friends = () => {
  const [visible, setVisible] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setCurrentChat(null))
  }, [])

  return (
    <div className='calls-tab-open h-100'>
      <div className='main-layout'>
        <NavBar activeMenu='friends' />
        <FriendsList onFriendClick={() => { setVisible(true) }} />
        <main className={visible ? 'main main-visible' : 'main'}>
          <FriendProfile onBackClick={() => { setVisible(false) }} />
        </main>
        <div className='backdrop' />
      </div>
    </div>
  )
}
