import api from '../../api'
import socketAPI from '../../apiSocket'
import { setLogoutModal } from '../../../store/reducers/main'
export const Login = async (email, password, dispatch) => {
  try {
    const response = await api.post('/users/authenticate', {
      username: email,
      password
    })
    if (response.status === 201) {
      api.interceptors.request.use(async (config) => {
        try {
          config.headers.Authorization = 'Bearer ' + response.data.id_token
        } catch (err) {
          console.warn(err)
        } finally {
          return config
        }
      })
      return response.data
    }
    return false
  } catch (err) {
    dispatch(setLogoutModal({
      isVisible: true,
      title: 'Invalid username or password',
      twoButtons: false,
      firstShouldClose: true,
      buttonTitle: 'Okay',
      secondButtonTitle: ''
    }))
    console.log(err)
    return false
  }
}
