import styled from "styled-components";
import { colors } from "../../../../../../configs/colors";

export const Title = styled.p`
  padding: 0px !important;
  margin: 0px 0px 0px 10px !important;
  font-size: 18px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 0px 10px 0px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 130px;
`;

export const InfoDiv = styled.div`
  margin: 20px 0px 20px 0px;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
`;
export const InfoContent = styled.div`
  margin: 0px 20px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
`;
export const InfoTitle = styled.p`
  padding: 0px !important;
  margin: 0px !important;
`;

export const InfoSelect = styled.select`
  margin: 0px 20px 0px 20px;
  width: 140px;
  height: 40px;
  color: #495057;
  padding: 0px 10px 0px 10px;
`;

export const InfoOption = styled.option``;
