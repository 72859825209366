import styled from "styled-components";
import { colors } from "../../../../../../configs/colors";

export const Title = styled.p`
  font-size: 18px;
`;

export const Divisor = styled.div`
  margin: ${(props) => props.amount / 2}px 0px ${(props) => props.amount / 2}px
    0px;
`;

export const tagInputClassNames = {
  tags: "tagInput_tags",
  tagInput: "tagInput_tagInput",
  tagInputField: "tagInput_tagInputField",
  selected: "tagInput_selected",
  tag: "tagInput_tag",
  remove: "tagInput_remove",
  suggestions: "tagInput_suggestions",
  activeSuggestion: "tagInput_activeSuggestions",
  clearAll: "tagInput_clearAll",
  editInput: "tagInput_editInput",
  editTagInput: "tagInput_editTagInput",
};

export const StatusContainer = styled.div`
  width: 100%;
  height: fit-content;
  right: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StatusSpamContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Status = styled.div`
  background-color: ${colors.GREEN};
  display: flex;
  border-radius: 4px;
  width: 120px;
  justify-content: center;
  height: fit-content;
  padding: 5px;
  /* padding-left: 10px; */
  margin: 0px 10px 0px 10px;
`;
export const StatusText = styled.p`
  color: ${colors.WHITE};
  padding: 0px !important;
  margin: 0px !important;
  text-align: center;
`;

export const UsersContainer = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
`;
export const UContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 30px 10px 0px;
`;
export const UCContentDiv = styled.div`
  border: 0.5px solid ${colors.GREY};
  border-radius: 4px;
  padding-right: 30px;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
`;

export const UCCContainer = styled.div`
  display: flex;
  border-radius: 4px;
  padding: 10px;
  width: fit-content;
  min-width: max-content;
  height: 100%;
  margin: 1px 5px 1px 1px;
  background-color: ${(props) =>
    props.darker ? colors.DARKGREY : colors.DARKGREY70};
`;
export const UCCCText = styled.p`
  color: ${colors.WHITE};
  padding: 0px !important;
  margin: 0px !important;
`;

export const UCTitle = styled.p`
  color: ${colors.BLACK};
  padding: 0px !important;
  margin: 0px !important;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px !important;
`;

export const DescriptionContainer = styled.div``;
export const DCTitle = styled.p`
  font-size: 16px;
  padding: 0px !important;
  margin: 10px 0px 10px 0px !important;
`;
export const DCText = styled.p`
  padding: 0px !important;
  margin: 10px 0px 10px 0px !important;
`;

export const TableContainer = styled.div`
  /* border: 1px solid ${colors.GREY}; */
  width: 100%;
  overflow: auto;
`;

export const Table = styled.table`
  width: fit-content;
  height: fit-content;
`;

export const VTRow = styled.tr`
  border: 1px solid ${colors.GREY};
  display: flex;
  flex-direction: row;
`;
export const VTHeader = styled.th`
  width: 200px;
  height: fit-content;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VTCell = styled.td`
  width: 200px;
  height: 100px;
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
`;

export const ImagesContainer = styled.div`
  height: fit-content;
  max-height: 200px;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin: 10px 0px 10px 0px;
  overflow-x: auto;
`;

export const InvImage = styled.img`
  border-radius: 4px;
  max-height: 100px;
  width: 200px;
  min-width: 200px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  margin: 10px;
  cursor: pointer;
`;
