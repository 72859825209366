import React, { useEffect } from 'react'
import { NavBar } from '../../../components/Home'
import { ChatList } from './ChatList'
import { Chat } from './Chat'

import { useDispatch, useSelector } from 'react-redux'
import { setCurrentChat, updateUnreadMessages } from '../../../store/reducers/chats'
import { setChatBarVisible } from '../../../store/reducers/main'

export const Chats = () => {
  const main = useSelector(state => state.main)
  const { internetStatus, currentChat, parsedRooms } = useSelector(state => state.chats)
  const { actionButtonClicked } = useSelector(state => state.main)

  // const { socket } = useSelector(state => state.chats)
  // const history = useHistory()
  const dispatch = useDispatch()
  const handleChatBackClick = () => {
    dispatch(setChatBarVisible(false))
    dispatch(setCurrentChat(null))
  }
  useEffect(() => {
    if (!currentChat && !actionButtonClicked && parsedRooms.length > 0 && window.innerWidth > 1000) {
      dispatch(setCurrentChat(parsedRooms[0]))
      dispatch(updateUnreadMessages())
    }
  }, [])
  return (
    <div className='calls-tab-open h-100'>
      <div className='main-layout'>
        <NavBar activeMenu='chat' />
        <ChatList onChatClick={() => { dispatch(setChatBarVisible(true)) }} />
        {(!internetStatus === 'offline' || currentChat) &&
          <main className={main.chatBarVisible ? 'main main-visible' : 'main'}>
            <Chat onBackClick={() => { handleChatBackClick() }} />
          </main>}

        {/* <ChatHistory
          mainVisible={}
          BackToListClicked={this.handleBackToList}
        /> */}
        <div className='backdrop' />
      </div>
    </div>
  )
}
