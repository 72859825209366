import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  taskList: [],
  currentTask: null,
  taskBarVisible: false,
  taskFormVisible: false,
  currentTransactions: [],
};
export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setTaskList: (state, action) => {
      state.taskList = action.payload;
    },
    setCurrentTask: (state, action) => {
      state.currentTask = action.payload;
    },
    setTaskTransactionInfo: (state, action) => {
      state.currentTransactions = action.payload;
    },
    setTaskBarVisible: (state, action) => {
      state.taskBarVisible = action.payload;
    },
    setTaskFormVisible: (state, action) => {
      state.taskFormVisible = action.payload;
    },
    getTasks: (state, action) => {
      return state;
    },
    getTasksTransactions: (state, action) => {
      return state;
    },
  },
});

export const {
  setTaskList,
  setCurrentTask,
  setTaskFormVisible,
  setTaskBarVisible,
  getTasks,
  setTaskTransactionInfo,
  getTasksTransactions,
} = tasksSlice.actions;
export default tasksSlice.reducer;
