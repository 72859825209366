import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Login } from "../../../services/userServices";
import { HomeInput, MainContainer } from "../../../components/Landing";
import { useDispatch, useSelector } from "react-redux";
import {
  userLoggedIn,
  setTypedCredentials,
  setRememberLogin,
} from "../../../store/reducers/main";
import eyesonLogo from "../../../assets/media/logos/eyesonLogo.png";
import { BottomDiv, Logo, TopDiv } from "./styles";

export const SignIn = () => {
  // const [isRemember, setIsRemember] = useState(false)
  const history = useHistory();
  const main = useSelector((state) => state.main);

  const [loginCredentials, setLoginCredentials] = useState({
    username: main.typedUsername || "",
    password: main.typedPassword || "",
  });
  const dispatch = useDispatch();
  const handleChange = (event) => {
    const newLoginCredentials = loginCredentials;
    newLoginCredentials[event.target.name] = event.target.value;
    setLoginCredentials({
      ...newLoginCredentials,
    });
  };

  const handleRememberChange = () => {
    if (main.rememberLogin) {
      dispatch(setTypedCredentials({ username: "", password: "" }));
    }
    dispatch(setRememberLogin(!main.rememberLogin));
  };

  const login = async () => {
    const authenticated = await Login(
      loginCredentials.username,
      loginCredentials.password,
      dispatch
    );
    if (main.rememberLogin) {
      dispatch(
        setTypedCredentials({
          username: loginCredentials.username,
          password: loginCredentials.password,
        })
      );
    }
    if (authenticated) {
      dispatch(
        userLoggedIn({
          idToken: authenticated.id_token,
          username: loginCredentials.username,
        })
      );
      history.push("/Home");
    }
  };

  useEffect(() => {
    if (main.idToken && main.profile) {
      history.push("/Home");
    }
  }, [main.idToken, main.profile]);

  useEffect(() => {
    if (main.rememberLogin) {
      setLoginCredentials({
        username: main.typedUsername,
        password: main.typedPassword,
      });
    }
  }, [main.typedPassword, main.typedUsername]);

  return (
    <MainContainer>
      <TopDiv>
        <Logo src={eyesonLogo}></Logo>
      </TopDiv>
      <BottomDiv>
        <form className="mb-3">
          <HomeInput
            labeltext="Username"
            name="username"
            value={loginCredentials.username}
            onChange={(changeEvent) => {
              handleChange(changeEvent);
            }}
            type="username"
            placeholder="Enter your username"
          />
          <HomeInput
            type="password"
            labeltext="Password"
            name="password"
            value={loginCredentials.password}
            onChange={(changeEvent) => {
              handleChange(changeEvent);
            }}
            className="form-control form-control-md"
            placeholder="Enter your password"
          />
          <div className="form-group d-flex justify-content-between">
            <div
              className="custom-control custom-checkbox"
              onClick={handleRememberChange}
            >
              <input
                type="checkbox"
                className="custom-control-input"
                onChange={() => {}}
                checked={main.rememberLogin || false}
              />
              <label className="custom-control-label text-muted font-size-sm">
                Remember me
              </label>
            </div>
          </div>
          <div
            onClick={login}
            className="btn btn-primary btn-lg btn-block text-uppercase font-weight-semibold"
          >
            Enter
          </div>
        </form>
        {/* <p>
        Don't have an account?{' '}
        <Link className='font-weight-semibold' to='/SignUp'>
          Sign up
        </Link>
        .
      </p> */}
      </BottomDiv>
    </MainContainer>
  );
};
