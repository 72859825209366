import api from "../../api";
export const loadAlerts = async (userId, authToken) => {
  try {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 30);
    const response = await api.post(
      "/emesages/",
      {
        startDate: currentDate,
        endDate: futureDate,
        status: "ALL",
        priority: "ALL",
        business: [],
        region: [],
        store: [],
        users: userId,
      },
      {
        headers: {
          Authorization: authToken,
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch (err) {
    console.log(err);
    window.alert("Failed to retrieve alerts");
    return false;
  }
};
