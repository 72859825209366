import React, { useMemo } from "react";
import { ContentContainer } from "../styles";
import { ReactComponent as TableArrow } from "../../../../../../assets/media/heroicons/solid/selector.svg";
import { ReactComponent as CamIcon } from "../../../../../../assets/media/icons/development.svg";
import { getContactName } from "../../../../../../utils/hooks";
import moment from "moment";
import {
  Title,
  StatusContainer,
  StatusText,
  Status,
  UsersContainer,
  UCCContainer,
  UCTitle,
  UCContentDiv,
  UContainer,
  UCCCText,
  DescriptionContainer,
  DCTitle,
  DCText,
  Divisor,
  VTRow,
  VTHeader,
  VTCell,
  TableContainer,
  Table,
  StatusSpamContainer,
  tagInputClassNames,
  ImagesContainer,
  InvImage,
} from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { TagInput } from "../../../../../../components/Home";
import "./styles.css";
import { setShowDocument } from "../../../../../../store/reducers/chats";

export const TaskContent = ({ taskDispatch }) => {
  const { contactList } = useSelector((state) => state.main);
  const {
    title,
    status,
    priority,
    owner,
    users,
    description,
    created,
    company,
    images,
  } = useSelector((state) => state.tasks.currentTask);
  const spacing = 30;
  const usersNames = users.map((user) => {
    return getContactName(user, contactList, "_id");
  });
  const ownerName = getContactName(owner, contactList, "_id");

  const { currentTransactions } = useSelector((state) => state.tasks);

  const handleTagInputChange = (data) => {
    taskDispatch({
      id: "newTaskMembers",
      value: data.map((value) => value.userId),
    });
  };

  return (
    <ContentContainer>
      <StatusComponent priority={priority} status={status} title={title} />
      <Divisor amount={spacing} />
      <UsersComponent
        owner={ownerName}
        users={usersNames}
        onChange={handleTagInputChange}
      />
      <Divisor amount={spacing} />
      <DescriptionComponent description={description} />
      <Divisor amount={spacing} />
      {/* {currentTransactions && (
        <TableComponent
          transactions={currentTransactions}
          stores={profile ? profile.profile.stores : []}
        />
      )} */}
      <ImageCarrouselComponent images={images} />
    </ContentContainer>
  );
};

const StatusComponent = ({ status, priority, title }) => {
  return (
    <StatusContainer>
      <Title>{title}</Title>
      <StatusSpamContainer>
        <Status>
          <StatusText>{status}</StatusText>
        </Status>
        <Status>
          <StatusText>{priority}</StatusText>
        </Status>
      </StatusSpamContainer>
    </StatusContainer>
  );
};

const UsersComponent = ({ owner, users, onChange }) => {
  const { contactList } = useSelector((state) => state.main);
  const { currentTask } = useSelector((state) => state.tasks);

  const formattedContactList = useMemo(() => {
    const result = contactList.map((value, index) => {
      const fullName = `${value.firstname} ${value.lastname}`;
      return { id: fullName, text: fullName, userId: value._id };
    });
    return result;
  }, [contactList]);
  const formattedUserList = useMemo(() => {
    const result = users.map((value, index) => {
      return { id: value, text: value };
    });
    return result;
  }, [users]);

  const handleChange = (data) => {
    const changedTags = data.filter(
      (value) => !formattedUserList.find((user) => user.id === value.id)
    );
    onChange(changedTags);
  };
  return (
    <UsersContainer>
      <UContainer>
        <UCTitle>Task Created by:</UCTitle>
        <UCContentDiv>
          <UCCContainer>
            <UCCCText>{owner}</UCCCText>
          </UCCContainer>
        </UCContentDiv>
      </UContainer>
      <UContainer>
        <UCTitle>Task Team:</UCTitle>
        <TagInput
          onChange={handleChange}
          resetOnValueChange
          value={currentTask}
          allowOnlyFromSuggestion
          classNames={tagInputClassNames}
          initialTags={formattedUserList}
          suggestions={formattedContactList}
          placeholder=" "
          shouldDeleteInitial={false}
          padding={0}
        />
      </UContainer>
    </UsersContainer>
  );
};

const DescriptionComponent = ({ description }) => {
  return (
    <DescriptionContainer>
      <DCTitle>Description</DCTitle>
      <DCText>{description}</DCText>
    </DescriptionContainer>
  );
};

// const TableComponent = ({ transactions, stores }) => {
//   const formattedTransactions = transactions.map((t) => {
//     return {
//       ...t,
//       formattedDate: moment(t.creation_date).format("MM/DD/YYYY HH:mm:ss"),
//       companyName: getContactName(t.store, stores, "store", "name"),
//     };
//   });
//   return (
//     <TableContainer>
//       <Table>
//         <thead>
//           <VTRow>
//             {/* <TableHeaderComponent>Video</TableHeaderComponent> */}
//             <TableHeaderComponent>Date</TableHeaderComponent>
//             <TableHeaderComponent>Store</TableHeaderComponent>
//             <TableHeaderComponent>Employee</TableHeaderComponent>
//             <TableHeaderComponent>Register</TableHeaderComponent>
//             <TableHeaderComponent>Trans. Type</TableHeaderComponent>
//             <TableHeaderComponent>Description</TableHeaderComponent>
//           </VTRow>
//         </thead>
//         <tbody>
//           {formattedTransactions &&
//             formattedTransactions.map((transaction) => {
//               return (
//                 <VTRow>
//                   {/* <VTCell to="Video">
//               <CamIcon />
//             </VTCell> */}

//                   <VTCell to="Date">{transaction.formattedDate}</VTCell>
//                   <VTCell to="Store">{transaction.companyName}</VTCell>
//                   <VTCell to="Employee">{transaction.employee_name}</VTCell>
//                   <VTCell to="Register">{transaction.register}</VTCell>
//                   <VTCell to="Trans. Type">{transaction.type}</VTCell>
//                   <VTCell to="Description">{transaction.description}</VTCell>
//                 </VTRow>
//               );
//             })}
//         </tbody>
//       </Table>
//     </TableContainer>
//   );
// };

const TableHeaderComponent = (props) => {
  return (
    <VTHeader>
      {props.children}
      <TableArrow />
    </VTHeader>
  );
};

const ImageCarrouselComponent = ({ images }) => {
  const dispatch = useDispatch();
  const handleImageClicked = (value) => {
    dispatch(setShowDocument({ visible: true, src: value }));
  };
  return (
    <ImagesContainer>
      {images.map((value, index) => {
        return (
          <InvImage
            src={value}
            key={index}
            onClick={() => {
              window.open(value);
            }}
          />
        );
      })}
    </ImagesContainer>
  );
};
