import React from "react";
import { CameraContainer, FadeBackground } from "./styles";
import Webcam from "react-webcam";
import { EyesonButton } from "../Button";
const videoConstraints = {
  width: 220,
  height: 200,
  facingMode: "user",
};

export const WebcamCapture = ({ handleCaptureScreenshot }) => {
  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) handleCaptureScreenshot(imageSrc);
    }
  }, [webcamRef]);

  return (
    <CameraContainer>
      <Webcam
        audio={false}
        height={200}
        ref={webcamRef}
        style={{ borderRadius: "8px" }}
        screenshotFormat="image/jpeg"
        width={220}
        videoConstraints={videoConstraints}
      />
      <EyesonButton
        blue
        buttonText="Capture"
        onClick={(e) => {
          e.preventDefault();
          capture();
        }}
      />
    </CameraContainer>
  );
};
