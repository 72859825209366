import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reportList: [],
  currentReport: null,
};
export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setReportList: (state, action) => {
      state.reportList = action.payload;
    },
    getReports: (state, action) => {
      return state;
    },
    updateReport: (state, action) => {
      return state;
    },
    setCurrentReport: (state, action) => {
      state.currentReport = action.payload;
    },
  },
});

export const { setReportList, getReports, updateReport, setCurrentReport } =
  reportsSlice.actions;
export default reportsSlice.reducer;
