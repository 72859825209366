import styled from 'styled-components';
import { colors } from '../../../../configs/colors';

const InputBorderRadius = 4;

export const FadeBackground = styled.div`
  position: absolute;
  display: flex;
  z-index: 20000;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${colors.FADEBLACK};
`

export const Container = styled.div`
  position: relative;
  display: flex;
  background-color: ${colors.WHITE};
  max-width: 700px;
  width: 90%;
  height: 95%;
  padding: 20px 40px 20px 40px;
  border-radius: 12px;
  scroll-behavior: smooth;
  overflow-y: scroll;
`

export const TopContainer = styled.div`
  display: flex;
  flex: 4;
`
export const MiddleContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex: 3;
  justify-content: space-around;
`

export const HorizontalLine = styled.div`
  height: 1px;
  width: 100%;
  margin: 20px 0px 20px 0px;
  background-color: ${colors.GREY};
`
export const EndContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 30px;
`

export const MContentContainer = styled.div`
  flex-direction: column;
  display: flex;
  min-width: 49%;
  width: 49%;
  @media (max-width: 500px) {
    min-width: 100%;
    width: 100%;
  }
`
export const MCTitle = styled.p`
  margin: 10px 0px 10px 0px !important;
`
export const MCInput = styled.input`
  border-radius: ${InputBorderRadius}px;
  height: 42px;
  padding-left: ${props => props.paddingLeft ? props.paddingLeft : 10}px;
  padding-right: 10px;
  width: 100%;
  border: 1px solid ${colors.GREY};
  outline: none;
  font-size: 12px;
`
export const MCSelect = styled.select`
  border-radius: ${InputBorderRadius}px;
  height: 42px;
  border: 1px solid ${colors.GREY};
  padding-left: 10px;
  padding-right: 10px;
  /* text-align: center; */
  font-size: 12px;
`
export const MCOption = styled.option`
`
export const MCTextArea = styled.textarea`
  border-radius: ${InputBorderRadius}px;
  border: 1px solid ${colors.GREY};
  padding-left: 10px;
  padding-right: 10px;
  height: 90px;
  font-size: 12px;
`

export const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const EButton = styled.input`
  background-color: ${colors.DARKBLUE};
  width: 160px;
  border-radius: 8px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${colors.BLUE};
  }
  text-align: center;
  margin: 0px !important;
  color: ${colors.WHITE};
  padding: 0px !important;
  line-height: 0px !important;
  outline: none;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
`

export const CloseButtonContainer = styled.div`
  position: absolute;
  right: 35px;
  top: 5px;
  cursor: pointer;
`

export const CloseButton = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  height: 30px;
  width: 30px;
`

export const CloseButtonImg = styled.img``