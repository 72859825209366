import api from "../../api";
export const loadInvestigationTransaction = async (transactionId) => {
  try {
    const response = await api.post("/report/investigation/detail", {
      transactions: transactionId,
    });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch (err) {
    console.log(err);
    window.alert("Failed to load investigation transaction");
    return false;
  }
};
