import React from "react";
import { Container } from "./styles";
import { ReportContent, ReportModal } from "./components";
import { useSelector } from "react-redux";

export const Report = () => {
  const { currentReport } = useSelector((state) => state.reports);
  return (
    <>
      <Container>
        <ReportContent />
      </Container>
      {currentReport && <ReportModal />}
    </>
  );
};
