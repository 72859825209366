import cartoon from "../assets/audio/cartoon-telephone_daniel_simion.mp3";
import frontDesk from "../assets/audio/front-desk-bells-daniel_simon.mp3";
import smsAlert1 from "../assets/audio/sms-alert-1-daniel_simon.mp3";
import smsAlert2 from "../assets/audio/sms-alert-2-daniel_simon.mp3";
import smsAlert3 from "../assets/audio/sms-alert-3-daniel_simon.mp3";
import smsAlert4 from "../assets/audio/sms-alert-4-daniel_simon.mp3";
import smsAlert5 from "../assets/audio/sms-alert-5-daniel_simon.mp3";

export const audios = {
  "cartoon-telephone_daniel_simion": cartoon,
  "front-desk-bells-daniel_simon": frontDesk,
  "sms-alert-1-daniel_simon": smsAlert1,
  "sms-alert-2-daniel_simon": smsAlert2,
  "sms-alert-3-daniel_simon": smsAlert3,
  "sms-alert-4-daniel_simon": smsAlert4,
  "sms-alert-5-daniel_simon": smsAlert5,
};
