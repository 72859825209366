import styled from "styled-components";

export const ImageModalDiv = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const ModalImage = styled.img`
  max-width: 90% !important;
  max-height: 90% !important;
  width: 400px;
  height: auto;
`;
