import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLogoutModal, userLoggedOut } from '../../../store/reducers/main'
export const LogoutModal = () => {
  const { logoutModal } = useSelector(state => state.main)
  const dispatch = useDispatch()
  const handleLogout = () => {
    window.localStorage.removeItem('tag')
    dispatch(userLoggedOut())
  }

  const closeModal = () => {
    dispatch(setLogoutModal({
      isVisible: false,
      title: '',
      twoButtons: false,
      firstShouldClose: true,
      buttonTitle: '',
      secondButtonTitle: ''
    }))
  }

  return (
    <div className='fadeBackground'>
      <div className='logoutModalContainer'>
        <div className='modalTitleContainer'>
          <p className='logoutModalTitle'>{logoutModal.title}</p>
        </div>
        {/* <div className='modalSubtitleContainer'>
          <p className='logoutModalSubtitle'>Subtitle</p>
        </div> */}
        <div className='modalButtonsContainer'>
          {logoutModal.twoButtons &&
            <div className='logoutModalButton backgroundRed' onClick={() => { logoutModal.firstShouldClose ? handleLogout() : closeModal() }}>
              <p className='logoutModalButtonText'>{logoutModal.secondButtonTitle}</p>
            </div>}
          <div className='logoutModalButton backgroundBlue' onClick={() => { logoutModal.firstShouldClose ? closeModal() : handleLogout() }}>
            <p className='logoutModalButtonText'>{logoutModal.buttonTitle}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
