import React from 'react'
import { Link } from 'react-router-dom'
import { HomeInput, MainContainer } from '../../../components/Landing'

export const ResetPassword = () => {
  return (
    <MainContainer>
      <h1 className='font-weight-bold'>Password Reset</h1>
      <p className='text-dark mb-3'>
        Enter your email address to reset password.
      </p>
      <HomeInput
        labeltext='Email Address'
        type='email'
        className='form-control form-control-md'
        placeholder='Enter your email'
      />
      <button className='btn btn-lg btn-block btn-primary text-uppercase font-weight-semibold mb-3'>
        Send Reset Link
      </button>
      <p>
        Already have an account?{' '}
        <Link className='font-weight-semibold' to='/SignIn'>
          Sign in
        </Link>
        .
      </p>
    </MainContainer>
  )
}
