import React, { useEffect, useRef } from "react";
import $ from "jquery";

import "datatables.net-dt/css/jquery.dataTables.css";

export function DataTable({ data, handleRowClick }) {
  $.DataTable = require("datatables.net");
  const tableRef = useRef();

  useEffect(() => {
    window.handleReportRowClick = handleRowClick;
    const table = $(tableRef.current).DataTable({
      data,
      columns: [
        { title: "Video" },
        { title: "Time" },
        { title: "Image" },
        { title: "Plate" },
        { title: "Direction" },
        { title: "Person Type" },
        { title: "Person Category" },
        { title: "Comments" },
        { title: "Trans. Id" },
      ],
      columnDefs: [
        {
          render: function (data, type, full, meta) {
            return `<div className="clickable fullSize" onClick="window.handleReportRowClick('${full[8]}', '${full[3]}')">${data}</div>`;
          },
          targets: "_all",
        },
      ],
      options: {
        scrollX: true,
        scrollY: true,
        search: true,
      },
    });
    // Extra step to do extra clean-up.
    return function () {
      console.log("Table destroyed");
      table.destroy();
    };
  }, []);
  return (
    <div id="reportTableDiv">
      <table
        className="display"
        width="100%"
        id="reportTable"
        ref={tableRef}
      ></table>
    </div>
  );
}
