import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Friend } from '../Friend'
import { sortContacts } from '../../../../utils/roomsUtils'
import { setCurrentProfile } from '../../../../store/reducers/chats'

export const FriendsList = ({ onFriendClick }) => {
  const dispatch = useDispatch()
  const { contactList } = useSelector(state => state.main)
  const { onlineList } = useSelector(state => state.chats)
  const [parsedList, setParsedList] = useState([])
  useEffect(() => {
    const newParsedList = []
    if (contactList && onlineList) {
      contactList.forEach(contact => {
        const onlineContact = onlineList.find(el => el.UserId === contact._id)
        if (onlineContact) {
          newParsedList.push({
            ...contact,
            online: true
          })
        } else {
          newParsedList.push({
            ...contact,
            online: false
          })
        }
      })
    }
    const sortedList = sortContacts(newParsedList)
    setParsedList(sortedList)
  }, [contactList, onlineList])

  const handleFriendSelected = (friend) => {
    dispatch(setCurrentProfile(friend))
    onFriendClick()
  }

  return (
    <FriendListContainer>
      <div className='sidebar-header sticky-top p-2'>

        <div className='d-flex justify-content-between align-items-center'>
          <h5 className='font-weight-semibold mb-0'>Employees</h5>
        </div>

      </div>

      <ul className='contacts-list' id='friendsTab' data-friends-list=''>
        {parsedList && parsedList.map((friend, index) => {
          return (
            <Friend
              key={index}
              {...friend}
              onFriendSelect={() => { handleFriendSelected(friend) }}
            />
          )
        })}
      </ul>
    </FriendListContainer>
  )
}

const FriendListContainer = (props) => {
  return (
    <aside className='sidebar'>
      <div className='tab-content'>
        <div className='tab-pane active' id='friends-content'>
          <div className='d-flex flex-column h-100'>
            <div className='hide-scrollbar h-100' id='friendsList'>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </aside>
  )
}
